import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import LOGO from "../../assets/logo-web.png";
import APPSTORE from "../../assets/appstore.png";
import PLAYSTORE from "../../assets/playstore.png";
import FaTwitter from "../../assets/twitterLogo.png";
import Container from "../../components/ui/Container";
import FooterAccordion from "../../components/ui/FooterAccordion";
// import { FooterData1 } from "../../utils/footerData";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-slate-900">
      <section className="py-4">
        <Container>
          <FooterAccordion />
        </Container>
      </section>
      <div className="pb-4">
        <Container>
          <div className="block md:flex items-center justify-between">
            <div className="flex justify-center">
              <Link to="/">
                <img src={LOGO} alt="footerLogo" />
              </Link>
            </div>
            <div className="flex items-start justify-center sm:justify-between sm:gap-12">
              <div>
                <h6 className="hidden sm:flex text-white text-base uppercase font-semibold mb-2">
                  Download App Now
                </h6>
                <div className="hidden sm:flex gap-2 items-center">
                  <Link
                    to="/"
                    className="text-white flex gap-2 items-center w-28 rounded-xl overflow-hidden"
                  >
                    <img
                      src={APPSTORE}
                      alt="App store"
                      width={200}
                      height={60}
                    />
                  </Link>
                  <Link
                    to="/"
                    className="text-white flex gap-2 items-center w-28 rounded-xl overflow-hidden"
                  >
                    <img
                      src={PLAYSTORE}
                      alt="Play store"
                      width={200}
                      height={60}
                    />
                  </Link>
                </div>
              </div>
              <div className="sm:flex gap-2 items-center flex-col">
                <h6 className="text-white text-base uppercase font-semibold mb-2">
                  Connect us on
                </h6>
                <div className="text-white flex gap-3 items-center justify-center sm:justify-start flex-wrap">
                  <a
                    href="https://www.facebook.com/indiaonlinepay/"
                    className="hover:text-green-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF />
                  </a>
                  <a
                    href="https://twitter.com/indiaonlinepay"
                    className="hover:text-green-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FaTwitter} alt="twitter" width={16} height={16} />
                  </a>
                  <a
                    href="https://www.instagram.com/indiaonlinepay/"
                    className="hover:text-green-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://in.linkedin.com/company/india-online-pay/"
                    className="hover:text-green-600"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-green-600 text-center py-3 text-white text-xs md:text-sm">
        <Container>
          Copyright &copy; {new Date().getFullYear()} ashapurti loans. All
          rights reserved.
        </Container>
      </div>
    </footer>
  );
};
export default Footer;
