import { useParams, useNavigate } from "react-router-dom";
import { loanData, LoanInfo } from "../../utils/loanData";
import InnerBanner from "../../components/ui/InnerBanner";
import Container from "../../components/ui/Container";
import LoanEMICalculator from "../../components/LoanEMICalculator/PersonalLoanEMICalculator";
import AdBanner from "../../components/ui/AdBanner";
import Button from "../../components/ui/Button";

const LoanLandingDetails: React.FC = () => {
  const navigate = useNavigate();

  // Extract loanType from URL parameters
  const { loanType } = useParams<{ loanType: string }>();

  // Get the loan information based on loanType
  const loanInfo: LoanInfo | undefined = loanType
    ? loanData[loanType]
    : undefined;

  // If loanInfo is undefined, return null to render nothing
  if (!loanInfo) return null;

  const handleSignUp = () => {
    navigate("/register"); // Navigate to the /register route
  };

  return (
    <>
      {/* Render InnerBanner with dynamic image and title */}
      <InnerBanner img={loanInfo.bannerImage} alt={loanInfo.title}>
        <h4 className="text-xl md:text-2xl lg:text-4xl text-center text-white text-shadow">
          <span className="text-green-600 font-bold">{loanInfo.title}</span>
        </h4>
      </InnerBanner>

      <section className="py-4">
        <Container>
          <div className="flex flex-col md:flex-row gap-12">
            <div className="relative flex flex-col md:w-6/12 pt-6">
              <div className="space-y-2 text-sm text-justify mb-4">
                {loanInfo.description.map((desc: string, index: number) => (
                  <p key={index} className="text-sm leading-6 text-gray-600">
                    {desc}
                  </p>
                ))}
              </div>
              <div className="flex justify-end w-full">
                <Button
                  className="btn btn-dark shadow-shadow_3"
                  as="Link"
                  onClick={handleSignUp}
                >
                  {loanInfo.buttonText}
                </Button>
              </div>
            </div>
            <div className="flex items-center md:w-6/12 shadow-shadow_1 rounded-2xl h-full">
              {loanInfo.emiCalculator && <LoanEMICalculator />}
            </div>
          </div>
        </Container>
      </section>

      {/* AdBanner with dynamic image */}
      <AdBanner dir="left" img={loanInfo.adImage} alt={loanInfo.title}>
        <div className="flex items-center flex-col justify-center text-center h-full">
          <h4 className="text-yellow-400 font-bold text-2xl mb-6 capitalize">
            Instant {loanInfo.title}
          </h4>
          <div>
            <button
              onClick={handleSignUp}
              className="btn btn-secondary text-slate-900 lg:!px-7 border-yellow-300 !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
            >
              Apply Now
            </button>
          </div>
        </div>
      </AdBanner>
    </>
  );
};

export default LoanLandingDetails;
