import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { useEffect, useState } from "react";
import { getAllBorrowerByAgentId } from "../../../utils/apis";

const BorrowerList = () => {
  const [borrowerList, setBorrowerList] = useState<any>([]);
  const navigate = useNavigate();

  const handleCreateBorrower = () => {
    navigate("/register", { state: "Agent" });
  };

  useEffect(() => {
    getAllBorrowerByAgentId(1).then((response) => {
      setBorrowerList(response.borrowers);
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Header name="Borrower List" />
      <div className="w-[1480px] mx-auto">
        <div className="flex flex-1 mt-10">
          <main className="flex-1 bg-gray-100 p-6">
            <div className="flex justify-between items-center">
              <div className="flex items-center py-4">
                <input
                  type="text"
                  placeholder="Enter Borrower Name..."
                  className="w-full p-2 border border-gray-300 rounded-none"
                />
                <button className="bg-[#17a44f] text-white px-4 py-2">
                  Search
                </button>
              </div>
              <button
                onClick={handleCreateBorrower}
                className="bg-[#17a44f] text-white px-4 py-2 h-10 flex items-center justify-center rounded-xl shadow-shadow_4"
              >
                Create Borrower
              </button>
            </div>
            <div className="overflow-x-auto bg-white rounded shadow-md">
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-[#17a44f] text-white text-center">
                    <th className="px-4 py-2 border">Borrower Name</th>
                    <th className="px-4 py-2 border">Aadhar Card</th>
                    <th className="px-4 py-2 border">Pan Card</th>
                    <th className="px-4 py-2 border">Loan Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {borrowerList.length > 0 ? (
                    borrowerList.map((data: any) => (
                      <tr
                        key={data.BorrowerId}
                        className="hover:bg-gray-100 text-center"
                      >
                        <td className="px-4 py-2 border">{data.fullName}</td>
                        <td className="px-4 py-2 border">
                          {data.aadhaarNumber}
                        </td>
                        <td className="px-4 py-2 border">
                          {data.panCardNumber}
                        </td>
                        <td className="px-4 py-2 border">
                          {data.loanAmount}/-
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={5}
                        className="px-4 py-6 text-center text-gray-500"
                      >
                        No borrowers available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between items-center mt-4">
              <span>Showing 1 to 10 of 200 entries</span>
              <div className="flex items-center gap-2 border-[#17a44f]">
                <button className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white">
                  First
                </button>
                <button className="px-3 py-1 border border-gray-500 bg-[#F5F5F5] rounded hover:bg-[#17a44f] hover:text-white">
                  Previous
                </button>
                <button className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white">
                  1
                </button>
                <button className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white">
                  2
                </button>
                <button className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white">
                  3
                </button>
                <button className="px-3 py-1 border border-gray-500 bg-[#F5F5F5] rounded hover:bg-[#17a44f] hover:text-white">
                  Next
                </button>
                <button className="px-3 py-1 border border-[#17a44f] text-[#17a44f] rounded hover:bg-[#17a44f] hover:text-white">
                  Last
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default BorrowerList;
