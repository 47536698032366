import React, { useEffect, useState } from "react";
import Banner from "../../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/Logo_circle.png";
import { ReactComponent as BackIcon } from "../../../assets/icons/back-arrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../utils/store";
import { handleAgentSignUp } from "../../../redux/Agent/agentSignUpSlice";
import { AgentSignup } from "../../../utils/types";
import { toast, ToastContainer } from "react-toastify";
import { FaHome } from "react-icons/fa";
import CustomModal from "../../../components/CustomModal";
import OTPVerificationInput from "../../../components/OTPVerificationInput";
import {
  submitOtpForVerifyAadhaar,
  verifyAadhaarRequest,
  verifyPan,
} from "../../../utils/verificationApis";
import {
  setIsAgentAadharVerified,
  setPanVerifiedData,
} from "../../../redux/registration/registrationSlice";
import { FiCheckCircle } from "react-icons/fi";
import { Loader } from "lucide-react";
// import AgentSignUp from "../../screens/Users/"

const AgentSignUp = () => {
  const [AadharOtpModal, setAadharOtpModal] = useState(false);
  const [AadharVerifyLoader, setAadharVerifyLoader] = useState(false);
  const [panVerifyLoader, setPanVerifyLoader] = useState(false);
  const [addressFlag, setAddressFlag] = useState(true);
  const [villageOrTownFlag, setVillageOrTownFlag] = useState(true);
  const [talukaFlag, setTalukaFlag] = useState(true);
  const [aadharNumForVerify, setAadharForVerify] = useState<any>();
  const [aadharVerifiedData, setAadharVerifiedData] = useState<any>();
  const [aadharVerReq, setResponseOfSubmitAddharOtp] = useState<any>();
  const [aadharOtp, setAadhaOtp] = useState("");
  const [isAadharVarified, setIsAadharVerified] = useState("");
  const [agentData, setAgentData] = useState<AgentSignup>({
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    maritalStatus: "",
    aadharCard: "",
    panCard: "",
    mobileNumber: "",
    email: "",
    whatsappNumber: "",
    address: "",
    villageOrTown: "",
    taluka: "",
    district: "",
    state: "",
    domicile: "",
    pincode: "",
    panVerifyStatus: "",
  });
  const [autoPopulateData, setAutoPopulateData] = useState<any>({
    firstName: "",
    // middleName: "Janrao",
    lastName: "",
    birthDate: "1998-05-20",
    gender: "female",
    maritalStatus: "Single",
    aadharCard: "",
    panCard: "BNRPT2011U",
    mobileNumber: "7775832277",
    email: "ashirvad.o@gllfl.com",
    whatsappNumber: "9876543210",
    address: "123, Main Street",
    villageOrTown: "Sample Town",
    taluka: "Sample Taluka",
    district: "Sample District",
    state: "Sample State",
    domicile: "Sample Domicile",
    pincode: "123456",
  });
  const navigate = useNavigate();
  const { isAgentAadharVerified, isAgentPanVerified } = useSelector(
    (state: RootState) => state.registrationSlice
  );
  const person: AgentSignup = {
    firstName: "",
    lastName: "",
    birthDate: "1998-05-20",
    gender: "female",
    maritalStatus: "Single",
    aadharCard: "",
    panCard: "BNRPT2011U",
    mobileNumber: "7775832277",
    email: "ashirvad.o@gllfl.com",
    whatsappNumber: "9876543210",
    address: "123, Main Street",
    villageOrTown: "Sample Town",
    taluka: "Sample Taluka",
    district: "Sample District",
    state: "Sample State",
    domicile: "Sample Domicile",
    pincode: "123456",
    panVerifyStatus: "verified",
  };

  // console.log("isAgentAadharVerified :", isAgentAadharVerified);
  const dispatch: AppDispatch = useDispatch();
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // const result = await postAgentSignup(person);
    try {
      const result = await dispatch(handleAgentSignUp(agentData));
      if (result?.payload?.status?.toLowerCase() === "success") {
        toast.success(result?.payload?.message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        dispatch(setIsAgentAadharVerified(false));
        dispatch(setPanVerifiedData(false));
      } else {
        toast.error(result?.payload?.message);
      }
    } catch (error) {
      console.log("Agent Sign Up Error", error);
    }
  };
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (name === "aadharCard") {
      // Allow only numeric values and restrict to 12 digits
      if (/^\d*$/.test(value) && value.length <= 12) {
        setAgentData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        console.log("aaadhar:", value);
      }
    } else if (
      name === "panCard" ||
      name === "mobileNumber" ||
      name === "whatsappNumber"
    ) {
      if (value.length <= 10) {
        setAgentData((prevData) => ({
          ...prevData,
          [name]: value?.toUpperCase(),
        }));
      }
    } else {
      setAgentData((prevData: any) => ({
        ...prevData,
        [name]: value, // Dynamically update the field that matches the input's `name`
      }));
    }
  };
  const handlAutoPopulateData = () => {
    setAgentData({
      ...agentData,
      mobileNumber: autoPopulateData.mobileNumber,
      birthDate: autoPopulateData.birthDate,
      address: autoPopulateData.address,
      villageOrTown: autoPopulateData.villageOrTown,
      taluka: autoPopulateData.taluka,
      district: autoPopulateData.district,
      state: autoPopulateData.state,
      pincode: autoPopulateData.pincode,
      domicile: autoPopulateData.domicile,
      gender: autoPopulateData.gender,
      maritalStatus: autoPopulateData?.maritalStatus,
    });
  };
  const openAadharOtpModal = () => {
    setAadharOtpModal(true);
  };
  const closeAadharOtpModal = () => setAadharOtpModal(false);
  const handleSendOtpToVerifyAadhar = async () => {
    try {
      if (agentData.aadharCard) {
        setAadharVerifyLoader(true);
        const result = await verifyAadhaarRequest(
          "aadhaarVerification/requestOtp",
          agentData.aadharCard
        );
        console.log("send otp", result);
        if (result?.result?.message_code === "success") {
          setResponseOfSubmitAddharOtp(result);
          setAadharVerifyLoader(false);
          openAadharOtpModal();
        }
      }
    } catch (err: any) {
      toast.error("Something went wrong.");
      setAadharVerifyLoader(false);
    }
  };

  const handleSubmitAadharOtp = async () => {
    try {
      if ((aadharVerReq?.result?.data?.client_id, aadharOtp)) {
        const result = await submitOtpForVerifyAadhaar(
          "aadhaarVerification/submitOtp",
          aadharVerReq?.result?.data?.client_id,
          aadharOtp
        );
        console.log("handleSubmitAadharOtp", result);
        if (result?.result?.message_code === "success") {
          toast.success("Verified Successfully");
          dispatch(setIsAgentAadharVerified(true));
          setAadharVerifiedData(result);
          setAadharOtpModal(false);
          setAgentData({
            ...agentData,
            birthDate: result?.result?.data?.dob,
            address: `${result?.result?.data?.address?.house} ${result?.result?.data?.address?.street}`,
            villageOrTown: result?.result?.data?.address?.vtc,
            taluka: result?.result?.data?.address?.subdist,
            district: result?.result?.data?.address?.dist,
            state: result?.result?.data?.address?.state,
            pincode: result?.result?.data?.zip,
            domicile: result?.result?.data?.address?.country,
            gender:
              result?.result?.data?.gender?.toUpperCase() === "M"
                ? "Male"
                : result?.result?.data?.gender?.toUpperCase() === "F"
                ? "Female"
                : "Other",
          });
          setAddressFlag(
            !!result?.result?.data?.address?.house ||
              !!result?.result?.data?.address?.street
          );
          setVillageOrTownFlag(!!result?.result?.data?.address?.vtc);
          setTalukaFlag(!!result?.result?.data?.address?.subdist);
        } else {
          toast.error(result?.result?.message);
        }
      }
    } catch (err: any) {
      console.log("Aadhar Sumbit Otp :", err);
      toast.error("Something went wrong");
    }
  };
  console.log("agentData.panCard :", agentData.panCard);
  const panSchema = {
    code: 200,
    message: "Data Found Successfully !",
    result: {
      pan_number: "KLYPS0089C",
      full_name: "ZEESHAN  SYED",
      full_name_split: ["ZEESHAN", "", "SYED"],
      masked_aadhaar: "XXXXXXXX0925",
      address: {
        line_1: "Degloor Naka",
        line_2: "",
        street_name: "Itwara Nanded S.O",
        zip: "431604",
        city: "Nanded",
        state: "Maharashtra",
        country: "INDIA",
        full: "Degloor Naka  Itwara Nanded S.O Nanded Maharashtra INDIA 431604",
      },
      email: "ma********ed@gmail.com",
      tax: true,
      phone_number: "91XXXXXX36",
      gender: "M",
      dob: "1999-10-06",
      aadhaar_linked: true,
      category: "person",
      less_info: false,
      is_director: {
        found: "No",
        info: [],
      },
      is_sole_proprietor: {
        found: "No",
        info: [],
      },
      fname: "",
      din_info: {
        din: "",
        dinAllocationDate: "",
        company_list: [],
      },
    },
  };
  const handlePanVerification = async () => {

    try {
      if (agentData.panCard) {
        setPanVerifyLoader(true);
        const result = await verifyPan("panAllInOne", agentData.panCard);
        console.log("send otp", result);
        if (result?.code === 200) {
          toast.success("Verified Successully");
          setPanVerifyLoader(false);
          dispatch(setPanVerifiedData(true));
          setAgentData({ ...agentData, panVerifyStatus: "verified" });
        } else {
          setPanVerifyLoader(false);
          toast.error(result?.message);
          setAgentData({ ...agentData, panVerifyStatus: "" });
        }
      }
    } catch (err: any) {
      // setError(err.message);
      setPanVerifyLoader(false);
      console.log("handleVerifyPan :", err);
      toast.error("Something went wrong.");
    }
  };

  console.log("agent data :", agentData);

  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="h-full w-full bg-no-repeat bg-cover min-h-screen"
    >
      <div className="py-20 md:py-24 h-full md:h-dvh flex md:items-center justify-center">
        <div className="max-w-[1160px] w-full">
          <div className="tab-contentWrapper">
            <ul className="tab-header flex !justify-start mb-6 gap-8">
              <li className="tab-itemHome">
                <img src={Logo} alt="logo" className="pr-6 w-40" />
              </li>
              <li>
                <div className="flex items-center gap-1 w-20 h-8">
                  <Link
                    to="/"
                    onClick={() => {
                      dispatch(setIsAgentAadharVerified(false));
                      dispatch(setPanVerifiedData(false));
                    }}
                    className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
                  >
                    <FaHome className="fill-green-700  hover:fill-green-900  " />
                    <p className="text-xs text-slate-400">Home Page</p>
                  </Link>
                </div>
              </li>
              <li className="w-full">
                <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
                  Agent Sign Up
                </h4>
              </li>
            </ul>

            <div className="tab-content">
              <div className="space-y-4 min-h-[545px]">
                <form onSubmit={handleSubmit}>
                  {/* Repeat similar blocks for all other inputs */}
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
                    <div>
                      <label className="block text-black font-medium mb-1 text-sm">
                        First Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        value={agentData.firstName}
                        name="firstName"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-none"
                        placeholder="First Name"
                      />
                    </div>

                    {/* <div>
                  <label className="block text-black font-medium mb-1 text-[.875rem]">
                    Middle Name<span className="text-red-500"></span>
                  </label>
                  <input
                    onChange={handleChange}
                    value={agentData.middleName}
                    name="middleName"
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-none"
                    placeholder="Middle Name"
                  />
                </div> */}

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Last Name<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        value={agentData.lastName}
                        name="lastName"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-none"
                        placeholder="Last Name"
                      />
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Aadhar Card<span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center  border-gray-300 verifyBtnBox">
                        <input
                          onChange={handleChange}
                          value={agentData.aadharCard}
                          name="aadharCard"
                          type="number"
                          maxLength={12}
                          className="w-full p-2 rounded-none"
                          placeholder="Aadhar Card"
                        />
                        {isAgentAadharVerified ? (
                          <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                        ) : (
                          <>
                            {AadharVerifyLoader ? (
                              <Loader />
                            ) : (
                              <button
                                type="button"
                                disabled={agentData?.aadharCard?.length <= 11}
                                onClick={handleSendOtpToVerifyAadhar}
                                className="bg-[#17a44f] text-white px-4 py-2 "
                              >
                                Verify
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Pan Card<span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center  border-gray-300">
                        <input
                          onChange={handleChange}
                          value={agentData.panCard}
                          name="panCard"
                          type="text"
                          className="w-full p-2 rounded-none"
                          placeholder="Pan Card"
                        />
                        {isAgentPanVerified ? (
                          <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                        ) : (
                          <>
                            {panVerifyLoader ? (
                              <Loader />
                            ) : (
                              <button
                                type="button"
                                disabled={agentData?.panCard?.length <= 9}
                                className="bg-[#17a44f] text-white px-4 py-2"
                                onClick={handlePanVerification}
                              >
                                Verify
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Mobile Number<span className="text-red-500">*</span>
                      </label>
                      <div className="flex  border-gray-300">
                        <span className="bg-gray-300 text-black px-1.5 flex items-center">
                          +91
                        </span>
                        <input
                          onChange={handleChange}
                          value={agentData.mobileNumber}
                          name="mobileNumber"
                          type="number"
                          className="w-full p-2 rounded-none cursor-pointer "
                          placeholder="Mobile Number"
                          // disabled
                        />
                        {/* <button className="bg-[#17a44f] text-white px-4">
                      Verify
                    </button> */}
                      </div>
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Whatsapp Number<span className="text-red-500">*</span>
                      </label>
                      <div className="flex  border-gray-300">
                        <span className="bg-gray-300 text-black px-1.5 flex items-center">
                          +91
                        </span>
                        <input
                          onChange={handleChange}
                          value={agentData.whatsappNumber}
                          name="whatsappNumber"
                          type="number"
                          className="w-full p-2 rounded-none  "
                          placeholder=" Whatsapp Number"
                        />
                      </div>
                    </div>
                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Date of Birth<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled
                        value={agentData.birthDate}
                        name="birthDate"
                        type="date"
                        className="w-full outline-none rounded-none border border-gray-300 p-2 bg-[#f3f3f3]"
                        placeholder="YYYY-MM-DD"
                        pattern="\d{4}-\d{2}-\d{2}" // Optional, for additional validation
                      />
                    </div>
                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Address<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled={addressFlag}
                        value={agentData.address}
                        name="address"
                        type="text"
                        className={`w-full p-2 border border-gray-300 rounded-none ${
                          addressFlag && "bg-[#f3f3f3]"
                        }`}
                        placeholder="Address"
                      />
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Village/Town<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled={villageOrTownFlag}
                        value={agentData.villageOrTown}
                        name="villageOrTown"
                        type="text"
                        className={`w-full p-2 border border-gray-300 rounded-none ${
                          villageOrTownFlag && "bg-[#f3f3f3]"
                        }`}
                        placeholder="Village/Town"
                      />
                    </div>
                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Taluka<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled={talukaFlag}
                        value={agentData.taluka}
                        name="taluka"
                        type="text"
                        className={`w-full p-2 border border-gray-300 rounded-none ${
                          talukaFlag && "bg-[#f3f3f3]"
                        }`}
                        placeholder="Taluka"
                      />
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem] ">
                        District<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled
                        value={agentData.district}
                        name="district"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                        placeholder="District"
                      />
                    </div>
                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        State<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled
                        value={agentData.state}
                        name="state"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                        placeholder="State"
                      />
                    </div>

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Pin Code<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled
                        value={agentData.pincode}
                        name="pincode"
                        type="number"
                        className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                        placeholder="Pin Code"
                      />
                    </div>
                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Domicile<span className="text-red-500">*</span>
                      </label>
                      <input
                        onChange={handleChange}
                        disabled
                        value={agentData.domicile}
                        name="domicile"
                        type="text"
                        className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                        placeholder="Domicile"
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      {/* Gender Dropdown */}
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Gender<span className="text-red-500">*</span>
                        </label>
                        <select
                          disabled
                          onChange={handleChange}
                          value={agentData.gender}
                          name="gender"
                          className="w-full p-2 border border-gray-300 rounded-none bg-[#f3f3f3]"
                        >
                          <option value="">Select</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      {/* Marital Status Dropdown */}
                      <div>
                        <label className="block text-black font-medium mb-1 text-[.875rem]">
                          Marital Status<span className="text-red-500">*</span>
                        </label>
                        <select
                          onChange={handleChange}
                          value={agentData?.maritalStatus}
                          name="maritalStatus"
                          className="w-full p-2 border border-gray-300 rounded-none"
                        >
                          <option value="">Select</option>
                          <option value="single">Single</option>
                          <option value="married">Married</option>
                          <option value="divorced">Divorced</option>
                          <option value="widowed">Widowed</option>
                        </select>
                      </div>
                    </div>

                    {/* <div>
                  <label className="block text-black font-medium mb-1 text-[.875rem]">
                    Education<span className="text-red-500">*</span>
                  </label>
                  <input
                    onChange={handleChange}
                    value={agentData.education}
                    name="education"
                    type="text"
                    className="w-full p-2 border border-gray-300 rounded-none"
                    placeholder="Education"
                  />
                </div> */}

                    <div>
                      <label className="block text-black font-medium mb-1 text-[.875rem]">
                        Email ID
                        {/* <span className="text-red-500">*</span> */}
                      </label>
                      <input
                        onChange={handleChange}
                        value={agentData.email}
                        name="email"
                        type="email"
                        className="w-full p-2 border border-gray-300 rounded-none"
                        placeholder="Email ID"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex items-center justify-between mt-1 relative">
                <div className="absolute top-1/2 -translate-y-1/2 left-0">
                  <Link
                    to="/login"
                    className="text-[#3c8f47] font-semibold text-center block hover:text-green-900 transition-all duration-300 ease-in-out text-sm "
                    onClick={() => {
                      dispatch(setIsAgentAadharVerified(false));
                      dispatch(setPanVerifiedData(false));
                    }}
                  >
                    Back to Login
                  </Link>
                </div>

                <div className="button-container">
                  <button
                    type="submit"
                    className="submitBtn"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={2000} />
      </div>
      <CustomModal
        isOpenCustModal={AadharOtpModal}
        onClose={closeAadharOtpModal}
        modalName="aadharVerificationModal"
      >
        <OTPVerificationInput
          setAadhaOtp={setAadhaOtp}
          handleSubmitAadharOtp={handleSubmitAadharOtp}
          handleVerify={handleSendOtpToVerifyAadhar}
        />
        {/* {isCustModalOpenCalculator && <LoanEMICalculator />} */}
      </CustomModal>
    </section>
  );
};

export default AgentSignUp;
