export const menuItemsData = [
  {
    id: 1,
    title: "Home",
    url: "/",
  },
  {
    id: 2,
    title: "About Us",
    url: "/aboutUs",
  },
  {
    id: 3,
    title: "Loans",
    url: "",
    submenu: [
      {
        id: 21,
        title: "Gold",
        url: "gold-loan",
      },
      {
        id: 22,
        title: "Personal",
        url: "personal-loan",
      },
      {
        id: 23,
        title: "Housing",
        url: "home-loan",
      },
      {
        id: 24,
        title: "Vehicle",
        url: "vehicle-loan",
      },
      {
        id: 25,
        title: "Business",
        url: "business-loan",
      },
      {
        id: 26,
        title: "Property",
        url: "property-loan",
      },
      {
        id: 27,
        title: "Arts & Antiques",
        url: "arts-antique-loan",
      },
      {
        id: 28,
        title: "Shares & Security",
        url: "shares-security-loan",
      },
      {
        id: 29,
        title: "SME",
        url: "sme-loan",
      },
      {
        id: 210,
        title: "Holiday",
        url: "holiday-loan",
      },
      {
        id: 211,
        title: "Wedding",
        url: "wedding-loan",
      },
      {
        id: 212,
        title: "Professional Loans",
        url: "",
        submenu: [
          {
            id: 2120,
            title: "CA",
            url: "/ca-loan",
          },
          {
            id: 2121,
            title: "Doctor",
            url: "/doctor-loan",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "Knowledge Center",
    url: "/knowledge-center",
  },
  {
    id: 5,
    title: "FAQ's",
    url: "/faq",
  },
];
