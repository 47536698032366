import React, { useState, ChangeEvent } from "react";
import Tesseract from "tesseract.js";
import { extractAadhaarNumber, removeSpaces } from "../../utils/ocrUtils";
import Loader from "../../utils/Loader";
// import { extractAadhaarNumber, removeSpaces } from "./ocrUtils";

const AadharOcr: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const [language, setLanguage] = useState<string>("eng");
  const [result, setResult] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [aadharNum, setAadharNum] = useState<string>("");

  // Handles file input change and sets file and image preview
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setImageUrl(URL.createObjectURL(selectedFile));
    }
  };
//   console.log("file :",file);
//   console.log("progress :",progress);
//   console.log("result :",result);
  

  // Processes the uploaded image for OCR
  const processImage = () => {
    if (!file) return; // Early return if no file is selected

    setResult("");
    setProgress(0);

    Tesseract.recognize(file, language, {
      logger: (m) => {
        // Log progress updates
        if (m.status === "recognizing text") {
          setProgress(m.progress);
        }
      },
    })
      .then(({ data: { text } }) => {
        // Set the result after OCR completes
        setResult(text);
      })
      .catch((error) => {
        console.error("Error recognizing image:", error);
        setResult("Error recognizing image.");
      });
  };

  // Extract Aadhar number from the OCR result
  const getAadharNumber = () => {
    const removeSpace = removeSpaces(result);
    if (removeSpace) {
      setAadharNum(extractAadhaarNumber(removeSpace));
    }
  };

  return (
    <div className="App">
      <input type="file" accept=".png, .jpeg, .jpg" onChange={onFileChange} />
      <select value={language} onChange={(e) => setLanguage(e.target.value)}>
        <option value="eng">English</option>
        <option value="tel">Telugu</option>
        <option value="hin">Hindi</option>
        <option value="kan">Kannada</option>
      </select>

      {/* Display the uploaded image */}
      {imageUrl && (
        <div style={{ marginTop: 20 }}>
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}

      <div style={{ marginTop: 25 }}>
        <input type="button" value="Submit" onClick={processImage} />
      </div>

      <div>
        <progress value={progress} max={1} />
        {progress<1 ? <Loader/> :null}
      </div>

      {/* Display the OCR result */}
      {result && (
        <div style={{ marginTop: 20, fontSize: 24, color: "teal" }}>
          Result: {result}
        </div>
      )}
      <button onClick={getAadharNumber}>Get Aadhar</button>
      {aadharNum && (
        <div style={{ marginTop: 20, fontSize: 24, color: "blue" }}>
          Aadhar Number: {aadharNum}
        </div>
      )}
    </div>
  );
};

export default AadharOcr;
