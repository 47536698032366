import { useState } from "react";
import CreateButton from "../../components/LoansComponents/CreateButton";
import DeleteButton from "../../components/LoansComponents/DeleteButton";
import UpdateButton from "../../components/LoansComponents/UpdateButton";
import SendButton from "../../components/LoansComponents/SendButton";
import CreatePopup from "../../components/LoansComponents/CreatePopup";
import SendPopup from "../../components/LoansComponents/SendPopup";
import DeletePopup from "../../components/LoansComponents/DeletePopup.tsx";
import UpdatePopup from "../../components/LoansComponents/UpdatePopup";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";

const NotificationScreen = () => {
  const location = useLocation();
  const [isOpenSend, setIsOpenSend] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const createPopup = () => {
    setIsOpenCreate(!isOpenCreate);
  };

  const sendPopup = () => {
    setIsOpenSend(!isOpenSend);
  };

  const updatePopup = () => {
    setIsOpenUpdate(!isOpenUpdate);
  };

  const deletePopup = () => {
    setIsOpenDelete(!isOpenDelete);
  };

  const items = [
    { id: 1, title: "Item 1", description: "Description for item 1" },
    { id: 2, title: "Item 2", description: "Description for item 2" },
    { id: 3, title: "Item 3", description: "Description for item 3" },
  ];

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="mt-16">
      <div className="flex flex-col">
        <Header name="Notification List" />
        <div className="flex justify-between items-center mx-6 my-2">
          <div className="flex items-center">
            <input
              className="p-2 rounded-md border border-gray-300 min-w-96"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <CreateButton createPopup={createPopup} />
        </div>
        <CreatePopup isOpenCreate={isOpenCreate} createPopup={createPopup} />

        <section className="overflow-x-auto">
          <div className="bg-white shadow-md rounded-lg">
            <table className="min-w-full text-center">
              <thead className="bg-[#3c8f47] text-white">
                <tr>
                  <th className="px-4 py-2 text-center border w-1/4">ID</th>
                  <th className="px-4 py-2 text-center border w-1/4">Title</th>
                  <th className="px-4 py-2 text-center border w-1/4">
                    Description
                  </th>
                  <th className="px-4 py-2 text-center border w-1/4">Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredItems.map((item) => (
                  <tr key={item.id} className="border 1 solid gray-500">
                    <td className="px-4 py-2">{item.id}</td>
                    <td className="px-4 py-2">{item.title}</td>
                    <td className="px-4 py-2">{item.description}</td>
                    <td className="px-4 py-2">
                      <div className="flex justify-center space-x-2">
                        <SendButton sendPopup={sendPopup} />
                        <SendPopup
                          isOpenSend={isOpenSend}
                          sendPopup={sendPopup}
                        />

                        <UpdateButton updatePopup={updatePopup} />
                        <UpdatePopup
                          isOpenUpdate={isOpenUpdate}
                          updatePopup={updatePopup}
                        />

                        <DeleteButton deletePopup={deletePopup} />
                        <DeletePopup
                          isOpenDelete={isOpenDelete}
                          deletePopup={deletePopup}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default NotificationScreen;
