import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBorrowerById } from "../../utils/apis";
import { CommonSliceState } from "../../utils/types";

const initialState: CommonSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

export const handleGetBorrowerData = createAsyncThunk(
  "getBorrowerData",
  async (borrowerId: number) => {
    const response = await getBorrowerById(borrowerId);
    return response; // Ensure this is the correct value to store in state
  }
);

const getBorrowerDataSlice = createSlice({
  name: "getDocumentReviewStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleGetBorrowerData.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(handleGetBorrowerData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Ensure the payload is assigned correctly
      })
      .addCase(handleGetBorrowerData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "documentReviewStatus failed";
        console.log("rejected");
      });
  },
});

export default getBorrowerDataSlice.reducer;
