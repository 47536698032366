import React from "react";
import { ChildComponentDeleteProps } from "../../utils/types";

const DeletePopup: React.FC<ChildComponentDeleteProps> = ({
  isOpenDelete,
  deletePopup,
}: any) => {
  return (
    <div>
      {isOpenDelete && (
        <div className="fixed inset-0 flex justify-center items-center z-50">
          <div className="bg-[#133b19] rounded-lg w-96 p-4 relative">
            <h2 className="text-white text-xl pl-4">Delete Loan Details</h2>
            <div className="bg-gray-100 p-4">
              <p className="text-center text-lg mb-4">
                Are you sure you want to delete this item?
              </p>
              <div className="flex justify-between mt-4">
                <button
                  onClick={deletePopup}
                  className="w-1/2 bg-[#133b19] mr-4 text-white py-2 rounded-md hover:bg-[#165423] "
                >
                  Close
                </button>
                <button
                  onClick={deletePopup}
                  className="w-1/2 bg-[#eb0e0e] text-white py-2 rounded-md hover:bg-[#c82333]"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeletePopup;
