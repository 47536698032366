import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "../../../components/Header";

// Import images
import IMGBLACK1 from "../../../assets/loans-icons/Icons-01.svg";
import IMGBLACK2 from "../../../assets/loans-icons/Icons-02.svg";
import IMGBLACK3 from "../../../assets/loans-icons/Icons-03.svg";
import IMGBLACK4 from "../../../assets/loans-icons/Icons-04.svg";
import IMGBLACK5 from "../../../assets/loans-icons/Icons-05.svg";
import IMGBLACK6 from "../../../assets/loans-icons/Icons-06.svg";
import IMGBLACK7 from "../../../assets/loans-icons/Icons-07.svg";
import IMGBLACK8 from "../../../assets/loans-icons/Icons-08.svg";
import IMGBLACK9 from "../../../assets/loans-icons/Icons-09.svg";
import IMGBLACK11 from "../../../assets/loans-icons/Icons-11.svg";
import IMGBLACK12 from "../../../assets/loans-icons/Icons-12.svg";
import IMGBLACK14 from "../../../assets/loans-icons/Icons-14.svg";
import IMGBLACK15 from "../../../assets/loans-icons/Icons-15.svg";
import IMGWHITE1 from "../../../assets/loans-icons/Yellow_Icons-62.svg";
import IMGWHITE2 from "../../../assets/loans-icons/Yellow_Icons-63.svg";
import IMGWHITE3 from "../../../assets/loans-icons/Yellow_Icons-64.svg";
import IMGWHITE4 from "../../../assets/loans-icons/Yellow_Icons-65.svg";
import IMGWHITE5 from "../../../assets/loans-icons/Yellow_Icons-66.svg";
import IMGWHITE6 from "../../../assets/loans-icons/Yellow_Icons-67.svg";
import IMGWHITE7 from "../../../assets/loans-icons/Yellow_Icons-68.svg";
import IMGWHITE8 from "../../../assets/loans-icons/Yellow_Icons-69.svg";
import IMGWHITE9 from "../../../assets/loans-icons/Yellow_Icons-70.svg";
import IMGWHITE11 from "../../../assets/loans-icons/Yellow_Icons-72.svg";
import IMGWHITE12 from "../../../assets/loans-icons/Yellow_Icons-73.svg";
import IMGWHITE14 from "../../../assets/loans-icons/Yellow_Icons-75.svg";
import IMGWHITE15 from "../../../assets/loans-icons/Yellow_Icons-76.svg";

// Data for loan categories
const data = [
  // {
  //   id: 1,
  //   slug: "gold-loan",
  //   name: "Gold",
  //   imgBlack: IMGBLACK1,
  //   imgWhite: IMGWHITE1,
  // },
  // {
  //   id: 2,
  //   slug: "vehicle-loan",
  //   name: "Vehicle",
  //   imgBlack: IMGBLACK2,
  //   imgWhite: IMGWHITE2,
  // },
  // {
  //   id: 3,
  //   slug: "property-loan",
  //   name: "Property",
  //   imgBlack: IMGBLACK3,
  //   imgWhite: IMGWHITE3,
  // },
  // {
  //   id: 4,
  //   slug: "shares-security-loan",
  //   name: "Shares & Security",
  //   imgBlack: IMGBLACK4,
  //   imgWhite: IMGWHITE4,
  // },
  // {
  //   id: 5,
  //   slug: "home-loan",
  //   name: "Home",
  //   imgBlack: IMGBLACK5,
  //   imgWhite: IMGWHITE5,
  // },
  // {
  //   id: 6,
  //   slug: "business-loan",
  //   name: "Business",
  //   imgBlack: IMGBLACK6,
  //   imgWhite: IMGWHITE6,
  // },
  {
    id: 1,
    slug: "personal-loan",
    name: "Personal",
    imgBlack: IMGBLACK7,
    imgWhite: IMGWHITE7,
  },
  // {
  //   id: 8,
  //   slug: "wedding-loan",
  //   name: "Wedding",
  //   imgBlack: IMGBLACK8,
  //   imgWhite: IMGWHITE8,
  // },
  // {
  //   id: 9,
  //   slug: "doctor-loan",
  //   name: "Doctor",
  //   imgBlack: IMGBLACK9,
  //   imgWhite: IMGWHITE9,
  // },
  // {
  //   id: 10,
  //   slug: "holiday-loan",
  //   name: "Holiday",
  //   imgBlack: IMGBLACK11,
  //   imgWhite: IMGWHITE11,
  // },
  // {
  //   id: 11,
  //   slug: "ca-loan",
  //   name: "CA",
  //   imgBlack: IMGBLACK12,
  //   imgWhite: IMGWHITE12,
  // },
  // {
  //   id: 12,
  //   slug: "sme-loan",
  //   name: "SME",
  //   imgBlack: IMGBLACK14,
  //   imgWhite: IMGWHITE14,
  // },
  // {
  //   id: 13,
  //   slug: "arts-antique-loan",
  //   name: "Arts & Antiques",
  //   imgBlack: IMGBLACK15,
  //   imgWhite: IMGWHITE15,
  // },
];

const LoansWeProvide = () => {
  const [flippedIndex, setFlippedIndex] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleMouseEnter = (index: number) => {
    setFlippedIndex(index);
  };

  const handleMouseLeave = () => {
    setFlippedIndex(null);
  };

  const handleClick = () => {
    navigate("/profile");
  };

  return (
    <>
      <Header name="Loan List" />
      <div className="flex justify-center items-start min-h-screen bg-gray-100 z-10">
        <div className="gap-y-8 gap-x-6 sm:gap-y-10 sm:gap-x-8 md:gap-y-12 md:gap-x-10 lg:gap-y-14 lg:gap-x-12 pt-24">
          {data.map((item, index) => (
            <div
              key={item.id}
              className="text-center flex flex-col items-center group flipBox"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              <div className="relative mb-2 w-[120px] h-[120px] sm:w-[140px] sm:h-[140px] md:w-[160px] md:h-[160px] flipBox_inner cursor-pointer">
                {/* Front side (Black Icon) */}
                <div
                  className={`absolute top-0 left-0 w-full h-full bg-white border-2 border-green-600 rounded-3xl flex items-center justify-center shadow-lg duration-700 ease-in-out ${
                    flippedIndex === index ? "rotate-y-180" : ""
                  }`}
                  style={{
                    backfaceVisibility: "hidden",
                    transform: "rotateY(0deg)",
                  }}
                >
                  <img
                    src={item.imgBlack}
                    alt={item.name}
                    width={80}
                    height={80}
                    className="sm:w-[90px] sm:h-[90px] md:w-[100px] md:h-[100px]"
                  />
                </div>

                {/* Back side (White Icon with Title) */}
                <div
                  className={`absolute top-0 left-0 w-full h-full bg-green-900 border-2 border-white rounded-3xl flex flex-col items-center justify-center shadow-lg duration-700 ease-in-out ${
                    flippedIndex === index ? "" : "rotate-y-180"
                  }`}
                  style={{
                    backfaceVisibility: "hidden",
                    transform: "rotateY(180deg)",
                  }}
                >
                  <img
                    src={item.imgWhite}
                    alt={item.name}
                    width={80}
                    height={80}
                    className="sm:w-[90px] sm:h-[90px] md:w-[100px] md:h-[100px]"
                  />
                </div>
              </div>
              <h5 className="text-center text-black text-sm sm:text-base md:text-lg mt-2">
                {item.name}
              </h5>
            </div>
          ))}
        </div>
      </div>
      {/* <button className="bg-red-400" onClick={() => navigate("/upload-documents")}>
        route to upload doc
      </button> */}
    </>
  );
};

export default LoansWeProvide;
