import Container from "../../components/ui/Container";
const IMG1 = require("../../assets/lowestInterestRate.png");
const IMG2 = require("../../assets/latestDocumentation.png");
const IMG3 = require("../../assets/minimumCibilScore.png");
const IMG4 = require("../../assets/completeLandingSystem.png");

const data = [
  {
    img: IMG1,
    title: "Lowest Interest in Market",
  },
  {
    img: IMG2,
    title: "Least Documentation",
  },
  {
    img: IMG3,
    title: "Minimum CIBIL Score 600*",
  },
  {
    img: IMG4,
    title: "Compete Lending System",
  },
];

const WhyChooseUs = () => {
  return (
    <section className="py-12 bg-slate-100">
      <Container className={""}>
        <h4 className="text-3xl lg:text-5xl text-center mb-6">
          <span className="text-green-600 font-bold">Why</span> Choose Us?
        </h4>
        <div className="bg-green-600 grid grid-cols-2 lg:grid-cols-4 rounded-lg overflow-hidden">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="text-white flex flex-col sm:flex-row justify-center sm:justify-evenly text-center items-center gap-2 py-4 px-6 border-r border-b border-r-green-700 border-b-green-700"
              >
                <img
                  src={item.img}
                  alt={item.title}
                  width={75}
                  height={75}
                  className="w-10 h-10"
                />
                <div className="text-xs md:text-base">{item.title}</div>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};
export default WhyChooseUs;
