
import "../styles/App.css";
import { LoaderType } from "./types";

const Loader: React.FC<LoaderType> = ({ customClass }) => {
  return (
    <div className="loader-container">
      <div className={`loader w-5 h-5  ${customClass}`}></div>
    </div>
  );
};

export default Loader;
