import MenuItems from "../../components/ui/MenuItems";
import { menuItemsData } from "../../utils/menuItemsData";
const styles = require("../../styles/Landingstyles/header.css");

function Navbar() {
  const depthLevel = 0;
  return (
    <nav className="desktop-nav">
      <ul className={`${styles.submenu} menus max-w-7xl mx-auto px-4`}>
        {menuItemsData.map((menu: any, index: any) => {
          return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
        })}
      </ul>
    </nav>
  );
}

export default Navbar;
