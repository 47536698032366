import { useEffect, useState, useCallback } from "react";
import Header from "../../components/Header";
import styles from "../../styles/documents/disbursement.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { useNavigate } from "react-router-dom";
import { getAllDisburseBorrowDetails } from "../../redux/disbursement/disbursementSlice";
import { debounce } from "lodash";

const Disbursement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [borrowerDataAfterApi, setBorrowerDatabyApi] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>([]);
  const { data, status } = useSelector(
    (state: RootState) => state.disbursement
  );
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllDisburseBorrowDetails({}));
  }, [dispatch]);

  useEffect(() => {
    if (status === 3) {
      if (data && data.data) {
        setBorrowerDatabyApi(data.data);
        setFilteredData(data.data);
      } else {
        setBorrowerDatabyApi([]);
        setFilteredData([]);
      }
    }
  }, [data, status]);

  // Debounced search function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (query) {
        const filtered = borrowerDataAfterApi.filter((item: any) =>
          item.fullName.toLowerCase().includes(query.toLowerCase())
        );
        console.log(filtered);
        setFilteredData(filtered);
      } else {
        setFilteredData(borrowerDataAfterApi); // Reset to full list if search is cleared
      }
    }, 500), // 500ms debounce delay
    [borrowerDataAfterApi]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    console.log(query);
    setSearchQuery(query);
    debouncedSearch(query); // Call the debounced function
  };

  const DataDisplayTable = ({ data }: any) => {
    const callDetailsPage = (id: any, amount: any) => {
      navigate("/borrowerpayment", {
        state: { borrowerId: id, amount: amount },
      });
    };
    return (
      <>
        {data.length > 0 ? (
          <div className={styles.table_container}>
            <div className={styles.table_header}>
              <div className={styles.header_item}>Customer Name</div>
              <div className={styles.header_item}>LoanAmount</div>
              <div className={styles.header_item}>Rate of intrest</div>
              <div className={styles.header_item}>Details</div>
            </div>
            {data.map((item: any, index: any) => (
              <div className={styles.table_row} key={item.id}>
                <div className={styles.table_cell}>{item.fullName}</div>
                <div className={styles.table_cell}>₹{item.amount}</div>
                <div className={styles.table_cell}>{item.interestRate}%</div>
                <div className={styles.table_cell}>
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => callDetailsPage(item.id, item.amount)}
                  >
                    Details
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.no_item}>No Data</div>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <Header name="Disbursement" />
      <div className={styles.table_container1}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by Customer Name"
          className={styles.search_input}
        />
        {borrowerDataAfterApi && <DataDisplayTable data={filteredData} />}
      </div>
    </div>
  );
};

export default Disbursement;
