import * as Yup from "yup";
export const personalInfoValidation = Yup.object({
  aadhaarNumber: Yup.string()
    .matches(/^\d{12}$/, "Aadhaar number must be 12 digits.")
    .required("Required."),
  panCardNumber: Yup.string()
    .transform((value) => (value ? value.toUpperCase() : value))
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Enter a valid PAN number.")
    .required("Required."),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
    .required("Required."),
  email: Yup.string().email("Invalid email address.").required("Required."),
  fullName: Yup.string().required("Required."),
  birthDate: Yup.string().required("Required."),
  gender: Yup.string()
    .required("Required")
    .oneOf(["Male", "Female", "Other"], "Invalid gender selected"),
    tenure: Yup.string()
    .required("Required"),
    // .oneOf(["male", "female", "other"], "Invalid gender selected"),
  maritalStatus: Yup.string()
    .required("Required")
    .oneOf(["Married", "Unmarried"], "Invalid Marital Status selected"),
    loanTypeId: Yup.string()
    .required("Required"),
    // .oneOf(["Married", "Unmarried"], "Invalid Marital Status selected"),

  // tenure: Yup.string().required("required"),
  // .oneOf(
  //   ["100 days", "200 days", "300 days", "52 weeks", "104 weeks"],
  //   "Invalid Tenure selected"
  // ),

  address: Yup.string().required("Required."),
  city: Yup.string().required("Required."),
  state: Yup.string().required("Required."),
  pincode: Yup.string()
    .matches(/^\d{6}$/, "Pincode must be 6 digits.")
    .required("Required."),
  loanAmount: Yup.number()
    .required("Required.")
    .typeError("Loan Amount must be a number")
    .min(10000, "Loan Amount must be at least ₹10,000")
    .max(250000, "Loan Amount must not exceed ₹2,50,000"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters.")
    .required("Required."),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match.")
    .required("Required."),
});
