import { useRef, useState } from "react";
import { menuItemsData } from "../../utils/menuItemsData";
import MobileMenuItems from "./MobileMenuItems";

const MobileNav = () => {
  const depthLevel = 0;
  const [showMenu, setShowMenu] = useState(false);
  let ref = useRef<HTMLUListElement | null>(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
    // console.log(showMenu);
  };

  return (
    <nav className="mobile-nav !flex !justify-end">
      <button className="mobile-nav__menu-button" type="button">
        <div
          className={`${showMenu ? "active burger" : "burger"}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      {showMenu && (
        <ul className="menus" ref={ref}>
          {menuItemsData.map((menu: any, index: any) => {
            return (
              <MobileMenuItems
                items={menu}
                key={index}
                depthLevel={depthLevel}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            );
          })}
        </ul>
      )}
    </nav>
  );
};

export default MobileNav;
