import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getLoanDetails, getDisbursementDetails } from "../../utils/apis";
import { CommonSliceState } from "../../utils/types";

const initialState: CommonSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

export const getAllLoanDetails = createAsyncThunk(
  "getAllLoanDetailState",
  async (borrowerId: any) => {
    let borrowerID = borrowerId.borrowerId;
    const response = await getLoanDetails(borrowerID);
    return response;
  }
);

export const getAllDisbursementDetails = createAsyncThunk(
  "getDisbursementDetails",
  async (borrowerId: any) => {
    let borrowerID = borrowerId.borrowerId;
    const response = await getDisbursementDetails(borrowerID);
    return response;
  }
);

const loanDetailsSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLoanDetails.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllLoanDetails.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllLoanDetails.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Loan Details failed";
      });

    builder
      .addCase(getAllDisbursementDetails.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDisbursementDetails.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getAllDisbursementDetails.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error =
          action.error.message || "Loan Disbursement Details failed";
      });
  },
});
export default loanDetailsSlice.reducer;
