import Header from "../../../components/Header";
import Container from "../../../components/ui/Container";

function AgentDashboard() {
  return (
    <>
      <Header name="Dashboard" />
      <Container>
        <div className="mt-28 flex items-center justify-between gap-4">
          <div className="">No. of Borrowers: 1</div>
          <div className="">No. of Pending Borrowers: 0</div>
          <div className="">No. of Approved Borrowers: 10</div>
        </div>
      </Container>
    </>
  );
}

export default AgentDashboard;
