import { useNavigate } from "react-router-dom";
import Header from "../components/Header";

function DemoPage() {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/loandetails");
  };

  const handleNext = () => {
    navigate("/disbursementforloan", { state: "disbursement" });
  };

  return (
    <>
      <Header name="Demo Page" />
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-blue-50 to-gray-100 mt-16 md:mt-0 relative">
        <div className="bg-white shadow-lg rounded-lg max-w-2xl w-full p-8 mx-4 md:mx-auto">
          <h2 className="text-2xl font-semibold text-gray-800 text-center">
            Loan Application Guidelines
          </h2>
          <h2 className="text-md font-semibold text-gray-800 text-center mb-6">
            This page is for demonstration purposes only.
          </h2>
          <div className="text-gray-700 space-y-4">
            <p>
              <span className="font-semibold text-red-500">
                Cibil Score &lt; 500:
              </span>{" "}
              Application is{" "}
              <span className="font-bold text-red-600">On Hold</span> for
              consultation.
            </p>
            <p>
              <span className="font-semibold text-yellow-500">
                Cibil Score 500-600:
              </span>{" "}
              Loan application is{" "}
              <span className="font-bold text-yellow-600">
                approved on special terms
              </span>
              .
            </p>
            <p>
              <span className="font-semibold text-green-500">
                Cibil Score &gt; 600:
              </span>{" "}
              Loan application is{" "}
              <span className="font-bold text-green-600">
                Approved By Default
              </span>
              .
            </p>
          </div>
          <div className="flex justify-center items-center mt-8 gap-4">
            <button
              onClick={handleBack}
              className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 transition-all w-32"
            >
              Back
            </button>
            <button
              onClick={handleNext}
              className="bg-green-600 text-white px-6 py-2 rounded-lg shadow-lg hover:bg-green-700 focus:ring-4 focus:ring-green-300 transition-all w-32"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoPage;
