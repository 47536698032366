import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eqModal: false,
  isMobile: window.innerWidth <= 1024,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    toggleEnquiryModal: (state) => {
      state.eqModal = !state.eqModal;
    },
    checkViewPort: (state) => {
      state.isMobile = window.innerWidth <= 1024;
    },
  },
});

export const { toggleEnquiryModal, checkViewPort } = globalSlice.actions;

export default globalSlice.reducer; // Export the reducer function
