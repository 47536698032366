import axios, { AxiosRequestConfig } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const paymentAPI = axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
});

const nodeAPI = axios.create({
  baseURL: process.env.REACT_APP_NODE_URL,
});

export const callApi = async (
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any
) => {
  try {
    let data = body.body;
    if (data) {
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
        data,
      };

      const response = await api(config);
      return response.data;
    } else {
      const config: AxiosRequestConfig = {
        method,
        url: endpoint,
      };
      const response = await api(config);
      return response.data;
    }
  } catch (error: any) {
    return error.response?.data;
  }
};

export const callApiWithParams = async (
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: any
) => {
  try {
    const config: AxiosRequestConfig = {
      method,
      url: endpoint + `/${body.body.borrowerID}`,
    };
    const response = await api(config);
    return response.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const callApiIOPIntent = async (body: any) => {
  let data = body.body;
  const config: AxiosRequestConfig = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      opStatus: "1",
      "Access-Control-Allow-Origin": "*",
    },
    data,
  };
  const response = await paymentAPI(config);
  return response.data;
};

export const callApiPaymentStatus = async (endpoint: string) => {
  try {
    const config: AxiosRequestConfig = {
      method: "GET",
      url: endpoint,
    };
    const response = await nodeAPI(config);
    return response.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

export const loginUser = async (body: string) => {
  return await callApi("/v1/auth/login", "POST", { body });
};

export const registerUser = async (body: string) => {
  return await callApi("/v1/auth/login", "POST", { body });
};

export const forgotPassUser = async (body: string) => {
  return await callApi("/v1/password/sendEmailForPasswordForgot", "POST", {
    body,
  });
};

export const getAllBorrowerApi = async (token: string) => {
  return await callApi("/v1/auth/getAllBorrowerData", "GET", { token });
};

export const getDocumentApi = async (body: any) => {
  return await callApiWithParams("/v1/checklist/getborrowerChecklist", "GET", {
    body,
  });
};

export const postDocumentApi = async (body: string) => {
  return await callApi("/v1/checklist/create", "POST", { body });
};

export const getDisbursementApi = async (body: any) => {
  return await callApiWithParams(
    "/v1/disbursement/getDisbursementDataById",
    "GET",
    { body }
  );
};

export const getAllDisbursementBorrowerDetailsApi = async (body: any) => {
  return await callApi("/v1/disbursement/getAllDisbursement", "GET", { body });
};

export const getDisbursementBorrowerDetailsApi = async (body: any) => {
  return await callApiWithParams("/v1/disbursement/borrower-details", "GET", {
    body,
  });
};

export const forgotPassUserReset = async (body: string) => {
  return await callApi("/v1/auth/login", "POST", { body });
};

export const resetPassUser = async (body: string) => {
  return await callApi("/v1/auth/login", "POST", { body });
};

export const getProfileDetails = async () => {
  return await callApi("/v1/auth/getBorrowerDataById/62", "GET", {});
};

export const postDocumentFile = async (body: any) => {
  try {
    return await callApi("/v1/documents/upload", "POST", { body });
  } catch (error) {
    console.log("postDocumentFile ERROR :", error);
  }
};

export const postSignup = async (body: any) => {
  try {
    return await callApi("v1/auth/signup", "POST", { body });
  } catch (error) {
    console.log("Signup ERROR:", error);
  }
};

export const getDocumentReviewStatus = async (body: any) => {
  const { borrowerId, loanTypeId } = body;
  return await callApi(
    `/v1/getReviewDoc/${loanTypeId}/${borrowerId}`,
    "GET",
    {}
  );
};

export const postUploadReviewDocument = async (
  body: any,
  borrowerId: number
) => {
  return await callApi(`/v1/uploadReviewDocument/${borrowerId}`, "POST", {
    body,
  });
};

export const getLoanDetails = async (data: number) => {
  return await callApi(`v1/auth/loanDetails/${data}`, "GET", {});
};

export const getDisbursementDetails = async (borrowerID: number) => {
  return await callApi(`v1/loan/getEmiDetails/${borrowerID}`, "GET", {});
};

export const getBorrowerById = async (borrowerID: number) => {
  return await callApi(`v1/auth/getBorrowerDataById/${borrowerID}`, "GET", {});
};

export const getReceiptApi = async () => {
  return await callApi(
    `v1/paymentReceipts/generatePaymentReceipt/1`,
    "GET",
    {}
  );
};

export const getIOPIntent = async (body: any) => {
  return await callApiIOPIntent({ body });
};

export const getPaymentStatus = async (invoiceId: any) => {
  return await callApiPaymentStatus(`?${invoiceId}`);
};

export const getPhonepayPaymentStatus = async (invoiceId: any) => {
  return await callApi(`v1/getUpdateCall?${invoiceId}`, "GET");
};

export const getPhonePayIntent = async () => {
  return await callApi("v1/callIntent", "GET");
};

export const postGeneratedisbursment = async (data: any) => {
  try {
    return await callApi(
      `v1/disbursement/generateDisburse/${data}`,
      "POST",
      {}
    );
  } catch (error) {
    console.log("Signup ERROR:", error);
  }
};

export const postAgentSignup = async (body: any) => {
  try {
    return await callApi(`v1/agent/signup`, "POST", { body });
  } catch (error) {
    console.log("Signup ERROR:", error);
  }
};

export const createEmiIntent = async (body: any) => {
  try {
    return await callApi(
      `v1/createEmiIntent/1/${body.borrowerId}/${body.breakdownId}`,
      "GET",
      {}
    );
  } catch (error) {
    console.log("CreateEmiIntent ERROR:", error);
  }
};

export const agentProfileById = async (agentId: number) => {
  try {
    return await callApi(`v1/agent/agentProfileById/${agentId}`, "GET", {});
  } catch (error) {
    console.log("agentProfileById ERROR:", error);
  }
};

export const createAgentIntent = async (agentId: number) => {
  try {
    return await callApi(`v1/createAgentIntent/${agentId}`, "GET", {});
  } catch (error) {
    console.log("createAgentIntent ERROR:", error);
  }
};

export const getAllApprovedBorrowerByAgentId = async (agentId: number) => {
  try {
    return await callApi(
      `v1/agent/getAllApprovedBorrowerByAgentId/${agentId}`,
      "GET",
      {}
    );
  } catch (error) {
    console.log("getAllApprovedBorrowerByAgentId ERROR:", error);
  }
};

export const getAllBorrowerByAgentId = async (agentId: number) => {
  try {
    return await callApi(
      `v1/agent/getAllBorrowerByAgentId/${agentId}`,
      "GET",
      {}
    );
  } catch (error) {
    console.log("getAllBorrowerByAgentId ERROR:", error);
  }
};
