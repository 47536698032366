// JSX Component with Tailwind CSS classes
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactNode,
  ReactPortal,
  useState,
} from "react";
import CreateButton from "../../../components/LoansComponents/CreateButton";
import DeleteButton from "../../../components/LoansComponents/DeleteButton";
import UpdateButton from "../../../components/LoansComponents/UpdateButton";
import CreatePopup from "../../../components/LoansComponents/CreatePopup";
import DeletePopup from "../../../components/LoansComponents/DeletePopup.tsx";
import UpdatePopup from "../../../components/LoansComponents/UpdatePopup";
import Header from "../../../components/Header";
import { useLocation } from "react-router-dom";
import { ListItem } from "../../../utils/types";

const LoanForm = () => {
  const location = useLocation();
  const receivedData = location.state?.message || "No data received";
  console.log("sunder", receivedData);

  // const [isOpen, setIsOpen] = useState(false);
  const [isOpenCreate, setIsOpenCreate] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [isOpenUpdate, setIsOpenUpdate] = useState(false);

  // const toggleSidebar = () => setIsOpen(!isOpen);
  const createPopup = () => setIsOpenCreate(!isOpenCreate);
  const updatePopup = () => setIsOpenUpdate(!isOpenUpdate);
  const deletePopup = () => setIsOpenDelete(!isOpenDelete);

  const items: ListItem[] = [
    // Sample items data
    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },
    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },

    {
      id: 1,
      title: "Item 1",
      description: "Description for item 1",
      minAmt: 1000,
      maxAmt: 5000,
      minInterest: 1.5,
      maxInterest: 5.0,
      particular: ["Particular 1", "Particular 2"],
      twelveMonthsProduct: ["12M Product 1", "12M Product 2"],
      sixMonthsProduct: ["6M Product 1", "6M Product 2"],
    },
    // Add other items here
  ];

  // const formatTitle = (str: string) => {
  //   return str
  //     .split("-")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  return (
    <div className="flex flex-col flex-1 mt-16">
      <Header name={receivedData} />

      <div className="flex justify-end mr-14">
        <CreateButton createPopup={createPopup} />
        <CreatePopup isOpenCreate={isOpenCreate} createPopup={createPopup} />
      </div>

      <section className="bg-white m-2">
        <div className="overflow-y-auto h-[calc(100vh-100px)]">
          <table className="w-full border-collapse">
            <thead className="sticky top-0 bg-gray-300 z-10">
              <tr>
                <th className="border p-2 bg-green-700 text-white">ID</th>
                <th className="border p-2 bg-green-700 text-white">Title</th>
                <th className="border p-2 bg-green-700 text-white">
                  Description
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  Min Amount
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  Max Amount
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  Min Interest
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  Max Interest
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  Particulars
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  12M Products
                </th>
                <th className="border p-2 bg-green-700 text-white">
                  6M Products
                </th>
                <th className="border p-2 bg-green-700 text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item) => (
                <tr key={item.id}>
                  <td className="border p-2 text-center">{item.id}</td>
                  <td className="border p-2 text-center">{item.title}</td>
                  <td className="border p-2 text-center">{item.description}</td>
                  <td className="border p-2 text-center">${item.minAmt}</td>
                  <td className="border p-2 text-center">${item.maxAmt}</td>
                  <td className="border p-2 text-center">
                    {item.minInterest}%
                  </td>
                  <td className="border p-2 text-center">
                    {item.maxInterest}%
                  </td>
                  <td className="border p-2 text-center">
                    <ul className="list-none">
                      {item.particular.map(
                        (
                          particular:
                            | string
                            | number
                            | boolean
                            | ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                              >
                            | Iterable<ReactNode>
                            | ReactPortal
                            | null
                            | undefined,
                          index: Key | null | undefined
                        ) => (
                          <li key={index}>{particular}</li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="border p-2 text-center">
                    <ul className="list-none">
                      {item.twelveMonthsProduct.map(
                        (
                          product:
                            | string
                            | number
                            | boolean
                            | ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                              >
                            | Iterable<ReactNode>
                            | ReactPortal
                            | null
                            | undefined,
                          index: Key | null | undefined
                        ) => (
                          <li key={index}>{product}</li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="border p-2 text-center">
                    <ul className="list-none">
                      {item.sixMonthsProduct.map(
                        (
                          product:
                            | string
                            | number
                            | boolean
                            | ReactElement<
                                any,
                                string | JSXElementConstructor<any>
                              >
                            | Iterable<ReactNode>
                            | ReactPortal
                            | null
                            | undefined,
                          index: Key | null | undefined
                        ) => (
                          <li key={index}>{product}</li>
                        )
                      )}
                    </ul>
                  </td>
                  <td className="border p-2">
                    <div className="flex flex-col items-center">
                      <UpdateButton updatePopup={updatePopup} />
                      <UpdatePopup
                        isOpenUpdate={isOpenUpdate}
                        updatePopup={updatePopup}
                      />
                      <DeleteButton deletePopup={deletePopup} />
                      <DeletePopup
                        isOpenDelete={isOpenDelete}
                        deletePopup={deletePopup}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default LoanForm;
