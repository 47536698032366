import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocumentReviewStatus } from "../../utils/apis";
import { OcrSliceState } from "../../utils/types";

const initialState: OcrSliceState = {
  AadharOcrNumber: "",
  previewsAadharNumber: "",
  PanOcrNumber: "",
  previewsPanNumber: "CYIPS8165C",
};

export const handleGetDocReviewStatusDetails = createAsyncThunk(
  "OCR",
  async (data: any) => {
    const response = await getDocumentReviewStatus(data);
    return response;
  }
);

const OcrDocumentSlice = createSlice({
  name: "getOcr",
  initialState,
  reducers: {
    storeAadharOcrNum: (state, action) => {
      state.AadharOcrNumber = action.payload;
    },
    removeAadharOcrNum: (state) => {
      state.AadharOcrNumber = "";
    },
    storePanOcrNum: (state, action) => {
      state.PanOcrNumber = action.payload;
    },
    removePanOcrNum: (state) => {
      state.PanOcrNumber = "";
    },
  },
});
export const {
  storeAadharOcrNum,
  removeAadharOcrNum,
  storePanOcrNum,
  removePanOcrNum,
} = OcrDocumentSlice.actions;

export default OcrDocumentSlice.reducer;
