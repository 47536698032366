const LogoutPopup = ({ onConfirm, onCancel, text }: any) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
        <h2 className="text-lg font-semibold text-gray-800 mb-6">
          Are you sure you want to {text}?
        </h2>
        <div className="flex justify-around gap-4">
          <button
            onClick={onConfirm}
            className="bg-red-500 text-white px-6 py-2 rounded-md hover:bg-red-600 transition-all"
          >
            Yes, {text}
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 text-gray-800 px-6 py-2 rounded-md hover:bg-gray-400 transition-all"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default LogoutPopup;
