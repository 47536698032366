import { Link } from "react-router-dom";
import Banner from "../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import Logo from "../../assets/Logo_circle.png";
// import { ReactComponent as BackIcon } from "../../assets/icons/back-arrow.svg";
// import { ReactComponent as ConfirmationIcon } from "../../assets/icons/Confirmation.svg";
import Confirmation from "../../components/Register/Confirmation";
import { FaHome } from "react-icons/fa";

function UserProfileScreen() {
  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="h-full w-full bg-no-repeat bg-cover min-h-screen"
    >
      <div className="registerSection py-24 h-dvh flex items-center justify-center">
        <div className="max-w-[1024px] w-full">
          <div className="wizard-container">
            <div className="tab-contentWrapper">
              <ul className="tab-header flex !justify-start mb-6 gap-8">
                <li className="tab-itemHome">
                  <img src={Logo} alt="logo" className="pr-6 w-40" />
                </li>
                <div className="flex items-center gap-1 w-24 h-8">
                  <Link
                    to="/"
                    className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
                  >
                    <FaHome className="fill-green-700  hover:fill-green-900  " />
                    <p className="text-xs text-slate-400">Home Page</p>
                  </Link>
                </div>
                <li className="w-full">
                  <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
                    Profile
                  </h4>
                </li>
              </ul>

              <div className="tab-content">
                <Confirmation source="Profile" />
                <div className="button-container">
                  <Link
                    to="/login"
                    className="text-[#3c8f47] font-semibold text-center block hover:text-green-900 transition-all duration-300 ease-in-out text-sm "
                  >
                    Back to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserProfileScreen;
