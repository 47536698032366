import { useEffect } from "react";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../utils/store";
import { getAllLoanDetails } from "../../../redux/user/LoanDetailsSlice";
import { getUserFromLocalStorage } from "../../../utils/localStorageUtil";
import { handleBeforeUnload } from "../../../utils/getIdFromUrl";

export default function LoanDetails({ props }: any) {
  const navigate = useNavigate();
  const { data } = useSelector((state: RootState) => state.loan);
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = () => {
    props === "repayment"
      ? navigate("/disbursementforloan", {
          state: {
            data: data?.LoanDetails,
          },
        })
      : navigate("/disbursementforloan", { state: "disbursement" });
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const borrowerId = getUserFromLocalStorage()?.borrowerId;
    if (borrowerId && !data?.LoanDetails?.fullName) {
      dispatch(getAllLoanDetails({ borrowerId }));
    }
  }, [dispatch, data]);

  const handleBack = () => {
    navigate(props === "repayment" ? "/" : "/document-review-status-page");
  };

  return (
    <div>
      <Header name="Loan Details" />

      <div className="flex justify-center items-center min-h-screen bg-gray-100 mt-16 md:mt-0 relative">
        <div className="m-auto bg-white px-8 py-6 rounded-lg shadow-lg max-w-2xl w-full absolute left-0 right-0 top-1/2 -translate-y-1/2">
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Name
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.fullName}
            </span>
          </div>
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Email
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.email}
            </span>
          </div>
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Mobile Number
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.mobileNumber}
            </span>
          </div>
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2 ">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Loan ID
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.loanID}
            </span>
          </div>

          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Loan Type
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.loanType}
            </span>
          </div>

          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Loan Amount
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.loanAmount &&
                "₹" + data?.LoanDetails?.loanAmount}
            </span>
          </div>

          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Tenure
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.tenureType}
            </span>
          </div>

          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              Rate of Intrest
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.RateOfInterest &&
                data?.LoanDetails?.RateOfInterest + "%"}
            </span>
          </div>
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              EMI Start Date
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.EMIStartDate}
            </span>
          </div>
          <div className="md:text-lg text-gray-700 flex-col md:flex-row flex items-center border-b pb-2 mb-2">
            <span className="pr-4 text-left w-full md:w-1/3 font-semibold">
              EMI End Date
            </span>
            {/* Vertical line */}
            <span className="font-medium text-base break-words text-blue-600 text-left w-full md:w-10/12">
              {data?.LoanDetails?.EMIEndDate}
            </span>
          </div>
          <div className="flex justify-center items-center mt-4 gap-2">
            <button
              onClick={handleBack}
              className="bg-green-700 text-white border-none px-4 py-2 rounded-lg cursor-pointer hover:bg-green-800 w-28"
            >
              Back
            </button>
            <button
              onClick={handleSubmit}
              disabled={!data?.LoanDetails?.fullName}
              className="bg-green-700 text-white border-none px-4 py-2 rounded-lg cursor-pointer hover:bg-green-800 w-28"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
