import IMG1 from "../../assets/icon1.svg";
import IMG2 from "../../assets/icon2.svg";
import IMG3 from "../../assets/icon3.svg";
import IMG4 from "../../assets/icon4.svg";
import Aitext from "../../assets/aiText.png";
import Container from "../../components/ui/Container";

const data = [
  {
    img: IMG1,
    title: "Improve Efficiency",
    link: "/",
    content:
      "Asha Purti leverages digital platforms and technologies to enhance its loan lending operations, providing customers with convenient and accessible services while optimizing efficiency. This helps reduce manual errors, eliminates unnecessary paperwork, and speeds up the overall process.",
  },
  {
    img: IMG2,
    title: "Delight Customers",
    link: "/",
    content:
      "Asha Purti's streamlined loan application and approval processes, supported by advanced technology and automation. This results in a delightful experience for customers who appreciate the ease and convenience of obtaining a loan",
  },
  {
    img: IMG3,
    title: "Drive Growth",
    link: "/",
    content:
      "Drive business growth with Asha Purti's easy loans, allowing you to focus on your business while we provide the necessary financial support. Your assets which are lying idle are put to use driving business growth.",
  },
  {
    img: IMG4,
    title: "Manage Risk",
    link: "/",
    content:
      "Asha Purti manage risk through careful evaluation of gold collateral, maintaining conservative loan-to-value ratios, implementing proper documentation and verification processes, securing storage facilities, and conducting regular audits and compliance checks.",
  },
];
const ArtificialIntelligence = () => {
  return (
    <section className="py-12 ">
      <div className="aiBg">
        <Container className={""}>
          <h4 className="text-3xl lg:text-5xl text-center mb-6 block">
            All-Digital Lending Enabled by
          </h4>
          <div className="flex justify-center">
            <img src={Aitext} alt={"artificial intelligence"} />
          </div>
          <p className="text-md lg:text-xl text-center mx-auto py-6">
            Provide exceptional digital experiences, say yes to more borrowers,
            <br />
            and improve your portfolio performance.
          </p>
        </Container>
      </div>
      <Container className={""}>
        <div className="bg-transparent md:bg-green-600/50 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 xl:gap-8 p-0 md:p-4 xl:p-8 rounded-xl mt-8 ">
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="rounded-xl bg-white border border-green-600 text-center relative pt-6 pb-4 px-3 hover:shadow-2xl duration-300 ease-in-out"
              >
                <img
                  src={item.img}
                  alt={item.title}
                  width={75}
                  height={75}
                  className="static lg:absolute top-0 left-1/2 lg:-translate-x-1/2 lg:-translate-y-2/3 mx-auto"
                />
                <h4 className="border-b-2 border-b-yellow-400 py-3 uppercase font-bold">
                  {item.title}
                </h4>
                <p className="pt-4 pb-4 text-sm font-light ">{item.content}</p>
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default ArtificialIntelligence;
