import React, { useEffect, useState } from "react";
import styles from "../../styles/documents/borrowerpayment.module.css";
import Header from "../../components/Header";
import { useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../utils/store";
import { useDispatch, useSelector } from "react-redux";
import { getDisburseBorrowDetails } from "../../redux/disbursement/disbursementSlice";
import CustomModal from "../../components/CustomModal";

export default function BorrowerPaymentDetails() {
  const dispatch = useDispatch<AppDispatch>();

  const [borrowerDataAfterApi, setBorrowerDataAfterApi] = useState<any>({});
  const [enableBankDetails, setEnableBankDetails] = useState<boolean>(false);
  const [accNo, setAccNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [aadharCard, setAadharCard] = useState("");
  const [panCard, setPanCard] = useState("");

  const { status, borrowerdata } = useSelector(
    (state: RootState) => state.disbursement
  );
  const location = useLocation();
  const id = location.state?.borrowerId || 0;
  const amount = location.state?.amount || 0;

  //   const fetchTransactionStatus = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://indiaonlinepay.com/api/test/transStatus",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             merchantId: 261,
  //             transactionId: "IN44544678524955018",
  //             secretKey: "ee0585f4-6c83-4227-abdc-d019cb368302",
  //             apiKey: "be27c3fe-cfb5-45b0-a3ca-395ab333abb2",
  //           }),
  //         }
  //       );

  //       if (!response.ok) {
  //         throw new Error(`Error: ${response.statusText}`);
  //       }

  //       const data = await response.json();
  //       setTransactionData(data.Data);
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   useEffect(() => {
  //     const fetchTransactionDetails = async () => {
  //       try {
  //         const response = await fetch("", {
  //           method: "GET",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         });

  //         if (!response.ok) {
  //           throw new Error(`Error: ${response.statusText}`);
  //         }
  //         const data = await response.json();
  //         setTxnStatus(data.paymentStatus);
  //       } catch (error: any) {
  //         console.log(error.message);
  //       }
  //     };

  //     fetchTransactionDetails();
  //   }, [id]);

  useEffect(() => {
    dispatch(getDisburseBorrowDetails({ borrowerID: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (status === 1) {
      if (borrowerdata && borrowerdata.data) {
        setBorrowerDataAfterApi(borrowerdata.data);
      }
    }
  }, [status, borrowerdata]);

  const BorrowerDataDisplayTable = ({ data }: any) => {
    const [selectedLoan, setSelectedLoan] = useState("");

    const handleLoanSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedLoan(event.target.value);
    };

    function handleSubmit() {
      if (selectedLoan) {
        setEnableBankDetails(true);
      } else {
        alert("Please select radio button");
      }
    }

    return (
      <>
        <div className={styles.table_container}>
          <div className={styles.modal_content}>
            <div className={styles.modal_header}>
              <h2>Disbursement</h2>
            </div>
            <div className={styles.grid_container}>
              <div>
                <div style={{ color: "#000", alignContent: "flex-start" }}>
                  Borrower Name
                </div>
                <input
                  type="text"
                  className={styles.grid_item}
                  placeholder={borrowerDataAfterApi.borrowerName}
                  disabled
                />
              </div>

              <div>
                <div style={{ color: "#000" }}>Borrower Code</div>
                <input
                  type="text"
                  className={styles.grid_item}
                  placeholder={borrowerDataAfterApi.borrowerCode}
                  disabled
                />
              </div>
              <div>
                <div style={{ color: "#000" }}>Aadhar Card</div>
                <input
                  type="text"
                  className={styles.grid_item}
                  value={borrowerDataAfterApi.aadharcard}
                  disabled
                />
              </div>

              <div>
                <div style={{ color: "#000" }}>Franchise Name</div>
                <input
                  type="text"
                  className={styles.grid_item}
                  placeholder={borrowerDataAfterApi.franchiseName}
                  disabled
                />
              </div>

              <div>
                <div style={{ color: "#000" }}>Franchise Code</div>
                <input
                  type="text"
                  className={styles.grid_item}
                  placeholder={borrowerDataAfterApi.franchiseCode}
                  disabled
                />
              </div>

              <div>
                <div style={{ color: "#000" }}>Pan Card</div>
                <input
                  type="text"
                  className={styles.grid_item}
                  placeholder={borrowerDataAfterApi.pancard}
                  disabled
                />
              </div>
            </div>
            <div>
              <div
                style={{ backgroundColor: "#000", width: "100%", height: 1 }}
              ></div>
              <div
                style={{
                  color: "#133b19",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                Total Disbursement
              </div>
              <div
                style={{ backgroundColor: "#000", width: "100%", height: 1 }}
              ></div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    color: "#000",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  Rate of Intrest
                </div>
                <div
                  style={{
                    color: "#000",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  {data.interestRate}%
                </div>
              </div>

              <div
                style={{ backgroundColor: "#000", width: "100%", height: 1 }}
              ></div>
              <div
                style={{
                  color: "#000",
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                EMI Table
              </div>
              <div
                style={{ backgroundColor: "#000", width: "100%", height: 1 }}
              ></div>
              <div className={styles.table_header}>
                <div className={styles.header_item}>S.No.</div>
                <div className={styles.header_item}>Months</div>
                <div className={styles.header_item}>Principle</div>
                <div className={styles.header_item}>EMI</div>
                <div className={styles.header_item}>Total</div>
                <div className={styles.header_item}>Action</div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>1</div>
                <div className={styles.table_cell}>6 Months</div>
                <div className={styles.table_cell}>₹{amount}</div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months6}
                </div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months6 + amount}
                </div>
                <div className={styles.table_cell}>
                  <label>
                    <input
                      type="radio"
                      name="loanSelection"
                      value="Months6"
                      checked={selectedLoan === "Months6"}
                      onChange={handleLoanSelect}
                    />
                  </label>
                </div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>2</div>
                <div className={styles.table_cell}>9 Months</div>
                <div className={styles.table_cell}>₹{amount}</div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months9}
                </div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months9 + amount}
                </div>
                <div className={styles.table_cell}>
                  <label>
                    <input
                      type="radio"
                      name="loanSelection"
                      value="Months9"
                      checked={selectedLoan === "Months9"}
                      onChange={handleLoanSelect}
                    />
                  </label>
                </div>
              </div>
              <div className={styles.table_row}>
                <div className={styles.table_cell}>3</div>
                <div className={styles.table_cell}>12 Months</div>
                <div className={styles.table_cell}>₹{amount}</div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months12}
                </div>
                <div className={styles.table_cell}>
                  ₹{borrowerDataAfterApi?.emiDetails?.Months12 + amount}
                </div>
                <div className={styles.table_cell}>
                  <label>
                    <input
                      type="radio"
                      name="loanSelection"
                      value="Months12"
                      checked={selectedLoan === "Months12"}
                      onChange={handleLoanSelect}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className={styles.table_header1}>
              <button
                onClick={handleSubmit}
                style={{
                  width: "100%",
                  display: "flex",
                  backgroundColor: "green",
                  color: "#fff",
                  padding: 10,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Next &gt;
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const BankDetails = () => {
    const generatedInvnos = new Set();
    function generateUniqueInvno() {
      let newInvno;
      do {
        newInvno =
          "IN" +
          Math.floor(Math.random() * 1e16)
            .toString()
            .padStart(16, "0");
      } while (generatedInvnos.has(newInvno));
      generatedInvnos.add(newInvno);
      return newInvno;
    }

    async function handleMakePayment() {
      if (
        aadharCard !== borrowerDataAfterApi.aadharCard ||
        panCard !== borrowerDataAfterApi.panCard
      ) {
        alert("Aadhar and PAN card details do not match.");
        const paymentDetails = {
          invoiceNumber: generateUniqueInvno(),
          merchantId: 261,
          customerName: borrowerDataAfterApi.borrowerName,
          phoneNumber: borrowerDataAfterApi.borrowerPhone,
          payoutMode: "IMPS",
          payoutAmount: amount,
          accountNo: accNo,
          ifscBankCode: ifscCode,
          secretKey: "ee0585f4-6c83-4227-abdc-d019cb368302",
          apiKey: "be27c3fe-cfb5-45b0-a3ca-395ab333abb2",
          ipAddress: "45.119.30.161",
          payeeVpa: "",
        };

        try {
          const response = await fetch("", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(paymentDetails),
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Payment successful:", data);
            setCustomerId(data.customerId);
            setIsPopUpOpen(true);
          } else {
            setIsPopUpOpen(true);
            console.error("Payment failed:", response.statusText);
          }
        } catch (error) {
          console.error("Error making payment request:", error);
        }
      } else {
        console.error("Error making payment request");
      }
    }

    return (
      <div className={styles.table_container}>
        <div className={styles.modal_header}>
          <h2>Bank Details</h2>
        </div>
        <div className={styles.grid_container_bank}>
          <div>
            <div style={{ color: "#000", alignContent: "flex-start" }}>
              Account Number
            </div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Account Number"
                value={accNo}
                onChange={(e) => setAccNo(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Account Number"
                disabled
              />
            )}
          </div>

          <div>
            <div style={{ color: "#000" }}>IFSC Code</div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="IFSC Code"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="IFSC Code"
                disabled
              />
            )}
          </div>

          <div>
            <div style={{ color: "#000" }}>Borrower Name</div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Borrower Name"
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Borrower Name"
                disabled
              />
            )}
          </div>

          <div>
            <div style={{ color: "#000" }}>Aadhar Card</div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                value={aadharCard}
                onChange={(e) => setAadharCard(e.target.value)}
                placeholder="Aadhar Card"
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Aadhar Card"
                disabled
              />
            )}
          </div>

          <div>
            <div style={{ color: "#000" }}>Pan Card</div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="PAN Card"
                value={panCard}
                onChange={(e) => setPanCard(e.target.value)}
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="PAN Card"
                disabled
              />
            )}
          </div>

          <div>
            <div style={{ color: "#000" }}>Sanction Amount</div>
            {enableBankDetails ? (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Sanction Amount"
              />
            ) : (
              <input
                type="text"
                className={styles.grid_item}
                placeholder="Sanction Amount"
                disabled
              />
            )}
          </div>
        </div>
        <div className={styles.table_header1}>
          {enableBankDetails ? (
            <button
              onClick={handleMakePayment}
              style={{
                display: "flex",
                backgroundColor: "green",
                color: "#fff",
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                width: "200px",
                margin: "0 auto",
              }}
            >
              Make payment &gt;
            </button>
          ) : (
            <button
              style={{
                display: "flex",
                backgroundColor: "grey",
                color: "#fff",
                padding: 10,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                width: "200px",
                margin: "0 auto",
              }}
              onClick={handleMakePayment}
            >
              Make payment &gt;
            </button>
          )}
        </div>
        {isPopUpOpen && (
          <CustomModal
            isOpenCustModal={isPopUpOpen}
            onClose={() => setIsPopUpOpen(false)}
          >
            <h2>Payment Successful!</h2>
            <p>Customer ID: {customerId}</p>
          </CustomModal>
        )}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Header name="Borrower-Payment-Details" />
      <div className={styles.table_container1}>
        {borrowerDataAfterApi && (
          <BorrowerDataDisplayTable data={borrowerDataAfterApi} />
        )}
        <BankDetails />
      </div>
    </div>
  );
}
