import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentReviewState } from "../../utils/types";
import {
  getDocumentApi,
  getReceiptApi,
  postDocumentApi,
} from "../../utils/apis";

const initialState: DocumentReviewState = {
  data: null,
  checkList: null,
  loading: false,
  error: null,
  status: 0,
};

// Create async thunk for login
export const getDocument = createAsyncThunk(
  "getDocument",
  async (body: any) => {
    const response = await getDocumentApi(body);
    return response;
  }
);

export const postDocument = createAsyncThunk(
  "postDocument",
  async (body: any) => {
    body = body.filteredData;
    const response = await postDocumentApi(body);
    return response;
  }
);

export const getReceipt = createAsyncThunk("getReceipt", async () => {
  const response = await getReceiptApi();
  return response;
});

const documentReviewStatusSlice = createSlice({
  name: "documentReviewStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDocument.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getDocument.fulfilled, (state, action) => {
        state.status = 2;
        state.loading = false;
        state.checkList = action.payload; // Store user data
      })
      .addCase(getDocument.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Documents failed";
      });

    builder
      .addCase(postDocument.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(postDocument.fulfilled, (state, action) => {
        state.status = 3;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(postDocument.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Documents failed";
      });

    builder
      .addCase(getReceipt.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getReceipt.fulfilled, (state, action) => {
        state.status = 3;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(getReceipt.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "getReceipt failed";
      });
  },
});
export default documentReviewStatusSlice.reducer;
