import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocumentReviewStatus, getReceiptApi } from "../../utils/apis";
import { CommonSliceState } from "../../utils/types";

const initialState: CommonSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

export const handleGetDocReviewStatusDetails = createAsyncThunk(
  "getDocReviewStatus",
  async (data: any) => {
    const response = await getDocumentReviewStatus(data);
    return response;
  }
);

export const getReceipt = createAsyncThunk("getReceipt", async () => {
  const response = await getReceiptApi();
  return response;
});

const docReviewStatusSlice = createSlice({
  name: "getDocumentReviewStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleGetDocReviewStatusDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(handleGetDocReviewStatusDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(handleGetDocReviewStatusDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "documentReviewStatus failed";
        console.log("rejected");
      });

    builder
      .addCase(getReceipt.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(getReceipt.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getReceipt.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "getReceipt failed";
        console.log("rejected");
      });
  },
});

export default docReviewStatusSlice.reducer;
