import "../styles/loanstyles/PaymentLoader.css";

const PaymentLoader = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="loader2">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
      </div>
      <div className="mt-4 text-gray-600 font-medium text-sm">
        Processing your payment...
      </div>
    </div>
  );
};

export default PaymentLoader;
