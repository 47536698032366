import Container from "../../components/ui/Container";

function PrivacyPolicy() {
  return (
    <section className="my-8 inner-content">
      <Container>
        <h4 className="text-3xl mb-6 text-left">
          <span className="text-slate-900 font-bold">Privacy Policy</span>
          {/* <span className="font-bold">Policy</span> */}
        </h4>
        <div className="space-y-2">
          <p className="mt-4 text-gray-600 text-base">
            We insist on the highest standards for secure transactions and
            customer information privacy since we value the trust you place in
            us.
          </p>
          <p className="text-gray-600 text-base">
            Our privacy policies are subject to change at any time and without
            notice. Please examine this policy on a regular basis to ensure you
            are informed of any changes.
          </p>
          <p className="text-gray-600 text-base">
            You agree to be bound by the terms and conditions of this Privacy
            Policy by visiting this website. Please do not use or access our
            Website if you do not agree.
          </p>
          <p className="text-gray-600 text-base">
            You expressly consent to our use and dissemination of your personal
            information in accordance with this Privacy Policy by simply using
            the Website. This Privacy Policy is part of and subject to the Terms
            of Service.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Collection of Information</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            We gather and retain your personal information that you supply to us
            from time to time when you visit our Website. We strive to give you
            with a safe, efficient, smooth, and personalized experience. This
            enables us to provide services and features that are likely to
            fulfill your requirements, as well as to tailor our Website to make
            your experience safer and easier. More crucially, we collect
            personal information from you that we believe is required for this
            purpose.
          </p>
          <p className="text-gray-600 text-base">
            On specific sections of the Website, we employ data-gathering
            devices such as “cookies” to help analyze web page flow, monitor
            promotional effectiveness, and encourage trust and safety. “Cookies”
            are little identifiers delivered from a web server and saved on your
            computer's hard drive that allow us to recognize you when you return
            to our website.
          </p>
          <p className="text-gray-600 text-base">
            Furthermore, you may encounter “cookies” or other similar devices
            installed by third parties on certain pages of the Website. We have
            no control over third-party cookie use.
          </p>
          <p className="text-gray-600 text-base">
            If you choose to make a purchase on the Website, we will gather
            information about your purchasing habits.
          </p>
          <p className="text-gray-600 text-base">
            In the event that you transact with us, we may also gather
            additional information, such as your billing address, credit card
            number, expiration date, and/or other payment information from
            checks and money orders.
          </p>
          <p className="text-gray-600 text-base">
            We will gather the information you give us if you decide to offer
            feedback or post messages in our chat rooms, message boards, or
            other communication areas. As allowed by law, we keep your
            information as long as it's required to resolve disputes, offer
            customer assistance, and troubleshoot issues.
          </p>
          <p className="text-gray-600 text-base">
            We may compile information about you into a file if you send us
            personal correspondence, such as emails or letters, or if other
            users or third parties send us contact regarding your actions or
            postings on the Website.
          </p>
          <p className="text-gray-600 text-base">
            When you create a free account with us, we ask for personally
            identifiable information (such as your name, email address, phone
            number, credit card or other payment instrument data, etc.).
          </p>
          <p className="text-gray-600 text-base">
            While you can view various areas of our website without registering,
            other activities (like placing an order) do demand registration.
            Your contact information is used by us to send you offers that are
            customized to your interests and past purchases.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">
          Use and Sharing of Information
        </h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            We will never share your personally identifiable information with a
            third party without your consent, unless otherwise specified in this
            Privacy Policy. To process your request, to comply with any law,
            regulation, audit, or court order, to improve our website and the
            products and services we offer, for research, to better understand
            our customers’ needs, to develop new offerings, and to notify you of
            new products and services (of us or our business associates), we may
            use or share the information we receive about you or from you.
            Additionally, we might combine the data you give us with the data we
            already have on you internally or from other sources in order to
            better serve you.
          </p>
          <p className="text-gray-600 text-base">
            We never divulge, trade, sell, or rent out your personal information
            to unidentified third parties.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Cookies</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            Cookies” are little identifiers that are sent from a web server and
            saved on the hard drive of your computer. They allow us to recognize
            you when you return to our website.
          </p>
          <p className="text-gray-600 text-base">
            We might occasionally store “cookies” on your computer.
            Additionally, cookies are used on our site to trace how you arrived
            there. We never use cookies to store or transfer any personal
            information about you online in order to preserve your privacy. You
            can decide whether to accept or reject cookies. Most web browsers
            allow cookies by default, however you can typically change your
            browser's settings so that you can refuse cookies if you prefer.
            Certain elements of the site might not work correctly or at all if
            you decide to reject cookies.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Links</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            Links to other websites can be found on our website. Information
            about your visit to this website may be used by such other websites.
            Our privacy policy does not apply to individuals we do not employ or
            to the privacy policies of websites we do not own or manage. Because
            of this, we disclaim all liability for the privacy policies,
            accuracy, and integrity of the content found on such third-party
            websites. We strongly advise reading each website’s specific privacy
            statement.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Security</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            By following accepted security standards and processes and adhering
            to relevant privacy regulations, we protect your privacy. Our
            websites integrate physically, electronically, and procedurally
            sound security measures that have been certified by the industry to
            keep your information safe during every stage of its existence in
            our infrastructure.
          </p>
          <p className="text-gray-600 text-base">
            When sensitive data is kept on our infrastructure, it is hashed or
            encrypted. When no longer required, sensitive material is quickly
            re-encrypted or deleted after being decrypted. With limited access
            to the servers that handle data, we host online services in data
            centers that have undergone audits. In such data centers, controlled
            access, recorded and live video feeds, 24/7 manned security, and
            biometrics are all offered to ensure that we provide secure hosting.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Consent</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            All of our users have the choice not to receive promotional or
            marketing-related communications that are not necessary.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">
          Changes to this Privacy Policy
        </h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            You consent to the collecting and use of the information you
            disclose on the Website in accordance with this Privacy Policy by
            using the Website and/or by supplying your information. This consent
            includes but is not limited to, your consent to the sharing of your
            information in accordance with this privacy policy.
          </p>
          <p className="text-gray-600 text-base">
            To ensure that you are constantly informed of the data we gather,
            how we use it, and when we release it, if we decide to make changes
            to our privacy policy, we will post those changes on this page.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">
          Changes to this Privacy Policy
        </h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            Without prior notice, our privacy practices are subject to change at
            any time. Our privacy policy may occasionally change. To ensure you
            are aware of any updates, please review our policy from time to
            time.
          </p>
        </div>
        <h3 className="mt-4 font-bold text-lg">Questions</h3>
        <div className="space-y-2">
          <p className="text-gray-600 text-base">
            If you have any queries about our Privacy Policy, please e-mail your
            questions to us.
          </p>
        </div>
      </Container>
    </section>
  );
}

export default PrivacyPolicy;
