const handleOccupationPayloadFormate = (
  empType: string,
  empSubType: string,
  data: any
) => {

  let payload = {};
  if (empType === "Self-Employed") {
    if (
      empSubType === "Doctor" ||
      empSubType === "CA" ||
      empSubType === "Lawyer"
    ) {
      const occupation = {
        type: empType,
        subType: empSubType,
        registeredNumber: data?.registeredNumber,
        specialisation: data?.specialisation,
        yearsInPractise: data?.yearsInPractise,
        previousYearITReturn: data?.previousYearITReturn,
        amountOfTaxPaid: Number(data?.amountOfTaxPaid),
        bankAccountNumber: data?.bankAccountNumber,
        bankIfsccode: data?.bankIfsccode,
        occupationAddress: data?.occupationAddress,
        offCity:data?.offCity,
        offPincode:data?.offPincode,
        offStateOrCentral:data?.offStateOrCentral,
      };
      payload = occupation;
    } else if (empSubType === "Freelancer") {
      const occupation = {
        type: empType,
        subType: empSubType,
        registeredNumber: data?.registeredNumber,
        natureOfWork: data?.natureOfWork,
        yearsOfExperience: data?.yearsOfExperience,
        other: data?.other,
      };
      payload = occupation;
    } else if (empSubType === "Business") {
      const occupation = {
        type: empType,
        subType: empSubType,
        gstNumber: data?.gstNumber,
        businessName: data?.businessName,
        occupationAddress: data?.occupationAddress,
        offCity:data?.offCity,
        offPincode:data?.offPincode,
        offStateOrCentral:data?.offStateOrCentral,
        typeOfBusiness: data?.typeOfBusiness,
        natureOfBusiness: data?.natureOfBusiness,
        amountOfTaxPaid: Number(data?.amountOfTaxPaid),
        previousYearITReturn: data?.previousYearITReturn,
      };
      payload = occupation;
    }
  } 
   if (empType === "Employed") {
    
    if (empSubType === "Govt. Employee" || empSubType === "PSU Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfOrganisation: data?.nameOfOrganisation,
        designation: data?.designation,
        department: data?.department,
        // stateOrCentral: data?.stateOrCentral,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity:data?.offCity,
        offPincode:data?.offPincode,
        offStateOrCentral:data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    } else if (empSubType === "Pvt. Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfCompany: data?.nameOfCompany,
        designation: data?.designation,
        department: data?.department,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity:data?.offCity,
        offPincode:data?.offPincode,
        offStateOrCentral:data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    } else if (empSubType === "Bank Employee") {
      const occupation = {
        type: empType,
        subType: empSubType,
        nameOfBank: data?.nameOfBank,
        designation: data?.designation,
        department: data?.department,
        // stateOrCentral: data?.stateOrCentral,
        yearsInCurrentDesignation: data?.yearsInCurrentDesignation,
        occupationAddress: data?.occupationAddress,
        offCity:data?.offCity,
        offPincode:data?.offPincode,
        offStateOrCentral:data?.offStateOrCentral,
        officePhoneNumber: data?.officePhoneNumber,
        annualIncome: Number(data?.annualIncome),
        lastMonthTakeHomeSalary: Number(data?.lastMonthTakeHomeSalary),
      };
      payload = occupation;
    }
  } 
  return payload;
};

export { handleOccupationPayloadFormate };
