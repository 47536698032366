// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgotPassUser,
  forgotPassUserReset,
  getAllBorrowerApi,
  loginUser,
  registerUser,
  resetPassUser,
} from "../utils/apis";
import { CommonSliceState } from "../utils/types";

const initialState: CommonSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

// Create async thunk for login
export const login = createAsyncThunk("login", async (body: any) => {
  const response = await loginUser(body);
  return response;
});

export const register = createAsyncThunk("register", async (body: any) => {
  const response = await registerUser(body);
  return response;
});

export const forgotPassword = createAsyncThunk("forgot", async (body: any) => {
  const response = await forgotPassUser(body);
  return response;
});

export const forgotPasswordReset = createAsyncThunk(
  "forgotReset",
  async (body: any) => {
    const response = await forgotPassUserReset(body);
    return response;
  }
);

export const resetPassword = createAsyncThunk("reset", async (body: any) => {
  const response = await resetPassUser(body);
  return response;
});

export const getAllBorrower = createAsyncThunk(
  "getAllBorrower",
  async (token: any) => {
    const response = await getAllBorrowerApi(token);
    return response;
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAdmin: (state) => {
      state.data = null; // Clear user data
      state.loading = false; // Reset loading
      state.error = null; // Clear error
      localStorage.removeItem("user");
    },
    handleLogout: (state) => {
      state.data = null;
      localStorage.removeItem("user");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Login failed";
      });

    builder
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Register failed";
      });

    builder
      .addCase(forgotPassword.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = 2;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Forgot Password failed";
      });

    builder
      .addCase(forgotPasswordReset.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordReset.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(forgotPasswordReset.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Forgot Password Reset failed";
      });

    builder
      .addCase(getAllBorrower.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllBorrower.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(getAllBorrower.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Documents failed";
      });
  },
});
export const { logoutAdmin } = authSlice.actions;
export default authSlice.reducer;
