import React from "react";
import Header from "../../../components/Header";

const EditBorrower = () => {
  return (
    <div className="min-h-screen flex flex-col">
      ,<Header />
      <div className="flex flex-1 mt-10">
        {/* Main Content */}
        <main className="flex-1 bg-gray-100 p-6">
          {/* Search Bar */}
          <div className="flex justify-between">
            <div className="flex items-center mb-6">
              <input
                type="text"
                placeholder="Enter Borrower Name..."
                className="w-full p-2 border border-gray-300 rounded-none"
              />
              <button className="bg-[#17a44f] text-white px-4 py-2">
                Search
              </button>
            </div>
            <button className="bg-[#17a44f] text-white px-4 py-2">
              Create Borrower
            </button>
          </div>
          {/* Table */}
          <div className="overflow-x-auto bg-white rounded shadow-md">
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-[#17a44f] text-white text-center">
                  <th className="px-4 py-2 border">Borrower Name</th>
                  <th className="px-4 py-2 border">Aadhar Card</th>
                  <th className="px-4 py-2 border">Pan Card</th>
                  <th className="px-4 py-2 border">Loan Amount</th>
                  <th className="px-4 py-2 border">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                <tr className="hover:bg-gray-100 text-center">
                  <td className="px-4 py-2 border">Sunder Sharma</td>
                  <td className="px-4 py-2 border">XXXXXX123567</td>
                  <td className="px-4 py-2 border">XXXXXA12</td>
                  <td className="px-4 py-2 border">₹1,00,000/-</td>
                  <td className="px-4 py-2 border flex gap-2 justify-center">
                    <button className="bg-blue-500 text-white px-4 py-2 rounded shadow">
                      View
                    </button>
                    <button className="bg-yellow-500 text-white px-4 py-2 rounded shadow">
                      Update
                    </button>
                    <button className="bg-red-500 text-white px-4 py-2 rounded shadow">
                      Delete
                    </button>
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="flex justify-between items-center mt-4">
            <span>Showing 1 to 10 of 200 entries</span>
            <div className="flex items-center gap-2">
              <button className="px-3 py-1 border rounded">First</button>
              <button className="px-3 py-1 border rounded">Previous</button>
              <button className="px-3 py-1 border rounded">1</button>
              <button className="px-3 py-1 border rounded">2</button>
              <button className="px-3 py-1 border rounded">3</button>
              <button className="px-3 py-1 border rounded">Next</button>
              <button className="px-3 py-1 border rounded">Last</button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditBorrower;
