// import "../../App.css";

const CreateButton = ({ createPopup }: any) => {
  return (
    <button
      className="create-button bg-[#3c8f47] text-white py-2 px-4 rounded-md hover:bg-[#218838] focus:outline-none"
      onClick={createPopup}
    >
      Create
    </button>
  );
};

export default CreateButton;
