// authSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DocumentState } from "../../utils/types";
import {
  getAllDisbursementBorrowerDetailsApi,
  getDisbursementApi,
  getDisbursementBorrowerDetailsApi,
} from "../../utils/apis";

const initialState: DocumentState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
  borrowerdata: null,
};

// Create async thunk for login
export const getDisbursmentData = createAsyncThunk(
  "getDisbursement",
  async (body: any) => {
    const response = await getDisbursementApi(body);
    return response;
  }
);

export const getDisburseBorrowDetails = createAsyncThunk(
  "getDisburseBorrowDetails",
  async (body: any) => {
    console.log(body);
    const response = await getDisbursementBorrowerDetailsApi(body);
    return response;
  }
);

export const getAllDisburseBorrowDetails = createAsyncThunk(
  "getAllDisburseBorrowDetails",
  async (body: any) => {
    const response = await getAllDisbursementBorrowerDetailsApi(body);
    return response;
  }
);

const disbursementSlice = createSlice({
  name: "disbursementStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDisbursmentData.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getDisbursmentData.fulfilled, (state, action) => {
        state.status = 2;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(getDisbursmentData.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Disbursement failed";
      });

    builder
      .addCase(getDisburseBorrowDetails.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getDisburseBorrowDetails.fulfilled, (state, action) => {
        state.status = 1;
        state.loading = false;
        state.borrowerdata = action.payload; // Store user data
      })
      .addCase(getDisburseBorrowDetails.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Disbursement failed";
      });

    builder
      .addCase(getAllDisburseBorrowDetails.pending, (state) => {
        state.status = 0;
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllDisburseBorrowDetails.fulfilled, (state, action) => {
        state.status = 3;
        state.loading = false;
        state.data = action.payload; // Store user data
      })
      .addCase(getAllDisburseBorrowDetails.rejected, (state, action) => {
        state.status = 0;
        state.loading = false;
        state.error = action.error.message || "Disbursement failed";
      });
  },
});
export default disbursementSlice.reducer;
