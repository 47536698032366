import { useState, useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link, useLocation } from "react-router-dom";
import Container from "./Container";
import GradualScrollToTop from "../GradualScrollToTop";
import CustomModal from "../CustomModal";
import PersonalLoanEMICalculator from "../LoanEMICalculator/PersonalLoanEMICalculator";

const FooterAccordionItem = ({ title, isOpen, onClick, children }: any) => (
  <div className="footer_accordion-item">
    <div className="footer_accordion-header">
      <div
        className={`footer_accordion-title relative${
          isOpen ? "!text-white pb-2" : "!text-white"
        } transition-all ease-in-out duration-500 cursor-pointer `}
        onClick={onClick}
      >
        <span
          className={
            isOpen
              ? "border-b border-green-600 transition-colors duration-500 text-green-600"
              : "text-slate-200"
          }
        >
          {title}
        </span>
        <div className="absolute top-1 left-auto right-0">
          <IoIosArrowDown
            className={`${
              isOpen
                ? "transform rotate-180 text-white transition-all ease-in-out duration-500 "
                : "text-white transition-all ease-in-out duration-500"
            } ml-5 w-4 h-4`}
          />
        </div>
      </div>
    </div>
    <div
      className={`footer_accordion-content transition-all ease-in-out duration-500 ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div>{children}</div>
    </div>
  </div>
);

const FooterAccordion = () => {
  const [openPanel, setOpenPanel] = useState<number | null>(3);
  const location = useLocation(); // Track location changes

  const handlePanelClick = (index: number) => {
    setOpenPanel(index === openPanel ? null : index);
  };

  // Scroll to top on location change
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]); // Trigger scroll on location change

  const [isCustModalOpenPersonalLoanCalc, setIsCustModalPersonalLoanCalc] =
    useState(false);

  const openCustModalPersonalLoanCalc = () => {
    setIsCustModalPersonalLoanCalc(true);
  };

  const closeCustModalPersonalLoanCalc = () => {
    setIsCustModalPersonalLoanCalc(false);
  };

  return (
    <>
      <GradualScrollToTop />
      <section className="w-full my-5">
        <Container>
          <div className="footer_accordion md:h-[auto] overflow-y-auto footer_accordion_scroll px-0 md:px-8">
            <FooterAccordionItem
              title="Loans"
              isOpen={openPanel === 0}
              onClick={() => handlePanelClick(0)}
            >
              <div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
                <ul className="footer_link">
                  <li>
                    <Link to="gold-loan">Gold Loan</Link>
                  </li>
                  <li>
                    <Link to="personal-loan">Personal Loan</Link>
                  </li>
                  <li>
                    <Link to="home-loan">Housing Loan</Link>
                  </li>
                  <li>
                    <Link to="vehicle-loan">Vehicle Loan</Link>
                  </li>
                  <li>
                    <Link to="business-loan">Business Loan</Link>
                  </li>
                  <li>
                    <Link to="property-loan">Property Loan</Link>
                  </li>
                  <li>
                    <Link to="arts-antique-loan">Arts & Antiques Loan</Link>
                  </li>
                  <li>
                    <Link to="shares-security-loan">
                      Shares & Security Loan
                    </Link>
                  </li>
                  <li>
                    <Link to="sme-loan">SME Loan</Link>
                  </li>
                  <li>
                    <Link to="holiday-loan">Holiday Loan</Link>
                  </li>
                  <li>
                    <Link to="wedding-loan">Wedding Loan</Link>
                  </li>
                  <li>
                    <Link to="ca-loan">CA Loan</Link>
                  </li>
                  <li>
                    <Link to="doctor-loan">Doctor Loan</Link>
                  </li>
                </ul>

                <ul className="footer_link">
                  <h5 className="uppercase">Application Form</h5>
                  <li>
                    <Link to="/register">personal loan Form</Link>
                  </li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">CALCULATOR</h5>
                  <li>
                    {/* <Link to="/">personal loan calculator</Link> */}
                    <span
                      onClick={openCustModalPersonalLoanCalc}
                      className="cursor-pointer text-white hover:text-green-600"
                    >
                      Personal Loan Calculator
                    </span>
                  </li>
                </ul>
              </div>
            </FooterAccordionItem>

            <FooterAccordionItem
              title="Business"
              isOpen={openPanel === 1}
              onClick={() => handlePanelClick(1)}
            >
              <div className="grid xl:grid-cols-5 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
                <ul className="footer_link">
                  <h5 className="uppercase">About Us</h5>
                  <li>
                    <Link to="/">Mission</Link>
                  </li>
                  <li>
                    <Link to="/">Vision</Link>
                  </li>
                  <li>
                    <Link to="/">Team</Link>
                  </li>
                  <li>
                    <Link to="/">Infra</Link>
                  </li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">FRANCHISE</h5>
                  <li>
                    <Link to="/">Idea</Link>
                  </li>
                  <li>
                    <Link to="/">Promise</Link>
                  </li>
                  <li>
                    <Link to="/">Support</Link>
                  </li>
                  <li>
                    <Link to="/">Solution</Link>
                  </li>
                  <li>
                    <Link to="/">Process</Link>
                  </li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">Investor</h5>
                  <li>
                    <Link to="/">Process</Link>
                  </li>
                  <li>
                    <Link to="/">Infra</Link>
                  </li>
                  <li>
                    <Link to="/">Legal</Link>
                  </li>
                  <li>
                    <Link to="/">Support</Link>
                  </li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">technology</h5>
                  <li>
                    <Link to="/">The Software</Link>
                  </li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">commercial</h5>
                  <li>
                    <Link to="/"></Link>
                  </li>
                </ul>
              </div>
            </FooterAccordionItem>

            <FooterAccordionItem
              title="Legal"
              isOpen={openPanel === 2}
              onClick={() => handlePanelClick(2)}
            >
              <div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
                <ul className="footer_link">
                  <h5 className="uppercase">legal</h5>
                  <li>CIN NO - L65990MH1984PLC033818</li>
                  <li>GST NO. - 27AAACG1540L1ZQ</li>
                  <li>RBI COR - 13.01171</li>
                  <li>LEI - 984500B851F5BNA97906</li>
                </ul>
                <ul className="footer_link">
                  <h5 className="uppercase">policies</h5>
                  <li>
                    <Link to="/terms">terms & conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/refund">Refund & Cancellations</Link>
                  </li>
                </ul>
              </div>
            </FooterAccordionItem>

            <FooterAccordionItem
              title="Reach US"
              isOpen={openPanel === 3}
              onClick={() => handlePanelClick(3)}
            >
              <div className="grid xl:grid-cols-4 lg:grid-cols-1 xl:gap-4 lg:gap-0 gap-0">
                <ul className="footer_link">
                  <h5 className="uppercase">Address</h5>
                  <li>
                    202, Ramakrishna Chambers, <br />
                    Khar Linking Road, Khar(West), <br />
                    Mumbai, 400 052, <br />
                    Maharashtra, india.
                  </li>
                  <li>TEL: +91 9985544266</li>
                  <li>
                    EMAIL:{" "}
                    <Link
                      to="mailto:ashapurtiloans@gmail.com"
                      className="lowercase"
                    >
                      ashapurtiloans@gmail.com
                    </Link>
                  </li>
                </ul>

                <ul className="footer_link">
                  <h5 className="uppercase">Company</h5>
                  <li>
                    <Link to="/contactUs">Contact Us</Link>
                  </li>
                </ul>

                <div className="footer_link col-span-full md:col-span-2">
                  <div>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d120664.0213527225!2d72.834207!3d19.074697!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c90eaecd861d%3A0x15caf06651358033!2sAsha%20Purti%20Loans!5e0!3m2!1sen!2sin!4v1700036086255!5m2!1sen!2sin"
                      width="100%"
                      height="250"
                      title="iframe"
                      style={{ border: 0 }}
                      loading="lazy"
                      className="shadow-lg rounded-3xl"
                    ></iframe>
                  </div>
                </div>
              </div>
            </FooterAccordionItem>
          </div>
          <div className="personalLoanCalc">
            <CustomModal
              isOpenCustModal={isCustModalOpenPersonalLoanCalc}
              onClose={closeCustModalPersonalLoanCalc}
            >
              <PersonalLoanEMICalculator />
            </CustomModal>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FooterAccordion;
