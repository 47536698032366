import React, { useState, useEffect, useRef } from "react";
import "../../styles/usersStyles/LoanEmiCalculator.css";

interface LoanState {
  loanAmount: number;
  interestRate: number;
  tenureInDays: number; // Tenure in days (calculated based on weeks or days)
  isWeekly: boolean; // Flag to determine if tenure is in weeks
  enabledTenure: number[]; // Array of enabled tenures based on loan amount
}

const PersonalLoanEMICalculator: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [state, setState] = useState<LoanState>({
    loanAmount: 10000,
    interestRate: 30,
    tenureInDays: 100, // Default tenure in days
    isWeekly: false, // Default is daily calculation
    enabledTenure: [100], // Default enabled tenures
  });

  const loanEMIValueRef = useRef<HTMLParagraphElement>(null);
  const totalAmountValueRef = useRef<HTMLParagraphElement>(null);
  const totalInterestValueRef = useRef<HTMLParagraphElement>(null);

  // Function to calculate EMI
  const calculateEMI = (): number => {
    const { loanAmount, interestRate, tenureInDays, isWeekly } = state;

    // Calculate the per annum interest
    const interestPerAnnum = (loanAmount * interestRate) / 100;

    // Calculate per day interest
    const perDayInterest = interestPerAnnum / 365;

    // Calculate per day principal
    const perDayPrincipal = loanAmount / tenureInDays;

    // EMI is the sum of per day interest and per day principal
    let emi = perDayInterest + perDayPrincipal;

    // If tenure is weekly, multiply the daily EMI by 7
    if (isWeekly) {
      emi *= 7;
    }

    return emi || 0; // Return 0 if calculation fails
  };

  // Update the data and display results
  const updateData = (emi: number) => {
    const { loanAmount, tenureInDays, isWeekly } = state;

    // Adjust the tenure for weekly calculations
    const convertedWeeklyTenure = isWeekly ? tenureInDays / 7 : tenureInDays;

    // Calculate the total payment and total interest
    const totalInterest = emi * convertedWeeklyTenure - loanAmount; // Total interest = EMI * adjusted tenure - loan amount
    const totalPayment = emi * convertedWeeklyTenure; // Total payment = EMI * adjusted tenure

    // Update the values in the DOM
    if (loanEMIValueRef.current) {
      loanEMIValueRef.current.innerText = `₹ ${emi.toFixed(2)}`; // Format to two decimal places
    }
    if (totalAmountValueRef.current) {
      totalAmountValueRef.current.innerText = `₹ ${totalPayment.toFixed(2)}`;
    }
    if (totalInterestValueRef.current) {
      totalInterestValueRef.current.innerText = `₹ ${totalInterest.toFixed(2)}`;
    }
  };

  const updateRateAndTenure = (amount: number) => {
    // debugger;
    let interestRate = 30;
    let tenureInDays = 100;
    let isWeekly = false;
    let enabledTenure = [100]; // Default enabled tenures

    if (amount >= 25001 && amount <= 75000) {
      interestRate = 27;
      tenureInDays = 200;
      enabledTenure = [200, 300]; // Enable 200 and 300 days tenure
    } else if (amount >= 75001 && amount <= 150000) {
      interestRate = 27;
      tenureInDays = 365; // 52 weeks
      isWeekly = true;
      enabledTenure = [52, 104]; // Enable 52 and 104 weeks tenure
    } else if (amount >= 150001 && amount <= 250000) {
      interestRate = 24;
      tenureInDays = 730; // 104 weeks
      isWeekly = true;
      enabledTenure = [104]; // Only enable 104 weeks tenure
    }

    setState((prevState) => ({
      ...prevState,
      loanAmount: amount,
      interestRate,
      tenureInDays,
      isWeekly,
      enabledTenure, // Update enabled tenures
    }));

    setErrorMessage(""); // Clear error message when valid amount is entered
    return enabledTenure; // Return the enabled tenures
  };

  // Handle Loan Amount Change
  const handleLoanAmountChange = (value: number) => {
    if (value < 10000) {
      setErrorMessage("Loan Amount must be at least ₹10,000");
      setState((prevState) => ({
        ...prevState,
        loanAmount: value,
      }));
    } else {
      const enabledTenure = updateRateAndTenure(
        Math.min(Math.max(value, 10000), 250000)
      );
      // Update tenure options availability based on the enabledTenure
      setState((prevState) => ({
        ...prevState,
        enabledTenure, // Set enabled tenures to the state
      }));
    }
  };

  // Function to handle Tenure Change
  const handleTenureChange = (value: number, type: "days" | "weeks") => {
    let tenureInDays = value;
    let isWeekly = false;

    // Determine if the tenure is in weeks and adjust accordingly
    if (type === "weeks") {
      isWeekly = true;
      tenureInDays = value === 52 ? 365 : 730; // Convert weeks to days
    }

    // Logic for setting the interest rate based on tenure
    setState((prevState) => {
      let updatedInterestRate = prevState.interestRate;

      // Always set 30% for 100 days
      if (tenureInDays === 100) {
        updatedInterestRate = 30;
      } else if (value === 300 || value === 104) {
        updatedInterestRate = 24; // Interest rate for 300 days or 104 weeks
      } else if (value === 200 || value === 52) {
        updatedInterestRate = 27; // Interest rate for these tenures
      }

      return {
        ...prevState,
        tenureInDays,
        isWeekly,
        interestRate: updatedInterestRate,
      };
    });
  };

  const handleInterestRateChange = (rate: number) => {
    setState((prevState) => ({
      ...prevState,
      interestRate: rate,
    }));
  };

  // Calculate EMI and update data when state changes
  useEffect(() => {
    const emi = calculateEMI(); // Calculate EMI based on the current state
    updateData(emi); // Update the results with the calculated EMI
  }, [state]); // Recalculate whenever the state changes

  return (
    <div className="loan-calculator-container w-full space-y-2">
      <div className="bg-gray-100 rounded-xl">
        <div className="bg-green-900 rounded-tl-[10px] rounded-tr-[10px] h-10 flex items-center justify-center mb-4 shadow-md">
          <h2 className="text-center text-lg font-semibold text-yellow-300 ">
            Loan EMI Calculator
          </h2>
        </div>

        <div className="calculator-form px-6 space-y-5">
          {/* Loan Amount */}
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:justify-between">
            <label className="text-green-900 w-full lg:w-[30%] font-semibold text-sm">
              Loan Amount (₹) :
            </label>
            <input
              type="number"
              value={state.loanAmount}
              onChange={(e) =>
                handleLoanAmountChange(parseFloat(e.target.value))
              }
              className="rounded-md w-full lg:w-[70%] border-yellow-700 "
              placeholder="Enter minimum ₹10,000 or above"
            />
          </div>
          <div className="text-sm font-medium text-right relative !mt-0">
            {errorMessage && (
              <p className="text-red-500 mt-2 text-xs absolute right-0 -top-[6px] font-medium">
                {errorMessage}
              </p>
            )}
          </div>

          {/* Tenure */}
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:justify-between">
            <label className="text-green-900 w-full lg:w-[30%] font-semibold text-sm">
              Tenure :
            </label>
            <div className="flex gap-2 w-full lg:w-[70%]">
              {/* {[100, 200, 300].map((days) => (
                <button
                  key={days}
                  className={`rounded border border-green-800 text-sm px-1 py-1 ${
                    state.tenureInDays === days && !state.isWeekly
                      ? "bg-yellow-600 text-white border-yellow-900 shadow-shadow_4"
                      : "bg-gray-50 text-black"
                  }`}
                  onClick={() => handleTenureChange(days, "days")}
                  disabled={
                    state.loanAmount >= 10000 &&
                    state.loanAmount <= 25000 &&
                    days !== 100
                  }
                >
                  {days} Days
                </button>
              ))} */}

              {[100, 200, 300].map((days) =>
                state.enabledTenure.includes(days) ? (
                  <button
                    key={days}
                    className={`rounded border border-green-800 text-sm px-1 py-1 ${
                      state.tenureInDays === days && !state.isWeekly
                        ? "bg-yellow-600 text-white border-yellow-900 shadow-shadow_4"
                        : "bg-gray-50 text-black"
                    }`}
                    onClick={() => handleTenureChange(days, "days")}
                  >
                    {days} Days
                  </button>
                ) : (
                  <button
                    key={days}
                    className="rounded border border-gray-400 text-sm px-1 py-1 bg-gray-200 text-gray-400 cursor-not-allowed"
                    disabled
                  >
                    {days} Days
                  </button>
                )
              )}

              {/* {[52, 104].map((weeks) =>
                state.enabledTenure.includes(weeks) ? (
                  <button
                    key={weeks}
                    className={`rounded border border-green-800 text-sm px-1 py-1 ${
                      (state.isWeekly &&
                        state.tenureInDays === (weeks * 365) / 52) ||
                      (!state.isWeekly &&
                        state.tenureInDays === (weeks * 365) / 52)
                        ? "bg-yellow-600 text-white border-yellow-900 shadow-shadow_4"
                        : "bg-gray-50 text-black"
                    }`}
                    onClick={() => handleTenureChange(weeks, "weeks")}
                  >
                    {weeks} Weeks
                  </button>
                ) : (
                  <button
                    key={weeks}
                    className="rounded border border-green-800 text-sm px-1 py-1 bg-gray-200 text-gray-400 cursor-not-allowed"
                    disabled
                  >
                    {weeks} Weeks
                  </button>
                )
              )} */}

              {[52, 104].map((weeks) => {
                const isActive = state.tenureInDays === (weeks * 365) / 52;
                return state.enabledTenure.includes(weeks) ? (
                  <button
                    key={weeks}
                    className={`rounded border border-green-800 text-sm px-1 py-1 ${
                      isActive
                        ? "bg-yellow-600 text-white border-yellow-900 shadow-shadow_4"
                        : "bg-gray-50 text-black"
                    }`}
                    onClick={() => handleTenureChange(weeks, "weeks")}
                  >
                    {weeks} Weeks
                  </button>
                ) : (
                  <button
                    key={weeks}
                    className="rounded border border-gray-400 text-sm px-1 py-1 bg-gray-200 text-gray-400 cursor-not-allowed"
                    disabled
                  >
                    {weeks} Weeks
                  </button>
                );
              })}
            </div>
          </div>

          {/* Interest Rate */}
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:justify-between">
            <label className="text-green-900 w-full lg:w-[30%] font-semibold text-sm">
              Interest Rate (%) :
            </label>
            <div className="flex gap-2 !mt-0 w-full lg:w-[70%]">
              {[30, 27, 24].map((rate) => (
                <div
                  key={rate}
                  className={`rounded border border-green-800 text-sm px-3 py-1 ${
                    state.interestRate === rate
                      ? "bg-yellow-600 text-white border-yellow-900 shadow-shadow_4"
                      : "bg-gray-200 text-gray-400 cursor-not-allowed !border-gray-400"
                  }`}
                  // onClick={() => handleInterestRateChange(rate)}
                >
                  {rate}%
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Results */}
        <div className="results-container mt-4">
          <div className=" bg-green-900 rounded-bl-xl rounded-br-xl ">
            <div className="text-center flex items-center justify-between gap-4 px-10 py-4 ">
              <p className="flex flex-col text-yellow-300 font-semibold text-sm lg:text-lg tracking-widest">
                EMI
                <span
                  ref={loanEMIValueRef}
                  className="underline text-sm lg:text-lg text-white font-medium underline-offset-4"
                >
                  ₹0
                </span>
              </p>
              <p className="flex flex-col text-yellow-300 font-semibold text-sm lg:text-lg racking-widest">
                Total Interest Payable
                <span
                  ref={totalInterestValueRef}
                  className="underline text-sm lg:text-lg text-white font-medium underline-offset-4"
                >
                  ₹0
                </span>
              </p>
              <p className="flex flex-col text-yellow-300 font-semibold text-sm lg:text-lg racking-widest">
                Total Payment
                <span
                  ref={totalAmountValueRef}
                  className="underline text-sm lg:text-lg text-white font-medium underline-offset-4"
                >
                  ₹0
                </span>
              </p>
            </div>
            <p className="text-center text-white text-xs px-6 pb-4">
              <span className="text-yellow-400 tracking-widest font-semibold">
                Note:{" "}
              </span>
              For daily payments calculations are for working days weekly and
              government holidays excluded.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalLoanEMICalculator;
