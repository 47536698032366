import { Link, useLocation, useNavigate } from "react-router-dom";
import companyLogo from "../../assets/logo2.png";
import "../../styles/loanstyles/payEmi.css";
import { useEffect, useState } from "react";
import CustomRadioButton from "../../components/CustomRadioButton";
import PaymentLoader from "../../components/PaymentLoader";
import { get, push, ref, set, update } from "firebase/database";
import { db, txnRef } from "../../utils/firebase";
import VisaCard from "../../assets/PayEmi/visa-card.png";
import MasterCard from "../../assets/PayEmi/mastercard.png";
import Rupay from "../../assets/PayEmi/rupay-card.png";
import Phonepe from "../../assets/PayEmi/phonepay.png";
import {
  getIOPIntent,
  getPaymentStatus,
  getPhonepayPaymentStatus,
} from "../../utils/apis";
import axios from "axios";

function PayEmi() {
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedCardMethod, setSelectedCardMethod] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showChessPayment, setShowChessPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [qrIsVisible, setQrIsVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [remainingTime, setRemainingTime] = useState(420);
  const [chessPaymentMessage, setChessPaymentMessage] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [showPhonepay, setShowPhonepay] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const paymentMethods = [{ label: "UPI", value: "upi", imageSrc: null }];
  const debitCardMethods = [
    { label: "VISA", value: "visa", imageSrc: null },
    { label: "Master Card", value: "mastercard", imageSrc: null },
    { label: "Rupay", value: "rupay", imageSrc: null },
  ];

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleDebitCardMethodsChange = (value: string) => {
    setSelectedCardMethod(value);
  };

  const handlePaymentMethodChange = (value: string) => {
    setSelectedMethod(value);
  };

  const generatedInvnos = new Set();

  function generateUniqueInvno() {
    let newInvno;
    do {
      newInvno = Math.floor(Math.random() * 1e16)
        .toString()
        .padStart(16, "0");
    } while (generatedInvnos.has(newInvno));
    generatedInvnos.add(newInvno);
    return newInvno;
  }

  useEffect(() => {
    handleQrCodeGenerator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  useEffect(() => {
    let timer: any, countdown: any;

    if (qrIsVisible) {
      countdown = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            clearInterval(countdown);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      timer = setTimeout(() => {
        setQrIsVisible(false);
      }, 420000);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(countdown);
    };
  }, [qrIsVisible]);

  const handleQrCodeGenerator = () => {
    if (!url) {
      setErrorMessage("Please enter a URL.");
      return;
    }
    setErrorMessage("");
    setQrIsVisible(true);
  };

  const handleGenerateQR = async (e: any) => {
    e.preventDefault();
    const paymentDetails = {
      status: "pending",
      mId: "SBKYKewQFJA=RkZGRlJYWFFQV0lOTVhFTUE=",
      invno: generateUniqueInvno(),
      fName: location.state.data.data.fullName,
      lName: location.state.data.data.fullName,
      mNo: location.state.data.data.mobileNumber,
      email: location.state.data.data.email,
      currency: "INR",
      amount: location.state.amount,
    };
    try {
      const response = await getIOPIntent(JSON.stringify(paymentDetails));
      if (response.ok) {
        const data = await response.json();
        setShowChessPayment(true);
        setInvoiceId(paymentDetails.invno);
        console.log("Payment successful:", data);
        setUrl(data.intent);
        const newTxnRef = push(txnRef);
        await set(newTxnRef, paymentDetails);
      } else {
        console.error("Payment failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error making payment request:", error);
    }
  };

  useEffect(() => {
    let interval: any;
    if (qrIsVisible && invoiceId) {
      interval = setInterval(() => {
        getPaymentStatus(invoiceId)
          .then(async (response) => {
            setChessPaymentMessage(response.data.status);
            const status = response.data.status;
            const fetchedInvoiceId = response.data.invoiceNumber;
            if (status === "SUCCESS" || status === "REFUNDED") {
              try {
                const transactionRef = ref(
                  db,
                  `transactions/${fetchedInvoiceId}`
                );
                const snapshot = await get(transactionRef);
                if (snapshot.exists()) {
                  await update(transactionRef, {
                    status: "SUCCESS",
                  });
                  console.log("Transaction status successfully updated!");
                } else {
                  console.error(
                    "No such transaction with the given invoice number!"
                  );
                }
                navigate("/success", { state: { invoiceId: invoiceId } });
              } catch (error) {
                console.error("Error updating transaction status: ", error);
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching transaction status:", error);
          });
      }, 3000);
    } else if (showPhonepay) {
      interval = setInterval(() => {
        getPhonepayPaymentStatus(invoiceId)
          .then(async (response: any) => {
            console.log(response);
            if (response.data === "success") {
              navigate("/success", { state: { invoiceId: invoiceId } });
            }
          })
          .catch((error) => {
            console.error("Error fetching transaction status:", error);
          });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [qrIsVisible, invoiceId, navigate, showPhonepay]);

  const handlePhonepay = () => {
    setShowPhonepay(true);
    setInvoiceId(location.state.breakdownId);
    axios
      .post(
        "https://dev.ashapurtiloans.com:8443/restful/v1/callIntent",
        location.state
      )
      .then((res: any) => {
        if (res.data.data.instrumentResponse.redirectInfo.url) {
          window.open(
            res.data.data.instrumentResponse.redirectInfo.url,
            "_blank"
          );
          navigate("/");
        } else {
          console.error("URL not found in response");
        }
      })
      .catch((error) => {
        console.error("Error occurred while making the API call:", error);
      });
  };

  return (
    <div className="payEmi-wrapper">
      <div className="w-full h-full min-h-dvh flex justify-center items-center py-10 md:pr-30 relative">
        <div className="py-8 px-0 md:px-6 w-full md:w-[650px] bg-[#f1f5f9] bg-opacity-90 min-h-[300px] rounded-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col shadow-shadow_2">
          <div className="logo mb-4 flex justify-center">
            <Link to="/">
              <img src={companyLogo} alt="logo" width={200} loading="lazy" />
            </Link>
          </div>
          <p className="text-slate-800 font-bold text-xs text-center">
            You are logged in from <span className="userIP" /> Mumbai,
            Maharashtra, India
          </p>
          <div className="w-full flex items-center justify-center flex-col mb-4">
            <div className="mt-4 underline-offset-2 underline text-base text-green-900 font-semibold">
              Pay By
            </div>
            <div className="flex flex-row grow form_group">
              <ul className="items-center w-full text-md font-medium text-gray-900 flex text-sm mt-4 gap-4">
                {paymentMethods.map((method) => (
                  <li key={method.value}>
                    <CustomRadioButton
                      key={method.value}
                      name="paymentMethod"
                      label={method.label}
                      value={method.value}
                      selectedValue={selectedMethod}
                      onChange={handlePaymentMethodChange}
                      imageSrc={method.imageSrc}
                      className="pr-4"
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            {selectedMethod === "debitCards" && (
              <div className="my-3 flex items-center justify-center flex-col">
                <div className="text-sm text-green-700 font-semibold mb-4">
                  Select Your Card
                </div>
                <div className="flex flex-row grow">
                  <ul className="items-center w-full text-md font-medium text-gray-900 flex mb-4 gap-4">
                    {debitCardMethods.map((cardMethod) => (
                      <li key={cardMethod.value}>
                        <CustomRadioButton
                          name="debitMethod"
                          label={cardMethod.label}
                          value={cardMethod.value}
                          selectedValue={selectedCardMethod}
                          onChange={handleDebitCardMethodsChange}
                          imageSrc={cardMethod.imageSrc}
                          className="pr-4"
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                {selectedCardMethod === "visa" && (
                  <>
                    <div className="mb-4">
                      <img src={VisaCard} alt="Visa Card" className="w-20" />
                    </div>
                    <div className=" form_group_grid gap-2 grid-cols-3">
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Name on Card"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Card No."
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow-0 text-sm"
                          type="text"
                          placeholder="MM / YY"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="CVV"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Amount"
                        />
                      </div>
                      <div className="">
                        <button
                          data-modal-target="pay-modal"
                          type="button"
                          className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {selectedCardMethod === "mastercard" && (
                  <>
                    <div className="mb-4">
                      <img src={MasterCard} alt="Visa Card" className="w-20" />
                    </div>
                    <div className=" form_group_grid gap-2 grid-cols-3">
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Name on Card"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Card No."
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow-0 text-sm"
                          type="text"
                          placeholder="MM/YY"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="CVV"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Amount:"
                        />
                      </div>
                      <div className="">
                        <button
                          data-modal-target="pay-modal"
                          type="button"
                          className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {selectedCardMethod === "rupay" && (
                  <>
                    <div className="mb-4">
                      <img src={Rupay} alt="Visa Card" className="w-20" />
                    </div>
                    <div className=" form_group_grid gap-2 grid-cols-3">
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Name on Card"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Card No."
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow-0 text-sm"
                          type="text"
                          placeholder="MM/YY"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="CVV"
                        />
                      </div>
                      <div className="">
                        <input
                          className="w-full p-2 rounded grow text-sm"
                          type="text"
                          placeholder="Amount:"
                        />
                      </div>
                      <div className="">
                        <button
                          data-modal-target="pay-modal"
                          type="button"
                          className="bg-green-700 text-white p-2 rounded w-full hover:bg-green-800 text-center text-sm h-10"
                          onClick={() => {
                            setShowModal(true);
                          }}
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
            <div
              className={`my-2 flex items-center justify-center flex-col ${
                selectedMethod === "upi" ? "" : "hidden"
              }`}
            >
              <div className="text-sm text-green-700 font-semibold mb-4">
                Select Your UPI App
              </div>
              <div className="mx-auto">
                <div className="flex items-center justify-center flex-wrap flex-col gap-4 border border-gray-400 rounded-lg p-3 mb-8 UPI_section">
                  <div className="UPI_icons gap-5">
                    <button
                      onClick={handlePhonepay}
                      className="bg-gray-100 hover:bg-slate-300 border rounded-lg border-gray-300 p-4 text-center mx-auto flex items-center justify-center w-[150px] h-[60px]"
                    >
                      <img src={Phonepe} alt="phonepe" loading="lazy" />
                    </button>
                  </div>
                  {showChessPayment && (
                    <div className="w-[18rem] flex items-center bg-white p-4 rounded-xl justify-center shadow-lg flex-col">
                      <form className="flex items-center justify-center flex-col">
                        {errorMessage && (
                          <div className="text-red-500 mb-4 text-sm">
                            {errorMessage}
                          </div>
                        )}
                        {qrIsVisible && (
                          <div className="flex flex-col items-center">
                            <img src={url} alt="qr code" />
                            <div className="mt-2 text-sm text-gray-500">
                              Time Remaining: {formatTime(remainingTime)}
                            </div>
                            <PaymentLoader />
                          </div>
                        )}
                        <div className="mt-4 text-green-bg-green-700">
                          Transaction Status: {chessPaymentMessage}
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {!showPhonepay ? (
                <button
                  type="button"
                  className="mx-auto col-span-2 text-center px-8 py-2 bg-green-700 text-white border-none rounded-lg cursor-pointer hover:bg-green-800"
                  onClick={handleGenerateQR}
                  disabled={qrIsVisible}
                >
                  Verify &amp; Pay
                </button>
              ) : (
                <PaymentLoader />
              )}
            </div>
            <div
              className={`my-2 flex items-center justify-center flex-col ${
                selectedMethod === "netbanking" ? "" : "hidden"
              }`}
            >
              <div className="text-sm text-green-700 font-semibold mb-4">
                Currently under integration
              </div>
            </div>
            <div className="my-2 hidden">Wallet</div>
          </div>
          <Link
            to="/"
            className="btn-gold btn text-slate-900 lg:!px-7 border-yellow-300  !py-2 shadow-shadow_1 !font-medium tracking-widest text-sm payEmi_Btn w-auto"
          >
            Go Back Home
          </Link>
        </div>
      </div>
      <div
        className={`bg-black/50 fixed top-0 left-0 w-full h-full z-10 flex items-center justify-center modal ${
          showModal ? "" : "hidden"
        }`}
      >
        <div className="relative p-4 pt-6 max-w-6xl py-12 px-12 w-[30%] bg-white bg-opacity-90 min-h-[400px] rounded-lg shadow-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col">
          <button
            className="absolute right-2 top-2 cursor-pointer"
            data-modal-hide="pay-modal"
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={20}
              height={20}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-x"
            >
              <path d="M18 6 6 18" />
              <path d="m6 6 12 12" />
            </svg>
          </button>
          <div className="logo mb-10">
            <img src={companyLogo} alt="logo" loading="lazy" />
          </div>
          <h4 className="font-bold text-2xl uppercase text-green-800 mb-3">
            Thank you for Payment
          </h4>
        </div>
      </div>
    </div>
  );
}

export default PayEmi;
