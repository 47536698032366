import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserFromLocalStorage,
  isUserLoggedIn,
  saveUserToLocalStorage,
} from "./../../utils/localStorageUtil";
import { handleGetBorrowerData } from "../../redux/Profile/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./../../utils/store";
import Loader from "../../utils/Loader";

const HomeScreen = () => {
  const { data } = useSelector((state: RootState) => state.profile);
  const [borrowerData, setBrorowerData] = useState<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const borrowerIdFromStorage = getUserFromLocalStorage()?.borrowerId;

  const handleNavigation = () => {
    if (!isUserLoggedIn()) {
      navigate("/login");
      return;
    }
    if (!borrowerData) return;

    if (
      !borrowerData.document ||
      borrowerData.document.length === 0 ||
      borrowerData.document === null
    ) {
      navigate("/upload-documents");
    } else if (
      !borrowerData.disbursement ||
      borrowerData.disbursement.length === 0 ||
      borrowerData.disbursement === null
    ) {
      navigate("/profile");
    } else {
      navigate("/loan-categories");
    }
  };

  useEffect(() => {
    handleNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, borrowerData, data]);

  const getBorrowerData = async (borrowerId: number) => {
    const res = await dispatch(handleGetBorrowerData(borrowerId));
    setBrorowerData(res?.payload?.borrowerData);
    const user = getUserFromLocalStorage();
    saveUserToLocalStorage({
      ...user,
      empType: res?.payload?.borrowerData?.occupation?.type,
      empSubType: res?.payload?.borrowerData?.occupation?.subType,
    });
  };

  useEffect(() => {
    if (borrowerIdFromStorage) {
      getBorrowerData(borrowerIdFromStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {borrowerData == null && (
        <div className="h-screen flex justify-center items-center">
          <div className="flex flex-col">
            <Loader customClass="w-10 h-10" /> <h1>Loading...</h1>
          </div>
        </div>
      )}
    </>
  );
};

export default HomeScreen;
