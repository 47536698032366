import { useNavigate } from "react-router-dom";
import Container from "../../components/ui/Container";
import logo from "../../assets/logo-web.png";
import MobileNav from "./MobileNav";
import Navbar from "./Navbar";

function Header() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/HomeScreen");
  };

  const handleSignup = () => {
    navigate("/register");
  };

  const handleAgentSignup = () => {
    navigate("/agent-signup");
  };

  return (
    <header id="header">
      <div className="w-full py-4">
        <Container>
          <div className="flex flex-col sm:flex-row items-center justify-between gap-y-4 sm:gap-0 md:pr-4">
            <a
              href="/"
              className="logo transition-all duration-500 ease-in-out !mr-0 w-full sm:w-[40%] leftHeader_box"
            >
              <img src={logo} alt="Ashapurti logo" />
            </a>
            <div className="flex flex-wrap flex-row gap-4 items-center justify-end w-full sm:w-[60%] rightHeader_box">
              {/* <div className="flex gap-4 "> */}
              {/* <button
                onClick={() => navigate("/upload-documents")}
                className="btn btn-green !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
              >
                upload doc
              </button> */}
              <button
                onClick={handleSignup}
                className="btn btn-green !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
              >
                Apply
              </button>
              <button
                onClick={handleLogin}
                className="btn btn-green !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
              >
                Log In
              </button>
              {/* </div> */}
              <button
                onClick={handleAgentSignup}
                className="btn btn-green !py-2 shadow-shadow_2 !font-medium tracking-widest text-sm"
              >
                Agent Signup
              </button>

              <div className="nav-area ">
                <MobileNav />
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className="nav-area ">
        <Navbar />
      </div>
    </header>
  );
}

export default Header;
