import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Agent, Occupation, PersonalInfo } from "../../utils/types";

interface UserState {
  personalInfo: PersonalInfo;
  occupation: Occupation;
  agent: Agent;
}

const initialState: UserState = {
  // personalInfo: {
  //   fullName: "Zeeshan",
  //   birthDate: "2000-01-05",
  //   maritalStatus: "unmarried",
  //   gender: "male",
  //   mobileNumber: "8956785489",
  //   address: "Sagar city",
  //   city: "Andheri west",
  //   pincode: "564001",
  //   state: "Maharashtra",
  //   email: "zeeshan@swordedgecommercials.com",
  //   panCardNumber: "INVJK8956G",
  //   aadhaarNumber: "452169874523",
  //   password: "Zeeshan@123",
  //   loanTypeId: 1,
  //   loanAmount: "50000",
  //   loanTenureId: 1,
  // },
  personalInfo: {
    fullName: "",
    birthDate: "",
    maritalStatus: "",
    gender: "",
    mobileNumber: "",
    address: "",
    correspondenceAddress: "",
    city: "",
    corrCity: "",
    pincode: "",
    corrPincode: "",
    state: "",
    corrState: "",
    email: "",
    panCardNumber: "",
    aadhaarNumber: "",
    password: "",
    loanTypeId: 0,
    loanAmount: "",
    loanTenureId: 0,
  },
  occupation: {
    type: "",
    subType: "",
    nameOfOrganisation: "",
    designation: "",
    department: "",
    stateOrCentral: "",
    yearsInCurrentDesignation: "",
    officeAddress: "",
    officePhoneNumber: "",
    annualIncome: "",
    lastMonthTakeHomeSalary: "",
  },
  agent: {
    agentId: null,
  },
};

const registrationFormData = createSlice({
  name: "userRegistration",
  initialState,
  reducers: {
    updatePersonalInfo: (
      state,
      action: PayloadAction<Partial<PersonalInfo>>
    ) => {
      state.personalInfo = { ...state.personalInfo, ...action.payload };
    },
    updateOccupation: (state, action: PayloadAction<Partial<Occupation>>) => {
      state.occupation = { ...state.occupation, ...action.payload };
    },
    handleCreate: (state) => {
      state.personalInfo = initialState.personalInfo;
      state.occupation = initialState.occupation;
    },
    handleCreateOccupation: (state) => {
      state.occupation = initialState.occupation;
    },
  },
});

export const {
  updatePersonalInfo,
  updateOccupation,
  handleCreate,
  handleCreateOccupation,
} = registrationFormData.actions;
export default registrationFormData.reducer;
