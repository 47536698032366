import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleGetBorrowerData } from "../../redux/Profile/profileSlice";
import { AppDispatch, RootState } from "../../utils/store";
import { getUserFromLocalStorage } from "../../utils/localStorageUtil";
import { handleBeforeUnload } from "../../utils/getIdFromUrl";
import DashboardBG from "../../assets/BG-4.jpg";

const ProfileScreen = () => {
  const style: React.CSSProperties = {
    backgroundImage: `url(${DashboardBG})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  const overlayStyle: React.CSSProperties = {
    backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent black overlay
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1, // Ensures the overlay is above the image
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const personalInfo = useSelector(
    (state: RootState) => state?.profile?.data?.borrowerData?.personalInfo
  );
  const occupation = useSelector(
    (state: RootState) => state?.profile?.data?.borrowerData?.occupation
  );
  const { loading } = useSelector((state: RootState) => state?.profile);
  const borrowerIdFromStorage = getUserFromLocalStorage()?.borrowerId;

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    dispatch(handleGetBorrowerData(Number(borrowerIdFromStorage)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    navigate("/document-review-status-page");
  };

  return (
    <>
      <div>
        <Header name="User Details" />
        <div
          className="flex justify-center items-center min-h-screen mt-16 md:mt-0 relative"
          style={style}
        >
          <div className="" style={overlayStyle}>
            <div className="my-60 md:my-0 mx-auto bg-[#f1f5f9] px-8 py-6 rounded-lg shadow-shadow_5 max-w-5xl w-full absolute left-0 right-0 top-1/2 -translate-y-1/2">
              <form onSubmit={handleSubmit} className="formWrapperContent">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6">
                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Aadhar Card:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="adharCard"
                      value={personalInfo?.aadhaarNumber}
                      className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      PAN Card:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="panCard"
                      value={personalInfo?.panCardNumber}
                      className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none"
                    />
                  </div>
                  <div className="form-group">
                    <label className="block text-sm font-semibold mb-2">
                      Mobile Number:
                    </label>
                    <div className="flex items-center">
                      <div className="bg-gray-200 p-2 border mb-2 border-gray-300 rounded-l-md text-gray-500">
                        +91
                      </div>
                      <input
                        disabled
                        type="tel"
                        name="mobileNumber"
                        value={personalInfo?.mobileNumber}
                        className="w-full p-2 border-t border-b mb-2 border-r border-gray-300 rounded-r-md focus:outline-none"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Email ID:
                    </label>
                    <input
                      disabled
                      type="email"
                      name="email"
                      value={personalInfo?.email}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Full Name:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="fullName"
                      value={personalInfo?.fullName}
                      className="w-full p-2 border border-gray-300 mb-2 rounded-md focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Address:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="address"
                      value={personalInfo?.address}
                      className="w-full p-2 border border-gray-300 mb-2 rounded-md focus:outline-none"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4 gap-x-6">
                  <div className="form-group">
                    <label className="block text-sm font-semibold">City:</label>
                    <input
                      disabled
                      type="text"
                      name="city"
                      value={personalInfo?.city}
                      className="w-full p-2 border border-gray-300 rounded-md mb-2 focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      State:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="state"
                      value={personalInfo?.state}
                      className="w-full p-2 border border-gray-300 rounded-md  mb-2 focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Pin Code:
                    </label>
                    <input
                      disabled
                      type="text"
                      name="postalCode"
                      value={personalInfo?.pincode}
                      className="w-full border border-gray-300 rounded-md mb-2 focus:outline-none"
                    />
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold">
                      Date of Birth:
                    </label>
                    <input
                      disabled
                      type="date"
                      name="dob"
                      value={personalInfo?.birthDate}
                      className="w-full p-2 border-gray-300 rounded-md  mb-2 focus:outline-none"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                    <div className="form-group">
                      <label className="block text-sm font-semibold">
                        Gender:
                      </label>
                      <select
                        name="gender"
                        value={personalInfo?.gender}
                        className="w-full p-2 border border-gray-300 mb-2 rounded-md focus:outline-none"
                        disabled
                      >
                        {["Male", "Female"].map((gender, index) => (
                          <option key={index} value={gender}>
                            {gender}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label className="block text-sm font-semibold">
                        Marital Status:
                      </label>
                      <select
                        name="maritalStatus"
                        value={personalInfo?.maritalStatus}
                        className="w-full p-2 border border-gray-300 mb-2 rounded-md focus:outline-none"
                        disabled
                      >
                        {["Married", "Unmarried"].map((status, index) => (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="block text-sm font-semibold ">
                      Occupation:
                    </label>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                      <div>
                        <input
                          disabled
                          type="text"
                          name="occupationType"
                          value={occupation?.type}
                          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                          placeholder="Type"
                        />
                      </div>
                      <div>
                        <input
                          disabled
                          type="text"
                          name="occupationSubtype"
                          value={occupation?.subType}
                          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                          placeholder="Subtype"
                        />
                      </div>
                      {/* <div>
                        <input
                          disabled
                          type="number"
                          name="netIncome"
                          value={occupation?.incomePerMonth}
                          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none"
                          placeholder="Net Income"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center mt-4 gap-2">
                  {/* <button
                  type="button"
                  className="bg-green-700 text-white py-2 px-6 rounded-md focus:outline-none hover:bg-green-800"
                  disabled={loading}
                >
                  Update
                </button> */}
                  <button
                    type="submit"
                    className="submitBtn !px-6"
                    disabled={loading}
                  >
                    Next
                  </button>
                </div>
              </form>
              {/* <button
                type="button"
                className="bg-green-700 text-white py-2 px-6 rounded-md focus:outline-none hover:bg-green-800"
                onClick={() => navigate("/upload-documents")}
              >
                route to upload document
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
