import React from "react";
// import "../../App.css";

const DeleteButton = ({ deletePopup }: any) => {
  return (
    <button
      className="delete-button bg-[#eb0e0e] text-white py-2 px-4 mb-2 rounded-md  hover:bg-[#c82333] focus:outline-none "
      onClick={deletePopup}
    >
      Delete
    </button>
  );
};

export default DeleteButton;
