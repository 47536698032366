import Container from "./Container";
import adBannerBG1 from "../../assets/inner-banners/ashapurti_adBanner-BG1.jpg";
import adBannerBG2 from "../../assets/inner-banners/ashapurti_adBanner-BG2.jpg";

const AdBanner = ({ dir = "left", img, alt, children }: any) => {
  const backgroundImage = dir === "left" ? adBannerBG2 : adBannerBG1;

  return (
    <section
      className="py-8 text-white bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <Container className="grid grid-cols-1 md:grid-cols-2 gap-12">
        <div className={dir === "left" ? "order-1" : "order-2"}>
          <img
            src={img}
            alt={alt}
            width={700}
            className="rounded-lg object-cover"
          />
        </div>
        <div
          className={
            dir === "left"
              ? "order-2 flex flex-col justify-center"
              : "order-1 flex flex-col justify-center"
          }
        >
          {children}
        </div>
      </Container>
    </section>
  );
};

export default AdBanner;
