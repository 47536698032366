import "../../styles/usersStyles/registerForm.css";
import Banner from "../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  handleRegistration,
  setTermsError,
} from "../../redux/registration/registrationSlice";
import { toast, ToastContainer } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PersonalInfo from "../../components/Register/PersonalInfo";
import Occupation from "../../components/Register/Occupation";
import Confirmation from "../../components/Register/Confirmation";
import { ReactComponent as ProfileIcon } from "../../assets/icons/Profile.svg";
import { ReactComponent as OccupationIcon } from "../../assets/icons/Occupation.svg";
import { ReactComponent as ConfirmationIcon } from "../../assets/icons/Confirmation.svg";
import Logo from "../../assets/Logo_circle.png";
import { AppDispatch, RootState } from "../../utils/store";
import CustomModal from "../../components/CustomModal";
import { hasProperties } from "../../utils/validationUtils";
import { useFormik } from "formik";
import { personalInfoValidation } from "../../components/Register/ragisterValidationSchema";
import { updatePersonalInfo } from "../../redux/registration/registrationFormDataSlice";
import { handleOccupationPayloadFormate } from "../../redux/registration/occupation/constant";
import {
  getUserFromLocalStorage,
  saveUserToLocalStorage,
} from "../../utils/localStorageUtil";
import { FaHome } from "react-icons/fa";

const RegisterScreen = () => {
  const { registrationFormData } = useSelector((state: RootState) => state);
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [applicationId, setApplicationId] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formikData, setFormikData] = useState<any>();
  const [formikError, setFormikError] = useState<any>();
  const [tenureMessage, setTenureMessage] = useState<any>("");

  const dispatch = useDispatch<AppDispatch>();
  const { hasChecked, termsError, empSubType, empType, isSameAddress } =
    useSelector((state: RootState) => state.registrationSlice);

  const navigate = useNavigate();
  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      fullName: "",
      aadhaarNumber: "",
      panCardNumber: "",
      mobileNumber: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      password: "",
      confirmPassword: "",
      gender: "",
      maritalStatus: "",
      loanAmount: "",
      birthDate: "",
      tenure: "",
      loanTypeId: "",
    },
    validationSchema: personalInfoValidation,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values: any) => {
      handleNext();
      dispatch(updatePersonalInfo({ fullName: values?.fullName }));
      dispatch(updatePersonalInfo({ address: values?.address }));
      dispatch(updatePersonalInfo({ city: values?.city }));
      dispatch(updatePersonalInfo({ state: values?.state }));
      dispatch(updatePersonalInfo({ pincode: values?.pincode }));
      dispatch(updatePersonalInfo({ birthDate: values?.birthDate }));
      dispatch(
        updatePersonalInfo({
          panCardNumber: values?.panCardNumber?.toUpperCase(),
        })
      );
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updatePersonalInfo({ [name]: value }));
  };

  const handnleAllInputsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formik) {
      formik.handleChange(e);
    }
    handleInputChange(e);
  };

  const handnleAllInputsBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (formik) {
      formik.handleBlur(e);
      setFormikData(formik?.values);
    }
  };

  useEffect(() => {
    setFormikData(formik);
    formik.setFieldValue(
      "fullName",
      registrationFormData?.personalInfo?.fullName
    );
    formik.setFieldValue(
      "birthDate",
      registrationFormData?.personalInfo?.birthDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    if (activeTab > 0) {
      setActiveTab(activeTab - 1);
    }
  };

  const occupation = {
    ...registrationFormData.occupation,
    type: empType,
    subType: empSubType,
  };

  const personalInfo = {
    ...registrationFormData.personalInfo,
    correspondenceAddress: registrationFormData.personalInfo.address,
    corrCity: registrationFormData.personalInfo.city,
    corrState: registrationFormData.personalInfo.state,
    corrPincode: registrationFormData.personalInfo.pincode,
  };

  const agent = {
    ...registrationFormData.agent,
    agentId: location.state === "Agent" ? 1 : null,
  };

  const handleSubmit = (e: any) => {
    const result = handleOccupationPayloadFormate(
      empType,
      empSubType,
      occupation
    );

    e.preventDefault();
    // if (aadharVer && confirmPass === formData.personalInfo.password && hasChecked) {
    try {
      const payload = {
        ...registrationFormData,
        occupation: result,
        agent,
      };
      const payloadWithCorrAddress = {
        ...registrationFormData,
        occupation: result,
        personalInfo,
        agent,
      };
      dispatch(
        handleRegistration(isSameAddress ? payloadWithCorrAddress : payload)
      ).then((res) => {
        if (
          res?.payload?.statusCode === 200 &&
          res?.payload?.status?.toLowerCase() === "success"
        ) {
          setCustomerId(res?.payload?.borrowerId);
          setApplicationId(res?.payload?.borrowerApplicationId);
          setIsModalOpen(true);
          const user = getUserFromLocalStorage();
          saveUserToLocalStorage({
            ...user,
            empType: occupation?.type,
            empSubType: occupation?.subType,
          });
        } else {
          toast.error(res?.payload?.message);
        }
      });
    } catch (error) {
      console.log("handleRegistration ERROR :", error);
    }
    // }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (location.state === "Agent") {
      navigate(`/upload-documents?customerId=${customerId}`, {
        state: {
          type: empType,
          routeFrom: "registration",
          applicationType: "Agent",
        },
      });
    } else {
      navigate(`/upload-documents?customerId=${customerId}`, {
        state: { type: empType, routeFrom: "registration" },
      });
    }
  };

  useEffect(() => {
    setFormikError(hasProperties(formik.errors));
    if (formik.errors) {
      setActiveTab(0);
    }
  }, [formik.touched, formik.errors]);

  const handleNext = () => {
    if (activeTab === 0 && !hasChecked) {
      dispatch(setTermsError("Please check the terms and conditions"));
      return;
    }
    if (activeTab < 2 && !formikError) {
      setActiveTab(activeTab + 1);
    }
    if (tenureMessage) {
      setActiveTab(0);
    } else {
      setActiveTab(activeTab + 1);
    }
  };
  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="h-full w-full bg-no-repeat bg-cover min-h-screen"
    >
      <div className="registerSection py-24 h-dvh flex items-center justify-center">
        <div className="max-w-[1024px] w-full">
          <div className="wizard-container">
            <div className="tab-contentWrapper">
              <ul className="tab-header flex justify-around sm:justify-between mb-6 gap-8">
                <li className="tab-itemHome">
                  <img src={Logo} alt="logo" className="pr-6 w-40" />
                </li>
                <li>
                  <div className="flex items-center gap-1 w-20 h-8">
                    <Link
                      to="/"
                      className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
                    >
                      <FaHome className="fill-green-700  hover:fill-green-900  " />
                      <p className="text-xs text-slate-400">Home Page</p>
                    </Link>
                  </div>
                </li>

                <li className="w-full">
                  <ul className="flex items-center justify-end gap-4 flex-col md:flex-row">
                    <li
                      className={`tab-item ${activeTab === 0 ? "active" : ""}`}
                    >
                      <ProfileIcon fill="#3498db" width={40} height={40} />
                      <span>Personal</span>
                    </li>
                    <li
                      className={`tab-item ${activeTab === 1 ? "active" : ""}`}
                    >
                      <OccupationIcon fill="#3498db" width={40} height={40} />
                      <span>Occupation</span>
                    </li>
                    <li
                      className={`tab-item ${activeTab === 2 ? "active" : ""}`}
                    >
                      <ConfirmationIcon fill="#3498db" width={40} height={40} />
                      <span>Confirmation</span>
                    </li>
                  </ul>
                </li>
              </ul>
              <div className="tab-content">
                <form action="" onSubmit={formik.handleSubmit}>
                  {activeTab === 0 && (
                    <PersonalInfo
                      setFormikData={setFormikData}
                      formik={formik}
                      handnleAllInputsChange={handnleAllInputsChange}
                      handnleAllInputsBlur={handnleAllInputsBlur}
                      setTenureMessage={setTenureMessage}
                      tenureMessage={tenureMessage}
                    />
                  )}
                  {activeTab === 1 && <Occupation />}
                  {activeTab === 2 && <Confirmation source="Register" />}
                  <span className="error">{termsError}</span>

                  <div className="flex items-center justify-between mt-1 relative">
                    <div className="absolute top-1/2 -translate-y-1/2 left-0">
                      <Link
                        to="/login"
                        className="text-[#3c8f47] font-semibold text-center block hover:text-green-900 transition-all duration-300 ease-in-out text-sm "
                      >
                        {location.state === "Agent"
                          ? "Back to Dashboard"
                          : "Back to Login"}
                      </Link>
                    </div>
                    <div className="button-container">
                      <button
                        type="button"
                        onClick={handleBack}
                        disabled={activeTab === 0}
                        className="backBtn"
                      >
                        Back
                      </button>
                      {activeTab < 2 ? (
                        <button type="submit" className="nextBtn">
                          Next
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="submitBtn"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <CustomModal
              isOpenCustModal={isModalOpen}
              onClose={closeModal}
              title="Success!"
              modalName="registration"
            >
              <h3 className="font-semibold">
                Your Application Id is : {applicationId}
              </h3>
              <p>
                Your login details will be shared on your registered mail id
                Please login to apply loan
              </p>
            </CustomModal>
            <ToastContainer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterScreen;
