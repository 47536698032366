import React from "react";
// import "../../App.css";

const UpdateButton = ({ updatePopup }: any) => {
  return (
    <button
      className="update-button bg-[#007bff] text-white py-2 px-3 mb-2 rounded-md hover:bg-[#0056b3] "
      onClick={updatePopup}
    >
      Update
    </button>
  );
};

export default UpdateButton;
