import React, { useState } from "react";
import { ChildComponentCreateProps, LoanDetails } from "../../utils/types";

const CreatePopup: React.FC<ChildComponentCreateProps> = ({
  isOpenCreate,
  createPopup,
}: any) => {
  const [loanDetails, setLoanDetails] = useState<LoanDetails>({
    title: "",
    maxAmount: 0,
    minAmount: 0,
    minInterest: 0,
    maxInterest: 0,
    description: "",
    particular: [""],
  });

  const [errors, setErrors] = useState<any>({});

  // Handle input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof LoanDetails
  ) => {
    setLoanDetails({ ...loanDetails, [field]: e.target.value });
  };

  // Handle particular array input change
  const handleParticularChange = (index: number, value: string) => {
    const updatedParticular = [...loanDetails.particular];
    updatedParticular[index] = value;
    setLoanDetails({ ...loanDetails, particular: updatedParticular });
  };

  // Add new particular input
  const addParticular = () => {
    setLoanDetails({
      ...loanDetails,
      particular: [...loanDetails.particular, ""],
    });
  };

  const deleteParticular = (index: number) => {
    const updatedParticular = loanDetails.particular.filter(
      (_, i) => i !== index
    );
    setLoanDetails({ ...loanDetails, particular: updatedParticular });
  };

  // Validate inputs
  const validateForm = (): boolean => {
    const newErrors: any = {};
    if (!loanDetails.title) newErrors.title = "Title is required.";
    if (loanDetails.maxAmount <= 0)
      newErrors.maxAmount = "Max Amount must be greater than 0.";
    if (loanDetails.minAmount <= 0)
      newErrors.minAmount = "Min Amount must be greater than 0.";
    if (loanDetails.minInterest < 0)
      newErrors.minInterest = "Min Interest cannot be negative.";
    if (loanDetails.maxInterest < loanDetails.minInterest)
      newErrors.maxInterest = "Max Interest cannot be less than Min Interest.";
    if (!loanDetails.description)
      newErrors.description = "Description is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = () => {
    if (validateForm()) {
      console.log(loanDetails);
      // createPopup();
    }
  };

  return (
    <div>
      {isOpenCreate && (
        <div className="fixed inset-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-[#133b19] rounded-lg w-96 p-4 relative">
            <h2 className="text-white text-xl ">Create Loan Details</h2>
            <div className="bg-gray-100 p-4">
              <div className="scrollable-content">
                <div>
                  <label className="flex font-normal">Title:</label>
                  <input
                    type="text"
                    value={loanDetails.title}
                    onChange={(e) => handleChange(e, "title")}
                    className="w-full p-2  rounded-md"
                  />
                  {errors.title && (
                    <p className="text-red-500 text-sm">{errors.title}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal">Max Amount:</label>
                  <input
                    type="number"
                    value={loanDetails.maxAmount}
                    onChange={(e) => handleChange(e, "maxAmount")}
                    className="w-full p-2  rounded-md"
                  />
                  {errors.maxAmount && (
                    <p className="text-red-500 text-sm">{errors.maxAmount}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal">Min Amount:</label>
                  <input
                    type="number"
                    value={loanDetails.minAmount}
                    onChange={(e) => handleChange(e, "minAmount")}
                    className="w-full p-2  rounded-md"
                  />
                  {errors.minAmount && (
                    <p className="text-red-500 text-sm">{errors.minAmount}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal">Min Interest:</label>
                  <input
                    type="number"
                    value={loanDetails.minInterest}
                    onChange={(e) => handleChange(e, "minInterest")}
                    className="w-full p-2  rounded-md"
                  />
                  {errors.minInterest && (
                    <p className="text-red-500 text-sm">{errors.minInterest}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal">Max Interest:</label>
                  <input
                    type="number"
                    value={loanDetails.maxInterest}
                    onChange={(e) => handleChange(e, "maxInterest")}
                    className="w-full p-2  rounded-md"
                  />
                  {errors.maxInterest && (
                    <p className="text-red-500 text-sm">{errors.maxInterest}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal ">Description:</label>
                  <textarea
                    value={loanDetails.description}
                    onChange={(e) => handleChange(e, "description")}
                    className="w-full p-2  rounded-md"
                  ></textarea>
                  {errors.description && (
                    <p className="text-red-500 text-sm">{errors.description}</p>
                  )}
                </div>

                <div>
                  <label className="flex font-normal">Particulars:</label>
                  {loanDetails.particular.map((particular, index) => (
                    <div
                      key={index}
                      className="flex items-center space-x-2 mb-2 "
                    >
                      <input
                        type="text"
                        value={particular}
                        onChange={(e) =>
                          handleParticularChange(index, e.target.value)
                        }
                        className="w-full p-2 rounded-md"
                      />
                      {index === 0 ? (
                        <button
                          onClick={addParticular}
                          className="bg-[#133b19] text-white p-2 rounded-md"
                        >
                          +
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={addParticular}
                            className="bg-[#133b19] text-white p-2 rounded-md mr-2"
                          >
                            +
                          </button>
                          <button
                            onClick={() => deleteParticular(index)}
                            className="bg-[#eb0e0e] text-white p-2 rounded-md "
                          >
                            -
                          </button>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-between mt-6">
                <button
                  onClick={createPopup}
                  className="w-1/2 bg-[#eb0e0e] mr-4 text-white py-2 rounded-md hover:bg-[#c82333]"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  className="w-1/2 bg-[#133b19] text-white py-2 rounded-md hover:bg-[#165423] "
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatePopup;
