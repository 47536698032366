import MobileMenuItems from "./MobileMenuItems";

const MobileDropdown = ({ submenus, dropdown, depthLevel }: any) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu: any, index: any) => (
        <MobileMenuItems
          items={submenu}
          key={index}
          depthLevel={depthLevel}
          showMenu={""}
          setShowMenu={""}
        />
      ))}
    </ul>
  );
};

export default MobileDropdown;
