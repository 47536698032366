const SendButton = ({ sendPopup }: any) => {
  return (
    <button
      className="send-button bg-[#333] text-white py-2 px-3 mb-2 rounded-md hover:bg-[#444] focus:outline-none"
      onClick={sendPopup}
    >
      Send
    </button>
  );
};

export default SendButton;
