import { RadioButtonProps } from "../utils/types";

const CustomRadioButton: React.FC<RadioButtonProps> = ({
  name,
  label,
  value,
  selectedValue,
  onChange,
  className = "",
  imageSrc, // Receive the imageSrc prop
}) => {
  return (
    <label
      className={`border border-[#E6ECF4] border-solid rounded-md px-4 py-2 flex items-center box-shadow cursor-pointer ${
        selectedValue === value
          ? "border-green-400 bg-green-200 shadow-md "
          : "border-slate-300 bg-gray-200"
      } ${className}`}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={selectedValue === value}
        onChange={() => onChange(value)}
        className="radio-input hidden"
        required
      />
      <span
        className={`radio-custom border-2 rounded-full w-4 h-4 mr-2 flex justify-center items-center transition-all duration-300 ${
          selectedValue === value
            ? "border-green-900 bg-green-600"
            : "border-gray-400 bg-gray-50"
        }`}
      >
        {selectedValue === value && (
          <div className="w-2 h-2 bg-white rounded-full"></div>
        )}
      </span>

      {/* Conditionally render image or just the label */}
      {imageSrc ? (
        <img
          src={imageSrc}
          alt={label}
          className="w-6 h-6 mr-2 rounded-full" // Adjust image size and margin as needed
        />
      ) : null}

      <span
        className={`text-sm font-medium ${
          selectedValue === value
            ? "text-green-900 font-medium text-base"
            : "text-slate-800"
        }`}
      >
        {label}
      </span>
    </label>
  );
};

export default CustomRadioButton;
