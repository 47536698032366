import { Doughnut,Bar } from "react-chartjs-2";
import "../components/chartConfig"; // Import chart configuration
import styles from '../styles/dashboardstyles/Revenue.module.css'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
export default function Revenue() {
  const data = {
    labels: ['Personal','Gold', 'Vehicle', 'Home', 'Property', 'Business', 'Art & Antiques', 'Shares & Security', 'SME', 'Holiday', 'Wedding', 'CA',  'Doctor'],
    datasets: [
      {
        data: [300, 400, 200,300,400,400, 200,300,400,400, 200,300,400],
        backgroundColor: ["#3c8f47", '#95d161', "#216d29","#6ed678", "#0e4c11", "#93ad2b","#8b962a", "#ced6c8", "#bfd159","#d8e0a9", "#1d8921", "#77af79","#9da09c"],
      },
    ],
  };



  const options: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        position: 'right', // Move labels to the right
        labels: {
          boxWidth: 10,
          padding: 10

        },
      },
      title: {
        display: true,
        text: 'Revenue',
        color: '#333', // Title text color (dark gray)
        font: {
          size: 20, // Font size for the title
          weight: 'bold', // Font weight for the title
          family: "'Arial', sans-serif" // Font family
        },
        padding: {
          top: 10,
          bottom: 10
        },
       
        align: 'start' // Align the title at the center
      },
    },
    layout: {
      padding: {
        top: 10, // Adds extra space around the entire chart, including above the title
        left: 10
      }
    },
    responsive: true,
    maintainAspectRatio: false, // Adjust to the height of the container
  };
 

  return (
    <div className={styles.revenue_circle_container}>
      <div  className={styles.revenue_circle}>
      <Doughnut data={data} options={options} />
      </div>
    </div>
  );
}
