import { Line } from "react-chartjs-2";
import styles from '../styles/dashboardstyles/YearlyGrowth.module.css';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';

// Register necessary Chart.js components, including PointElement for line charts
ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend);

export default function YearlyGrowth() {
  const data = {
    labels: [
      'Personal','Gold', 'Vehicle', 'Home', 'Property', 'Business', 'Art & Antiques', 'Shares & Security', 'SME', 'Holiday', 'Wedding', 'CA',  'Doctor'
    ],
    datasets: [
      {
        label: "Yearly Growth",
        data: [50, 100, 200, 300, 400, 300, 600, 700, 800, 300, 1000, 100, 200], // Data in lakhs (L)
        borderColor: "black",
        backgroundColor: [
          '#3c8f47', '#95d161', '#216d29', '#6ed678', '#0e4c11', '#93ad2b', 
          '#8b962a', '#ced6c8', '#bfd159', '#d8e0a9', '#1d8921', '#77af79', '#9da09c'
        ],
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          callback: (tickValue: string | number) => {
            const value = typeof tickValue === 'number' ? tickValue : parseFloat(tickValue);
            if (!isNaN(value)) {
              // Format y-axis labels as lakhs (L) or crores (cr)
              return value >= 100 ? `${value / 1000}cr` : `${value}L`;
            }
            return tickValue;
          },
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Yearly Growth',
        color: '#333',
        font: {
          size: 20,
          weight: 'bold',
          family: "'Arial', sans-serif"
        },
        padding: {
          top: 10,
          bottom: 10
        },
        align: 'start',
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw;
            if (value >= 100) {
              return `${value / 100}cr`; // Convert thousands to crores
            }
            return `${value}L`; // Otherwise, show in lakhs
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        left: 10,
      },
    },
  };

  return (
    <div className={styles.turnover_yearly_container}>
      <div className={styles.turnover_yearly}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
}
