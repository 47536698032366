import { useEffect } from "react";
import ArtificialIntelligence from "./ArtificialIntelligence";
import HomeSlider from "./HomeSlider";
import LoansWeProvide from "./LoansWeProvide";
import WhyChooseUs from "./WhyChooseUs";
import { removeUserFromLocalStorage } from "../../utils/localStorageUtil";

function Home() {
  useEffect(() => {
    removeUserFromLocalStorage();
  });
  return (
    <>
      <HomeSlider />
      <WhyChooseUs />
      <ArtificialIntelligence />
      <LoansWeProvide />
    </>
  );
}

export default Home;
