


type FormikErrors<T> = {
  [K in keyof T]?: string;
};

function hasErrors<T>(errors: FormikErrors<T>): boolean {
  if (errors) {
  }
  return Object.keys(errors)?.length > 0;
}
function hasProperties(obj: object): boolean {
  return Object.keys(obj).length > 0;
}
export {  hasErrors, hasProperties };
