import { useEffect, useState } from "react";
import CustomDropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  handleCreate,
  handleCreateOccupation,
  updateOccupation,
} from "../../redux/registration/registrationFormDataSlice";
import {
  setEmpSubType,
  setEmpType,
} from "../../redux/registration/registrationSlice";

import Logo from "../../assets/logoNew-light.png";

const Occupation = () => {
  const { occupation } = useSelector((state) => state?.registrationFormData);
  const { empSubType, empType } = useSelector(
    (state) => state?.registrationSlice
  );
  const { registrationFormData } = useSelector((state) => state);
  const [selectedType, setSelectedType] = useState(
    registrationFormData.type || ""
  );

  const [inputFieldsForMap, setInputFieldsForMap] = useState([]);

  // occupationAddress
  const govtEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Organisation",
      inputName: "nameOfOrganisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.nameOfOrganisation,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.department,
    },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.lastMonthTakeHomeSalary,
    },
  ];

  const pvtEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Company",
      inputName: "nameOfCompany",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.nameOfCompany,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.department,
    },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.lastMonthTakeHomeSalary,
    },
  ];

  const psuEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Organisation",
      inputName: "nameOfOrganisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.nameOfOrganisation,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.department,
    },
    // {
    //   inputType: "text",
    //   label: "State/Central",
    //   inputName: "offStateOrCentral",
    //   placeholder: "",
    //   inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
    //   value: occupation?.offStateOrCentral,
    // },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
    {
      inputType: "select",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.lastMonthTakeHomeSalary,
    },
  ];
  const bankEmployeeInputs = [
    {
      inputType: "text",
      label: "Name of Bank",
      inputName: "nameOfBank",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.nameOfBank,
    },
    {
      inputType: "text",
      label: "Designation",
      inputName: "designation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.designation,
    },
    {
      inputType: "text",
      label: "Department",
      inputName: "department",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.department,
    },
    // {
    //   inputType: "text",
    //   label: "State/Central",
    //   inputName: "offStateOrCentral",
    //   placeholder: "",
    //   inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
    //   value: occupation?.offStateOrCentral,
    // },

    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
    {
      inputType: "number",
      label: "Year in the current designation",
      inputName: "yearsInCurrentDesignation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsInCurrentDesignation,
    },
    {
      inputType: "number",
      label: "Office phone number",
      inputName: "officePhoneNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.officePhoneNumber,
    },
    {
      inputType: "number",
      label: "Annual Income",
      inputName: "annualIncome",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.annualIncome,
    },
    {
      inputType: "number",
      label: "Last month take home salary",
      inputName: "lastMonthTakeHomeSalary",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.lastMonthTakeHomeSalary,
    },
  ];
  const DoctorLawyerCASelfEmpInputs = [
    {
      inputType: "text",
      label: "Registered number",
      inputName: "registeredNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.registeredNumber,
    },
    {
      inputType: "text",
      label: "Specialisation",
      inputName: "specialisation",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.specialisation,
    },
    {
      inputType: "number",
      label: "Years in practise",
      inputName: "yearsInPractise",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsInPractise,
    },
    {
      inputType: "text",
      label: "Previous year IT return",
      inputName: "previousYearITReturn",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.previousYearITReturn,
    },
    {
      inputType: "number",
      label: "Amount of tax paid",
      inputName: "amountOfTaxPaid",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.amountOfTaxPaid,
    },
    {
      inputType: "number",
      label: "Bank account number",
      inputName: "bankAccountNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.bankAccountNumber,
    },

    {
      inputType: "text",
      label: "Bank IFSC code",
      inputName: "bankIfsccode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.bankIfsccode,
    },
    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
  ];
  const freelancerSelfEmpInputs = [
    {
      inputType: "text",
      label: "Registered number",
      inputName: "registeredNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.registeredNumber,
    },
    {
      inputType: "text",
      label: "Nature of work",
      inputName: "natureOfWork",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.natureOfWork,
    },
    {
      inputType: "number",
      label: "Years of experience",
      inputName: "yearsOfExperience",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.yearsOfExperience,
    },
    {
      inputType: "text",
      label: "Other",
      inputName: "other",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.other,
    },
  ];
  const businessSelfEmpInputs = [
    {
      inputType: "text",
      label: "GST Number",
      inputName: "gstNumber",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.gstNumber,
    },
    {
      inputType: "text",
      label: "Name of Business",
      inputName: "businessName",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.businessName,
    },
    {
      inputType: "text",
      label: "Office address",
      inputName: "occupationAddress",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.occupationAddress,
    },
    {
      inputType: "text",
      label: "City",
      inputName: "offCity",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offCity,
    },
    {
      inputType: "text",
      label: "State/Central",
      inputName: "offStateOrCentral",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offStateOrCentral,
    },
    {
      inputType: "number",
      label: "Pin Code",
      inputName: "offPincode",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.offPincode,
    },
    {
      inputType: "text",
      label: "Type of business",
      inputName: "typeOfBusiness",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.typeOfBusiness,
    },
    {
      inputType: "text",
      label: "Nature Of business",
      inputName: "natureOfBusiness",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.natureOfBusiness,
    },
    {
      inputType: "number",
      label: "Amount of tax paid",
      inputName: "amountOfTaxPaid",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.amountOfTaxPaid,
    },
    {
      inputType: "text",
      label: "Previous year IT return",
      inputName: "previousYearITReturn",
      placeholder: "",
      inputClass: "form-group w-full md:w-2/5 lg:w-[31.79%]",
      value: occupation?.previousYearITReturn,
    },
  ];

  const dispatch = useDispatch();
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "loanAmount" || name === "incomePerMonth") {
      if (!/^\d*$/.test(value)) return;
    }
    if (name === "subType") {
      dispatch(setEmpSubType(value));
    }
    dispatch(updateOccupation({ [name]: value }));
  };

  const options = [
    "Employed",
    "Self-Employed",
    // "Self-Employed: Professional",
    // "Retired",
    // "Home Maker",
  ];

  useEffect(() => {
    setSelectedType("");
  }, [selectedType]);

  const subTypeOptions = {
    Employed: [
      "Govt. Employee",
      "Pvt. Employee",
      "PSU Employee",
      "Bank Employee",
    ],
    "Self-Employed: Business": ["Proprietor", "Pvt. Ltd."],
    "Self-Employed": ["Freelancer", "Doctor", "Business", "CA", "Lawyer"],
    Retired: ["Pensioner", "VRS"],
  };

  const handleSelect = (option) => {
    dispatch(updateOccupation({ subType: "" }));
    setSelectedType(option);
    dispatch(updateOccupation({ type: option }));
    dispatch(setEmpType(option));
  };

  // const selectType = () => {
  //   setSelectedType(occupation?.type);
  //   return occupation?.type;
  // };
  console.log("empSubType :", empSubType);
  console.log("occupation?.subType :", occupation?.subType);
  console.log("subType :", empSubType);
  return (
    <>
      <div className="occupationInfo space-y-6 min-h-[545px]">
        <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
          Personal Loan Application
        </h4>
        <form>
          <div className="form-group w-full flex-col md:flex-row flex items-center gap-4 justify-start bg-gray-50 rounded-xl shadowLight border-r-2 border-l-2 p-4 border-green-800">
            <label className="!text-base !mb-0 font-bold pr-8">Type :</label>
            <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row gap-6 flex-wrap">
              {options.map((subType) => (
                <label
                  onClick={() => {
                    dispatch(setEmpSubType(subType));
                    dispatch(handleCreateOccupation());
                    handleSelect(subType);
                  }}
                  key={subType}
                  className={` rounded-md px-4 py-2 flex items-center !mb-0 cursor-pointer  ${
                    selectedType === subType
                      ? "bg-green-300 boxShadow border border-solid border-green-400"
                      : "bg-white shadowLight border border-solid border-gray-300"
                  }`}
                >
                  <input
                    type="radio"
                    name="subType"
                    value={subType}
                    checked={selectedType === subType}
                    onChange={handleInputChange}
                    required
                    className="radio-input hidden"
                  />
                  <span
                    className={`radio-custom ${
                      selectedType === subType
                        ? "border-green-500 bg-green-500"
                        : "border-gray-400"
                    }`}
                  ></span>
                  {subType}
                </label>
              ))}
            </div>
          </div>

          <div className="occu_content h-full min-h-[400px] flex justify-center items-center flex-col">
            {!selectedType && (
              <div className="bgImg w-full flex items-center justify-center">
                <p className="text-red-500 font-normal text-base text-center">
                  Please choose your Occupation
                </p>
              </div>
            )}

            {selectedType && (
              <div className="w-full flex">
                {selectedType && (
                  <div className="occu_LeftSide w-[22%] mt-6 border-r-[1px] border-solid border-green-500 pr-6">
                    <div className="">
                      {selectedType === "Home Maker" ? (
                        <>
                          <div className="form-group w-full md:w-1/3">
                            <label>Source of income</label>
                            <input
                              type="text"
                              name="details"
                              placeholder=""
                              value={occupation?.details || ""}
                              onChange={handleInputChange}
                              className="border border-gray-300 rounded-md px-4 py-2 w-full"
                            />
                          </div>
                          <div className="form-group w-full md:w-1/3">
                            <label>Net Income Per Month</label>
                            <input
                              type="number"
                              name="incomePerMonth"
                              placeholder=""
                              value={occupation?.incomePerMonth || ""}
                              onChange={handleInputChange}
                              className="border border-gray-300 rounded-md px-4 py-2 w-full"
                            />
                          </div>
                        </>
                      ) : (
                        selectedType &&
                        subTypeOptions[selectedType] && (
                          <div className="form-group w-full customRadioBtn ">
                            <label className="!text-sm !mb-4 font-bold underline underline-offset-2 !text-green-800">
                              Sub Type
                            </label>
                            <div className="flex flex-col sm:flex-row md:flex-row lg:flex-row flex-wrap">
                              {subTypeOptions[selectedType].map((subType) => (
                                <label
                                  onClick={() => {
                                    dispatch(setEmpSubType(subType));
                                    dispatch(handleCreateOccupation());
                                  }}
                                  key={subType}
                                  className={`border border-[#E6ECF4] border-solid px-4 py-3 flex items-center !mb-0 w-full cursor-pointer hover:bg-green-700 hover:!text-white transition-transform ${
                                    occupation?.subType === subType
                                      ? "bg-green-300 boxShadow customArrow"
                                      : "bg-transparent border-0 border-b-[1px]"
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="subType"
                                    value={subType}
                                    checked={occupation?.subType === subType}
                                    onChange={handleInputChange}
                                    required
                                    className="radio-input hidden"
                                  />
                                  <span
                                    className={`${
                                      occupation?.subType === subType
                                        ? "border-green-500 bg-green-500"
                                        : "border-gray-400"
                                    }`}
                                  ></span>
                                  {subType}
                                </label>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}

                {(empType == "Employed" || empType == "Self-Employed") && (
                  <div className="occu_RightSide w-[78%] pl-6 mt-6 min-h-[340px]">
                    <div className="flex flex-wrap gap-4">
                      {selectedType && !occupation?.subType && (
                        <>
                          <p className="text-red-500 font-normal text-base occu_RightSide-content">
                            Please choose your Field
                          </p>
                        </>
                      )}
                      {selectedType &&
                      empType == "Employed" &&
                      occupation?.subType === "Govt. Employee"
                        ? govtEmployeeInputs?.map((input, index) => {
                            return (
                              <div className={input?.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                  className="border border-gray-300 rounded-md px-4 py-2 w-full"
                                />
                              </div>
                            );
                          })
                        : selectedType &&
                          empType == "Employed" &&
                          occupation?.subType === "Pvt. Employee"
                        ? pvtEmployeeInputs?.map((input, index) => {
                            return (
                              <div className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : selectedType &&
                          empType == "Employed" &&
                          occupation?.subType === "PSU Employee"
                        ? psuEmployeeInputs?.map((input, index) => {
                            return (
                              <div className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : selectedType &&
                          empType == "Employed" &&
                          occupation?.subType === "Bank Employee"
                        ? bankEmployeeInputs?.map((input, index) => {
                            return (
                              <div key={index} className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : (selectedType &&
                            empType == "Self-Employed" &&
                            occupation?.subType === "Doctor") ||
                          (selectedType && occupation?.subType === "CA") ||
                          (selectedType && occupation?.subType === "Lawyer")
                        ? DoctorLawyerCASelfEmpInputs?.map((input, index) => {
                            return (
                              <div className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : selectedType &&
                          empType == "Self-Employed" &&
                          occupation?.subType === "Freelancer"
                        ? freelancerSelfEmpInputs?.map((input, index) => {
                            return (
                              <div className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : selectedType &&
                          empType == "Self-Employed" &&
                          occupation?.subType === "Business"
                        ? businessSelfEmpInputs?.map((input, index) => {
                            return (
                              <div className={input.inputClass}>
                                <label>{input.label}</label>
                                <input
                                  type={input.inputType}
                                  name={input.inputName}
                                  placeholder={input.placeholder}
                                  value={input.value || ""}
                                  onChange={handleInputChange}
                                />
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default Occupation;
