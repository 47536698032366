import Tesseract from "tesseract.js";

function removeSpaces(inputString: string) {
  return inputString.replace(/\s+/g, "");
}

function extractAadhaarNumber(inputString: string) {
  const regex = /\d{12}/;
  const match = inputString.match(regex);
  if (match) {
    return match[0];
  }
  return "invalid";
}

function extractPanNumber(inputString: string): string | null {
  const regex = /[A-Z]{5}\d{4}[A-Z]/i;
  const match = inputString.match(regex);
  if (match) {
    return match[0];
  }
  return "invalid";
}

const processImage = async (
  file: any,
  language: string,
  onProgress: (progress: number) => void,
  onComplete: (text: string | null) => void,
  onError?: (error: any) => void
) => {
  if (!file) {
    onComplete(null); // Handle cases where no file is provided
    return;
  }

  try {
    const {
      data: { text },
    } = await Tesseract.recognize(file, language, {
      logger: (m) => {
        if (m.status === "recognizing text" && m.progress !== undefined) {
          onProgress(m.progress);
        }
      },
    });

    onComplete(text);
  } catch (error) {
    console.error("Error recognizing image:", error);
    if (onError) onError(error);
    onComplete(null); // Optionally handle errors by returning null text
  }
};

export { removeSpaces, extractAadhaarNumber, processImage, extractPanNumber };
