import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CommonSliceState } from "../../utils/types";
import { postAgentSignup } from "../../utils/apis";

const initialState: CommonSliceState = {
  data: null,
  loading: false,
  error: null,
  status: 0,
};

export const handleAgentSignUp = createAsyncThunk(
  "postAgentSingup",
  async (data: any) => {
    const response = await postAgentSignup(data);
    return response;
  }
);

const agentSlice = createSlice({
  name: "postRegistraion",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleAgentSignUp.pending, (state) => {
        state.loading = true;
        state.error = null;
        console.log("pending");
      })
      .addCase(handleAgentSignUp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(handleAgentSignUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "postRegistraion failed";
        console.log("rejected");
      });
  },
});

export default agentSlice.reducer;
