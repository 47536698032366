import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GradualScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const gradualScroll = () => {
      const start = window.scrollY;
      const distance = 0 - start;
      let startTime: number | null = null;

      const easeInOut = (time: number) => {
        const timeFraction = (time - startTime!) / 1000; // duration of 1000ms
        const progress = Math.min(timeFraction, 1);
        const ease = progress * (2 - progress); // ease-in-out

        window.scrollTo(0, start + distance * ease);

        if (progress < 1) {
          requestAnimationFrame(easeInOut);
        }
      };

      requestAnimationFrame((time) => {
        startTime = time;
        requestAnimationFrame(easeInOut);
      });
    };

    gradualScroll(); // Trigger gradual scroll to top on location change
  }, [location]);

  return null;
};

export default GradualScrollToTop;
