import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/authSlice";
import documentReviewStatusReducer from "../redux/disbursement/documentReviewStatusSlice";
import disbursementReducer from "../redux/disbursement/disbursementSlice";
import xTanentIdSlice from "../redux/XTanentIdSlice";
import loanDetailsReducer from "../redux/user/LoanDetailsSlice";
import docReviewStatusReducer from "../redux/DocumentUpload/DocumentUploadReviewSlice";
import profileSliceReducer from "../redux/Profile/profileSlice";
import DocumentUploadReducer from "../redux/DocumentUpload/DocumentUploadSlice";
import registrationReducer from "../redux/registration/registrationSlice";
import registrationFormDataSlice from "../redux/registration/registrationFormDataSlice";
import OcrDocumentReducer from "../redux/DocumentUpload/ocrSlice";
import agentReducer from "../redux/Agent/agentSignUpSlice"

const store = configureStore({
  reducer: {
    auth: authReducer,
    documentReview: documentReviewStatusReducer,
    disbursement: disbursementReducer,
    xTanentId: xTanentIdSlice,
    loan: loanDetailsReducer,
    documentReviewStatus: docReviewStatusReducer,
    profile: profileSliceReducer,
    documentUpload: DocumentUploadReducer,
    registrationSlice: registrationReducer,
    registrationFormData: registrationFormDataSlice,
    OcrDocument: OcrDocumentReducer,
    agent: agentReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
