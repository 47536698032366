import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { validationSchema } from "../../components/uploadDocumentForm/uploadDocumentValidation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackIcon } from "../../assets/icons/back-arrow.svg";
import Banner from "../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import {
  getUserFromLocalStorage,
  removeTokenFromLocalStorage,
  removeUserFromLocalStorage,
} from "../../utils/localStorageUtil";
import { handleBeforeUnload } from "../../utils/getIdFromUrl";
import { logoutAdmin } from "../../redux/authSlice";
import {
  DocumentUpload,
  setIsIfscCodeVerified,
  setIsUpiVerified,
} from "../../redux/DocumentUpload/DocumentUploadSlice";
import { AppDispatch, RootState } from "../../utils/store";
import LogoutPopup from "../../components/LogoutPopUp";
import { FileUpload } from "../../components/uploadDocumentForm/FileUpload";
import { verifyIFSC, verifyUpi } from "../../utils/verificationApis";
import { FiCheckCircle } from "react-icons/fi";

const DocumentsUploadForm: React.FC = () => {
  const [logout, setLogout] = useState(false);
  const [isTypeSelfEmployee, setIsTypeSelfEmployee] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isIfscLoader, setIsIfscLoader] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isUpiIdLoader, setIsUpiIdLoader] = useState(false);
  const [autoPopulateBankName, setAutoPopulateBankName] = useState<string>("");
  const [upiName, setUpiName] = useState<string>("");

  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customerId");

  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch<AppDispatch>();
  const { data, loading, isIfscCodeVerified, isUpiVerified } = useSelector(
    (state: RootState) => state?.documentUpload
  );
  const borrowerData = useSelector(
    (state: RootState) => state?.profile?.data?.borrowerData
  );
  const { empType } = useSelector(
    (state: RootState) => state?.registrationSlice
  );

  const {
    AadharOcrNumber,
    PanOcrNumber,
    previewsAadharNumber,
    previewsPanNumber,
  } = useSelector((state: RootState) => state.OcrDocument);
  const fileUploadMessageForImage =
    "Select Image File to Upload (size upto 2MB)";
  const fileUploadMessageForPDF = "Select PDF File to Upload (size upto 2MB)";

  const notify = (message: string) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleVerifyUpi = async (upiId: string) => {
    // alert(upiId);
    setIsUpiIdLoader(true);
    try {
      if (upiId) {
        const res = await verifyUpi("upiInformation", upiId);
        if (res?.code === 200) {
          setIsUpiIdLoader(false);
          toast.success("Verified Successfully");
          formik.setFieldValue("upiIdKycStatus", "verified");
          console.log("verifyIFSC", res?.result?.response?.name);
          setUpiName(res?.result?.name);
          dispatch(setIsUpiVerified(true));
        }
      }
    } catch (error) {
      console.log("handleVerifyIfsc function:", error);
      formik.setFieldValue("upiIdKycStatus", "");
      setIsUpiIdLoader(false);
      toast.error("Something went wrong in UPI Verification");
    }
  };
  const UpiRes = {
    code: 200,
    result: {
      request_id: "ea4a24de-3c64-4562-8df7-f7a7ee247584",
      vpa: "9599667779@upi",
      vpa_handle: "@upi",
      bank_name: "Bhim UPI NPCI",
    },
  };

  const handleVerifyIfsc = async (ifscCode: string) => {
    setIsIfscLoader(true);
    try {
      if (ifscCode) {
        const res = await verifyIFSC("ifscCodeInformation", ifscCode);
        if (res?.code === 200) {
          setIsIfscLoader(false);
          toast.success("Verified Successfully");
          console.log("verifyIFSC", res?.result?.response?.name);
          setAutoPopulateBankName(res?.result?.response?.name);
          dispatch(setIsIfscCodeVerified(true));
        }
      }
    } catch (error) {
      console.log("handleVerifyIfsc function:", error);
      setIsIfscLoader(false);
      toast.error("Something went wrong in IFSC Verification");
    }
  };

  const handleSubmit = async (values: any) => {
    const payload = new FormData();
    const borrowerId = getUserFromLocalStorage()?.borrowerId;
    const roleType = getUserFromLocalStorage()?.role;
    payload.append("borrowerId", customerId || borrowerId);
    payload.append("upiId", values.upiId);
    payload.append("bankIfscCode", values.bankIfscCode);
    payload.append("bankAccountNumber", values.bankAccountNumber);
    payload.append("bankAccountName", values.bankAccountName);
    payload.append("aadharCard", values.aadharCard);
    payload.append("panCard", values.panCard);
    payload.append("profilePhoto", values.profilePhoto);
    payload.append("cancelledCheque", values.cancelledCheque);
    payload.append("bankStatement", values.bankStatement);
    isTypeSelfEmployee &&
      payload.append("gstCertificate", values.gstCertificate);
    isTypeSelfEmployee && payload.append("ItReturn", values.ItReturn);
    !isTypeSelfEmployee && payload.append("form16", values.form16);
    payload.append("bankIfscStatus", values.bankIfscStatus);
    payload.append("upiIdKycStatus", values.upiIdKycStatus);

    dispatch(DocumentUpload(payload));
  };

  useEffect(() => {
    if (data?.status === "success") {
      toast.success(data?.message);
      if (location.state.applicationType === "Agent") {
        navigate("/agent-dashboard");
      } else {
        removeUserFromLocalStorage();
        removeTokenFromLocalStorage();
        dispatch(logoutAdmin());
        setTimeout(() => navigate("/"), 2000);
      }
    } else {
      notify(data?.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (location?.state?.routeFrom === "registration") {
      setIsTypeSelfEmployee(location?.state?.type === "Self-Employed");
    } else {
      setIsTypeSelfEmployee(borrowerData?.occupation?.type === "Self-Employed");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const employeeDoc = {
    upiId: "",
    bankIfscCode: "",
    bankAccountNumber: "",
    bankAccountName: "",
    OcrAadharNum: "",
    storedAadharNum: "",
    OcrPanNum: "",
    storedPanNum: "",
    aadharCard: null,
    panCard: null,
    profilePhoto: null,
    cancelledCheque: null,
    bankStatement: null,
    form16: null,
    bankIfscStatus: "",
    upiIdKycStatus: "",
  };

  const selfEmployeeDoc = {
    upiId: "",
    bankIfscCode: "",
    bankAccountNumber: "",
    bankAccountName: "",
    OcrAadharNum: "",
    storedAadharNum: "",
    OcrPanNum: "",
    storedPanNum: "",
    aadharCard: null,
    panCard: null,
    profilePhoto: null,
    cancelledCheque: null,
    bankStatement: null,
    gstCertificate: null,
    ItReturn: null,
    bankIfscStatus: "",
    upiIdKycStatus: "",
  };

  const formik = useFormik({
    initialValues: empType === "Employed" ? employeeDoc : selfEmployeeDoc,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const getErrorMessage = (fieldName: string) => {
    return (
      formik.touched[fieldName as keyof typeof formik.values] &&
      formik.errors[fieldName as keyof typeof formik.errors] && (
        <span className="error  left-0 top-8 w-full text-red-500 text-sm">
          {formik.errors[fieldName as keyof typeof formik.errors]}
        </span>
      )
    );
  };

  const handleLogout = (e: any) => {
    e.preventDefault();
    setLogout(true);
  };

  function onConfirm() {
    removeUserFromLocalStorage();
    dispatch(logoutAdmin());
    setLogout(false);
    navigate("/login");
  }

  function onCancel() {
    setLogout(false);
  }

  const handleSetPreviewAadhar = () => {
    formik.setFieldValue("storedAadharNum", previewsAadharNumber);
  };

  const handleSetPreviewPan = () => {
    formik.setFieldValue("storedPanNum", previewsPanNumber);
  };

  const handleImageChange = (e: any, inputLabel: string) => {
    formik.setFieldValue(inputLabel, e.target.files?.[0] || null);
  };

  const handleOcrAadhar = () => {
    formik.setFieldValue("OcrAadharNum", AadharOcrNumber);
  };

  const handleOcrPan = () => {
    formik.setFieldValue("OcrPanNum", PanOcrNumber);
  };

  useEffect(() => {
    handleSetPreviewAadhar();
    handleSetPreviewPan();
    handleOcrAadhar();
    handleOcrPan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AadharOcrNumber, PanOcrNumber]);
  useEffect(() => {
    formik.setFieldValue("bankAccountName", autoPopulateBankName);
  }, [autoPopulateBankName]);
  useEffect(() => {
    if (isIfscCodeVerified) {
      formik.setFieldValue("bankIfscStatus", "verified");
    } else {
      formik.setFieldValue("bankIfscStatus", "");
    }
    if (isUpiVerified) {
      formik.setFieldValue("upiIdKycStatus", "verified");
    } else {
      formik.setFieldValue("upiIdKycStatus", "");
    }
  }, [isIfscCodeVerified, isUpiVerified]);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            backgroundImage: `url(${Banner})`,
          }}
          className="w-full min-h-screen bg-gray-500 flex justify-center items-center"
        >
          <div className="w-11/12 md:w-9/12 lg:w-8/12 xl:w-7/12 2xl:w-5/12 my-5 bg-no-repeat bg-cover bg-white flex justify-center rounded-2xl py-8">
            <div className="w-5/6 flex justify-center flex-col items-center">
              <div className="flex flex-col sm:flex-row justify-between w-full mb-6 pb-2 border-gray-300 border-b-[1px]">
                <button
                  onClick={(e) => handleLogout(e)}
                  className="flex items-center gap-1 mb-2 md:mb-0"
                >
                  <BackIcon fill="#707070" className="w-6 h-6 md:w-8 md:h-8" />
                  <span className="font-semibold text-base md:text-xl text-green-600 hover:text-green-900">
                    Logout
                  </span>
                </button>
                <span className="text-center md:text-left xl:text-2xl md:text-xl text-lg font-semibold">
                  Upload Document Form
                </span>
              </div>
              <div className="w-full mb-1">
                <div className="flex justify-between">
                  <label
                    htmlFor="aadharCard"
                    className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                  >
                    Aadhar Card
                  </label>
                  <span className="text-neutral-400 text-[10px] md:text-sm">
                    {fileUploadMessageForImage}
                  </span>
                </div>
                <FileUpload
                  handleImageChange={handleImageChange}
                  title="Upload Your Aadhar Card"
                  inputLabel="aadharCard"
                />
                {formik.touched.aadharCard && formik.errors.aadharCard && (
                  <span className="text-red-500 text-[10px] error">
                    {formik.errors.aadharCard}
                  </span>
                )}
                {getErrorMessage("OcrAadharNum")}
              </div>
              <div className="w-full mb-1">
                <div className="flex justify-between">
                  <label
                    htmlFor="panCard"
                    className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                  >
                    Pan Card
                  </label>
                  <span className="text-neutral-400 text-[10px] md:text-sm">
                    {fileUploadMessageForImage}
                  </span>
                </div>
                <FileUpload
                  handleImageChange={handleImageChange}
                  title="Upload Your Pan Card"
                  inputLabel="panCard"
                />
                {formik.touched.panCard && formik.errors.panCard && (
                  <span className="text-red-500 text-[10px] error">
                    {formik.errors.panCard}
                  </span>
                )}
                {getErrorMessage("OcrPanNum")}
              </div>
              <div className="w-full mb-1">
                <div className="flex justify-between">
                  <label
                    htmlFor="profilePhoto"
                    className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                  >
                    Profile Picture
                  </label>
                  <span className="text-neutral-400 text-[10px] md:text-sm">
                    {fileUploadMessageForImage}
                  </span>
                </div>
                <FileUpload
                  handleImageChange={handleImageChange}
                  title="Upload a Photo"
                  inputLabel="profilePhoto"
                />
                {formik.touched.profilePhoto && formik.errors.profilePhoto && (
                  <span className="text-red-500 text-[10px] error">
                    {formik.errors.profilePhoto}
                  </span>
                )}
                <div className="flex justify-between">
                  <label
                    htmlFor="cancelledCheque"
                    className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                  >
                    Cancelled Cheque
                  </label>
                  <span className="text-neutral-400 text-[10px] md:text-sm">
                    {fileUploadMessageForImage}
                  </span>
                </div>
                <FileUpload
                  handleImageChange={handleImageChange}
                  title="Upload Cancelled Cheque"
                  inputLabel="cancelledCheque"
                />

                <p>{getErrorMessage("cancelledCheque")}</p>
                <div className="flex justify-between">
                  <label
                    htmlFor="bankStatement"
                    className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                  >
                    Bank Statement
                  </label>
                  <span className="text-neutral-400 text-[10px] md:text-sm">
                    {fileUploadMessageForPDF}
                  </span>
                </div>
                <FileUpload
                  handleImageChange={handleImageChange}
                  title="Upload Bank Statement"
                  inputLabel="bankStatement"
                />

                <p>{getErrorMessage("bankStatement")}</p>
                {isTypeSelfEmployee && (
                  <>
                    <div className="flex justify-between">
                      <label
                        htmlFor="gstCertificate"
                        className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                      >
                        GST Certificate
                      </label>
                      <span className="text-neutral-400 text-[10px] md:text-sm">
                        {fileUploadMessageForPDF}
                      </span>
                    </div>
                    <FileUpload
                      handleImageChange={handleImageChange}
                      title="Upload GST Certificate"
                      inputLabel="gstCertificate"
                    />

                    <p>{getErrorMessage("gstCertificate")}</p>
                    <div className="flex justify-between">
                      <label
                        htmlFor="ItReturn"
                        className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                      >
                        IT Return
                      </label>
                      <span className="text-neutral-400 text-[10px] md:text-sm">
                        {fileUploadMessageForPDF}
                      </span>
                    </div>
                    <FileUpload
                      handleImageChange={handleImageChange}
                      title="Upload IT Return"
                      inputLabel="ItReturn"
                    />

                    <p>{getErrorMessage("ItReturn")}</p>
                    <span className="text-neutral-400 text-[10px] md:text-sm "></span>
                  </>
                )}

                {!isTypeSelfEmployee && (
                  <>
                    <div className="flex justify-between">
                      <label
                        htmlFor="gstCertificate"
                        className="text-gray-400 text-[10px] md:text-sm font-semibold mb-0  mt-1 "
                      >
                        Form 16
                      </label>
                      <span className="text-neutral-400 text-[10px] md:text-sm">
                        {fileUploadMessageForPDF}
                      </span>
                    </div>
                    <FileUpload
                      handleImageChange={handleImageChange}
                      title="Upload Form 16"
                      inputLabel="form16"
                    />
                    <p>{getErrorMessage("form16")}</p>
                    <span className="text-neutral-400 text-[10px] md:text-sm">
                      {/* {fileUploadMessageForImage} */}
                    </span>
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-x-4 gap-y-2 my-4">
                <div className="relative w-full">
                  <input
                    type="text"
                    name="upiId"
                    value={formik.values.upiId}
                    onChange={formik.handleChange}
                    placeholder="UPI ID"
                    className="w-full rounded-lg border"
                  />
                  {getErrorMessage("upiId") || (
                    <>
                      {upiName ? (
                        <span className=" w-[500px] left-0 top-8  text-green-500 text-sm">
                          {upiName}
                        </span>
                      ) : (
                        <span className="text-neutral-400 text-[12px]  left-0 top-10 w-full my-1">
                          70532399@indianonlinepay
                        </span>
                      )}
                    </>
                  )}
                  <div className="verifyBtnBox flex justify-center lg:block absolute right-[5px] left-auto top-1">
                    {isUpiVerified ? (
                      <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                    ) : (
                      <button
                        onClick={() => handleVerifyUpi(formik.values.upiId)}
                        type="button"
                        className="customBtn btn-dark text-lg"
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    name="bankAccountNumber"
                    value={formik.values.bankAccountNumber}
                    onChange={formik.handleChange}
                    className="w-full  rounded-lg border"
                    placeholder="Bank Account Number"
                  />
                  {getErrorMessage("bankAccountNumber")}
                  <div className="verifyBtnBox flex justify-center lg:block absolute right-[5px] left-auto top-1"></div>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    name="bankIfscCode"
                    value={formik.values.bankIfscCode}
                    onChange={formik.handleChange}
                    placeholder="IFSC Code"
                    className="w-full rounded-lg border"
                  />
                  {getErrorMessage("bankIfscCode")}
                  <div className="verifyBtnBox flex justify-center lg:block absolute right-[5px] left-auto top-1">
                    {isIfscCodeVerified ? (
                      <FiCheckCircle className="text-green-600 text-[1.2rem] mx-[6px] my-[6px]" />
                    ) : (
                      <button
                        type="button"
                        onClick={() =>
                          handleVerifyIfsc(formik?.values?.bankIfscCode)
                        }
                        className="customBtn btn-dark text-lg"
                      >
                        Verify
                      </button>
                    )}
                  </div>
                </div>
                <div className="relative w-full">
                  <input
                    type="text"
                    name="bankAccountName"
                    value={
                      formik?.values?.bankAccountName || autoPopulateBankName
                    }
                    onChange={formik.handleChange}
                    className="w-full rounded-lg border bg-slate-200"
                    placeholder="Bank Account Name"
                    disabled
                  />
                  {getErrorMessage("bankAccountName")}
                  <div className="verifyBtnBox flex justify-center lg:block absolute right-[5px] left-auto top-1"></div>
                </div>
              </div>
              {/* <div className="relative mb-1 w-full">
                <input
                  type="text"
                  name="OcrAadharNum"
                  value={formik.values.OcrAadharNum}
                  // onChange={formik.handleChange}
                  onChange={handleOcrAadhar}
                  onBlur={handleOcrAadhar}
                  className="w-full"
                  placeholder="OcrAadharNum"
                />
                {getErrorMessage("OcrAadharNum")}
                <div className="verifyBtnBox flex justify-center lg:block absolute right-[5px] left-auto top-1"></div>
              </div> */}
              {!loading ? (
                <button
                  type="submit"
                  className="w-60 py-2 bg-[#3C8F47] text-white rounded-lg font-medium hover:bg-green-700 transition mt-2"
                >
                  Submit
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-60 py-2 bg-[#3C8F47] text-white rounded-lg font-medium hover:bg-green-700 transition mt-2"
                >
                  <Loader />
                </button>
              )}
            </div>
          </div>
        </div>
        {logout ? (
          <LogoutPopup onConfirm={onConfirm} onCancel={onCancel} />
        ) : null}
        <ToastContainer autoClose={2000} />
      </form>
    </div>
  );
};

export default DocumentsUploadForm;
