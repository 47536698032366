import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setXTanentId } from "../redux/XTanentIdSlice";

const Demo: React.FC = () => {
  const [id, setId] = useState<string | null>(null);
  const dispatch = useDispatch();
  //   setXTanentId

  useEffect(() => {
    const url =
      "https://jsonplaceholder.typicode.com/api/v1/posts/33db046f-add9-4b03-9a52-ce906b93e0e5";
    const extractedId = getIdFromUrl(url);
    setId(extractedId);
    console.log("extractedId :", extractedId);
    if (extractedId) {
      dispatch(setXTanentId(extractedId));
    }
  }, []);

  const getIdFromUrl = (url: string): string | null => {
    try {
      const urlParts = url.split("/");
      return urlParts[urlParts.length - 1];
    } catch (error) {
      console.error("Error extracting ID from URL:", error);
      return null;
    }
  };

  return (
    <div>
      <h1>Demo</h1>
    </div>
  );
};

export default Demo;
