// localStorageUtil.js

// Save user data or token to localStorage
export const saveUserToLocalStorage = (user:any) => {
    try {
      localStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
      console.error('Error saving user to localStorage', error);
    }
  };
  
  // Get user data from localStorage
  export const getUserFromLocalStorage = () => {
    try {
      const user = localStorage.getItem('user');
      return user ? JSON.parse(user) : null;
    } catch (error) {
      console.error('Error getting user from localStorage', error);
      return null;
    }
  };
  
  // Remove user data from localStorage (logout)
  export const removeUserFromLocalStorage = () => {
    try {
      localStorage.removeItem('user');
    } catch (error) {
      console.error('Error removing user from localStorage', error);
    }
  };
  export const removeTokenFromLocalStorage = () => {
    try {
      localStorage.removeItem('token');
    } catch (error) {
      console.error('Error removing user from localStorage', error);
    }
  };
  // Check if user is logged in by verifying if 'user' exists in localStorage
  export const isUserLoggedIn = () => {
    return !!getUserFromLocalStorage();
  };
  
//   export const clearLocalStorage = () => {
//     try {
//       localStorage.clear();
//     } catch (error) {
//       console.error('Error clearing localStorage', error);
//     }
//   };
  