import { Undo } from "lucide-react";
import BANNERBG from "../../assets/inner-banners/knowledge-centerBG.jpg";
import WHYLENDWITHUS from "../../assets/investor-platform/why-lend-with-us.png";
import MONEYPLATFORM from "../../assets/investor-platform/money-platform.png";
import LENDERS from "../../assets/investor-platform/lenders.png";
import BORROWERS from "../../assets/investor-platform/borrowers.png";
import LENDINGPLATFORM from "../../assets/investor-platform/lending_platform.png";
import CREATEIMG from "../../assets/investor-platform/create-an-individual.png";
import INVESTIMG from "../../assets/investor-platform/invest-in-a-loan.png";
import INVESTORIMG from "../../assets/investor-platform/investor-transfer.png";
import LOANGETIMG from "../../assets/investor-platform/loan-gets-funded.png";
import PHYSICALIMG from "../../assets/investor-platform/physical-verification.png";
import RECEIVEIMG from "../../assets/investor-platform/receive-monthly.png";
import REGISTERIMG from "../../assets/investor-platform/register.png";
import INVESTORWALLETIMG from "../../assets/investor-platform/investor-wallet.png";
import HOWAREWEDIFFERENT from "../../assets/investor-platform/how-are-we-different.png";
import Container from "../../components/ui/Container";
import InnerBanner from "../../components/ui/InnerBanner";

const KnowledgeCenter = () => {
  return (
    <>
      <InnerBanner img={BANNERBG} alt={"knowledge center"}>
        <h4 className="text-xl md:text-3xl lg:text-5xl text-center md-3 lg:mb-6 text-white text-shadow">
          <span className="text-green-600 font-bold">Knowledge</span> Center
        </h4>
      </InnerBanner>
      <section className="bg-green-100 py-12">
        <Container>
          <div className="relative py-8">
            <div className="bg-white p-8 rounded-lg w-full md:w-[60%] md:pr-24">
              <h4 className="text-5xl mb-6">
                <span className="text-green-600 font-bold">Why Lend</span> with
                Us?
              </h4>
              <div className="space-y-2 text-justify text-sm">
                <p>
                  In today&quot;s financial environment where investments seldom
                  generate expected returns and most of our savings placed with
                  institutions like banks go into paying service charges to
                  them, Asha Purti Loans offers a unique opportunity to lend
                  money directly to pre-verified borrowers and earn extra income
                  on idle money.
                </p>
                <p>
                  The Asha Purti Loans model provides better rates of interest
                  than the traditional model because it removes the
                  intermediaries and connects the lenders directly to the
                  end-users - the borrowers.
                </p>
                <p>
                  It is a simple win-win situation so go ahead SIGN UP and start
                  lending because every percentage counts!
                </p>
              </div>
            </div>
            <div className="relative -translate-x-1 md:absolute md:right-0 md:top-1/2 md:-translate-y-1/2 md:w-[45%] z-[1] rounded-lg overflow-hidden mb-4">
              <img
                src={WHYLENDWITHUS}
                alt="why lend with us"
                width={600}
                height={450}
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="py-12">
        <Container>
          <div className="relative py-8">
            <div className="bg-slate-100 p-8 rounded-lg w-[60%] pl-24 ml-auto">
              <h4 className="text-5xl mb-6">
                <span className="text-green-600 font-bold">
                  Asha Purthi Call
                </span>{" "}
                Money Platform
              </h4>
              <div className="space-y-2 text-justify text-sm">
                <p>
                  Traditionally, banks and other financial institutions collect
                  money (through savings accounts or deposits like fixed or
                  recurring) at low-interest rates (say 6-10%) and lend it to
                  borrowers at much higher rates (18-36%). This margin goes into
                  paying for the high costs they incur like thousands of
                  employees to pay salaries to and hundreds of swanky branches
                  to maintain and so on. Asha Purti Loans brings borrowers and
                  lenders directly in touch with each other, removes the
                  intermediary cost, enables borrowers to access faster and
                  cheaper credit, and enables lenders to make greater returns on
                  funds lying idle in the bank deposits.
                </p>
              </div>
            </div>
            <div className="absolute left-0 top-1/2 -translate-y-1/2 w-[45%] z-[1] rounded-lg overflow-hidden mb-4">
              <img
                src={MONEYPLATFORM}
                alt="why lend with us"
                width={600}
                height={450}
              />
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-slate-100 py-12">
        <Container>
          <h4 className="text-5xl mb-6 text-center">
            <span className="text-green-600 font-bold">Loan</span> Agreement
          </h4>
          <div className="flex gap-12 justify-center items-center flex-wrap text-center">
            <div className="w-[45%] relative">
              <div className="rounded-lg overflow-hidden">
                <img
                  src={LENDERS}
                  alt="why lend with us"
                  width={600}
                  height={450}
                />
              </div>
              <div className="relative px-12">
                <div className="bg-slate-800 -translate-y-8 rounded-lg p-4 text-white space-y-2">
                  <h4 className="text-2xl font-bold uppercase">Lenders</h4>
                  <h5>(Loan Offer)</h5>
                  <p className="text-sm">
                    Platform Fees & Charges
                    <br /> (Depending on the Business Model)
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[45%] order-3 relative">
              <div className="absolute rotate-[225deg] -left-1/2">
                <Undo size={120} strokeWidth={1} />
              </div>
              <div className="absolute rotate-[90deg] -right-1/2">
                <Undo size={120} strokeWidth={1} />
              </div>
              <div className="rounded-lg overflow-hidden">
                <img
                  src={LENDINGPLATFORM}
                  alt="why lend with us"
                  width={600}
                  height={450}
                />
              </div>
              <div className="relative px-12">
                <div className="bg-slate-800 -translate-y-8 rounded-lg p-4 text-white space-y-2">
                  <h4 className="text-2xl font-bold uppercase">
                    Landing Platform
                  </h4>
                  <h5>(Loan Offer)</h5>
                  <p className="text-sm">
                    (User profile, Loan Calculator, Transaction Processing, Data
                    Analytics, Auto-invest, Secondary Market Offering, Payments,
                    ISA/IFA Support)
                  </p>
                </div>
              </div>
            </div>
            <div className="w-[45%] order-2">
              <div className="rounded-lg overflow-hidden">
                <img
                  src={BORROWERS}
                  alt="why lend with us"
                  width={600}
                  height={450}
                />
              </div>
              <div className="relative px-12">
                <div className="bg-slate-800 -translate-y-8 rounded-lg p-4 text-white space-y-2">
                  <h4 className="text-2xl font-bold uppercase">Borrowers</h4>
                  <h5>(Loan Demand)</h5>
                  <p className="text-sm">
                    Platform Fees & Charges <br /> (Depending on the Business
                    Model)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-12">
        <Container>
          <div className="grid grid-cols-3 gap-10">
            <div className="flex flex-col gap-10 order-2">
              <div>
                <h4 className="text-5xl mb-6 text-center">
                  <span className="text-green-600 font-bold">How does</span>{" "}
                  this work?
                </h4>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={REGISTERIMG}
                  width={350}
                  height={350}
                  alt="Register at Asha Purti Loans for Free"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Register at Asha Purti Loans for Free
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">
                    Register at Asha Purti Loans for Free
                  </div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={LOANGETIMG}
                  width={350}
                  height={350}
                  alt="Loan Gets Funded"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Loan Gets Funded
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">Loan Gets Funded</div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-10 order-1">
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={CREATEIMG}
                  width={350}
                  height={350}
                  alt="Create an Individual Investor Account"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Create an Individual Investor Account
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">
                    Create an Individual Investor Account
                  </div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={INVESTIMG}
                  width={350}
                  height={350}
                  alt="Invest in a Loan"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Invest in a Loan
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">Invest in a Loan</div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={INVESTORIMG}
                  width={350}
                  height={350}
                  alt="Investor - Transfer of Finances"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Investor - Transfer of Finances
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">Investor - Transfer of Finances</div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-10 order-3">
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={INVESTORWALLETIMG}
                  width={350}
                  height={350}
                  alt="Investor's Wallet"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Investor&apos;s Wallet
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">Investor&apos;s Wallet</div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={PHYSICALIMG}
                  width={350}
                  height={350}
                  alt="Physical Verification and Signing of Legal ContracT"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Physical Verification and Signing of Legal Contract
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">
                    Physical Verification and Signing of Legal Contract
                  </div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
              <div className="overflow-hidden relative rounded-lg group">
                <img
                  src={RECEIVEIMG}
                  width={350}
                  height={350}
                  alt="Receive Monthly Payments - Keep or Reinvest"
                  className="scale-110 group-hover:scale-100 duration-500"
                />
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/80 p-4 group-hover:hidden">
                  Receive Monthly Payments - Keep or Reinvest
                </div>
                <div className="absolute left-0 bottom-0 text-white text-center w-full bg-black/75 h-full translate-y-full group-hover:translate-y-0 duration-500">
                  <div className="p-4">
                    Receive Monthly Payments - Keep or Reinvest
                  </div>
                  <div className="text-sm p-4">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Minus, minima delectus laborum tenetur sunt repellendus
                    soluta deleniti nesciunt ullam aliquam consequatur quis quo
                    quisquam, necessitatibus deserunt totam enim? Officia,
                    maiores!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="py-12 bg-slate-100">
        <Container>
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h4 className="text-5xl mb-6 text-center">
                <span className="text-green-600 font-bold">How Are We</span>{" "}
                Different?
              </h4>
              <div className="space-y-2 text-justify text-sm">
                <p>
                  Asha Purti Call Money Platform (APCMP) sets itself apart by
                  being complete honest and impartial. While similar to P2P
                  platforms, we take full responsibility for our investors&quot;
                  and partners&quot; investments. In case of a bad loan or
                  non-performing asset, we reimburse the remaining capital,
                  excluding interest paid.
                </p>
                <p>
                  Our automated lending and borrowing process, supported by
                  custom software, ensures seamless functionality. The platform
                  integrates with our Loan Originating System and Loan
                  Management System, updating available amounts on the
                  Dashboard. It automatically distributes interest between
                  borrowers and lenders.
                </p>
              </div>
            </div>
            <div>
              <img
                src={HOWAREWEDIFFERENT}
                alt="how are we different?"
                width={600}
                height={450}
                className="rounded-xl"
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};
export default KnowledgeCenter;
