import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../utils/store";
import { getReceipt } from "../../redux/disbursement/documentReviewStatusSlice";
import { getPaymentStatus } from "../../utils/apis";

function Success() {
  // const [searchParams] = useSearchParams();
  // const invoiceId = searchParams.get("invoiceId");
  // const dispatch = useDispatch<AppDispatch>();
  // const { data } = useSelector(
  //   (state: RootState) => state.documentReviewStatus
  // );

  // const [txnID, setTxnID] = useState("");
  // const [status, setStatus] = useState("");
  // const [utrNo, setUtrNo] = useState("");

  // useEffect(() => {
  //   dispatch(getReceipt());
  //   getPaymentStatus(invoiceId)
  //     .then(async (response) => {
  //       setTxnID(response.data.txn_id);
  //       setStatus(response.data.status);
  //       setUtrNo(response.data.rrn);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching transaction status:", error);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="payEmi-wrapper">
      <div className="w-full h-full min-h-dvh flex justify-center items-center py-10 md:pr-30 relative">
        <div className="py-8 px-0 md:px-6 w-full md:w-[650px] bg-[#f1f5f9] bg-opacity-90 min-h-[300px] rounded-lg backdrop-blur-xl backdrop-filter flex items-center justify-center flex-col shadow-shadow_1">
          <p className="text-4xl font-bold pl-2 text-green-700 mb-4">
            {/* PAYMENT {status || "SUCCESS"} */}
            PAYMENT SUCCESS
          </p>
          {/* <p className="font-semibold text-lg mb-2">
            Transaction ID:{" "}
            <span className="text-base font-medium pl-2 text-gray-700">
              {txnID}
            </span>
          </p>
          <p className="font-semibold text-lg mb-6">
            UTR No:{" "}
            <span className="text-base font-medium pl-2 text-gray-700">
              {utrNo}
            </span>
          </p>
          <a href={data?.filePath} target="_blank" rel="noopener noreferrer">
            <button className="px-4 py-1 text-gray-500 border-solid border-[1px] border-gray-400 rounded-lg hover:bg-green-600 hover:text-white hover:border-green-600 shadow-shadow1">
              Get Receipt
            </button>
          </a> */}
          <p className="font-medium text-md mt-4">
            You can close this window now.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Success;
