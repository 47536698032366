import { useSelector } from "react-redux";
import { RootState } from "../../utils/store";
import { confirmProp } from "../../utils/types";
import { FC } from "react";

const Confirmation: FC<confirmProp> = ({ source }) => {
  const { personalInfo, occupation } = useSelector(
    source === "Register"
      ? (state: RootState) => state?.registrationFormData
      : (state: RootState) => state?.profile.data?.borrowerData
  );
  const { empSubType, empType } = useSelector(
    (state: RootState) => state.registrationSlice
  );

  return (
    <div className="confirmation space-y-4 min-h-[545px]">
      {!personalInfo ? (
        <div>Loading...</div>
      ) : (
        <div className="confirmation_details ">
          <h4>Personal Information</h4>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 border border-gray-300">
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Full Name:
              <span className="block lg:inline pl-0 lg:pl-3 font-medium capitalize">
                {personalInfo?.fullName}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Mobile:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.mobileNumber}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Date of Birth:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.birthDate}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Email:
              <span className="pl-3 font-medium">{personalInfo?.email}</span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              PAN Card:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.panCardNumber}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Aadhar Card:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.aadhaarNumber}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Address:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.address}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              City:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.city}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              State:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.state}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Pin Code:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.pincode}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Gender:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.gender || "Female"}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Marital Status:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.maritalStatus || "Married"}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Loan Type:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.loanTypeId === 1 ||
                personalInfo?.loanTypeId.loanTypeId === 1
                  ? "Personal Loan"
                  : personalInfo?.loanTypeId === 2 ||
                    personalInfo?.loanTypeId.loanTypeId === 2
                  ? "Gold Loan"
                  : ""}
              </span>
            </p>
            <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
              Loan Amount:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.loanAmount}
              </span>
            </p>
            <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
              Tenure:
              <span className="pl-3 font-medium capitalize">
                {personalInfo?.loanTenureId === 1 ||
                personalInfo?.loanTenureId.loanTenureId === 1
                  ? "100 days"
                  : personalInfo?.loanTenureId === 2 ||
                    personalInfo?.loanTenureId.loanTenureId === 2
                  ? "200 days"
                  : personalInfo?.loanTenureId === 3 ||
                    personalInfo?.loanTenureId.loanTenureId === 3
                  ? "300 days"
                  : personalInfo?.loanTenureId === 4 ||
                    personalInfo?.loanTenureId.loanTenureId === 4
                  ? "52 weeks"
                  : personalInfo?.loanTenureId === 5 ||
                    personalInfo?.loanTenureId.loanTenureId === 5
                  ? "104 weeks"
                  : ""}
              </span>
            </p>
          </div>
          <div className="confirm-OccInfo mt-4">
            <h4>Occupation Information</h4>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 border border-gray-300 pl-4">
              {empType && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Type:
                  <span className="pl-3 font-medium capitalize">
                    {empType || "Not Specified"}
                  </span>
                </p>
              )}
              {empSubType && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Sub-Type:
                  <span className="pl-3 font-medium capitalize">
                    {empSubType || "Not Specified"}
                  </span>
                </p>
              )}
              {/* Employed Occupation Information Section */}
              {occupation?.nameOfOrganisation && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Name Of Organisation:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.nameOfOrganisation || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.nameOfCompany && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Name Of Company:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.nameOfCompany || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.nameOfBank && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Name Of Bank:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.nameOfBank || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.designation && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Designation:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.designation || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.department && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Department:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.department || "Not Available"}
                  </span>
                </p>
              )}

              {occupation?.yearsInCurrentDesignation && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Years In Current Designation:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.yearsInCurrentDesignation || "0"}
                  </span>
                </p>
              )}

              {occupation?.officePhoneNumber && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Office Phone Number:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.officePhoneNumber || "Unavailable"}
                  </span>
                </p>
              )}
              {occupation?.annualIncome && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Annual Income:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.annualIncome || "Not Disclosed"}
                  </span>
                </p>
              )}
              {occupation?.lastMonthTakeHomeSalary && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Last Month Take Home Salary:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.lastMonthTakeHomeSalary || "Not Applicable"}
                  </span>
                </p>
              )}
              {/* Employed Occupation Information Section end */}
              {/* Self-Employed Occupation Information Section */}
              {occupation?.registeredNumber && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Registered Number:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.registeredNumber || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.specialisation && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Specialisation:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.specialisation || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.gstNumber && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  GST Number:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.gstNumber || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.bussinessName && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Business Name:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.bussinessName || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.typeOfBusiness && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Type Of Business:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.typeOfBusiness || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.yearsInPractise && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Years In Practice:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.yearsInPractise || "0"}
                  </span>
                </p>
              )}
              {occupation?.previousYearITReturn && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Previous Year IT Return:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.previousYearITReturn || "Not Disclosed"}
                  </span>
                </p>
              )}
              {occupation?.amountOfTaxPaid && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Amount Of Tax Paid:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.amountOfTaxPaid || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.bankAccountNumber && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Bank Account Number:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.bankAccountNumber || "Not Available"}
                  </span>
                </p>
              )}
              {occupation?.bankIfsccode && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Bank IFSC Code:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.bankIfsccode || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.occupationAddress && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Office Address:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.occupationAddress || "Not Provided"}
                  </span>
                </p>
              )}

              {occupation?.city && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  City:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.city || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.stateOrCentral && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  State Or Central:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.stateOrCentral || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.pincode && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Pin Code:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.pincode || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.natureOfWork && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Nature Of Work:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.natureOfWork || "Not Specified"}
                  </span>
                </p>
              )}
              {occupation?.yearsOfExperience && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Years Of Experience:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.yearsOfExperience || "0"}
                  </span>
                </p>
              )}
              {occupation?.other && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Other:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.other || "Not Provided"}
                  </span>
                </p>
              )}
              {occupation?.natureOfBusiness && (
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Nature Of Business:
                  <span className="pl-3 font-medium capitalize">
                    {occupation?.natureOfBusiness || "Not Specified"}
                  </span>
                </p>
              )}

              {/* self emplyeed data end here */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Confirmation;
