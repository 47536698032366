import React, { useState, useRef } from "react";
import "../../styles/usersStyles/Dropdown.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { DropdownProps } from "../../utils/types";

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  isSelected,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(
    isSelected !== "" ? isSelected : null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  // Event handler for clicks outside the dropdown
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedOption || "Select an option"} {/* Placeholder text */}
        {isOpen ? (
          <IoIosArrowUp className="arrow" />
        ) : (
          <IoIosArrowDown className="arrow" />
        )}
      </div>
      {isOpen && (
        <div className="dropdown-list">
          {options.map((option: any, index: any) => (
            <div
              key={index}
              className="dropdown-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
