import "../styles/usersStyles/customModal.css";
import { CustomModalProps } from "../utils/types";

const CustomModal: React.FC<CustomModalProps> = ({
  isOpenCustModal,
  onClose,
  children,
  title,
  modalName,
}) => {
  if (!isOpenCustModal) return null;

  return (
    <div
      className="modal-overlay"
      onClick={() => {
        modalName !== "aadharVerificationModal" && onClose();
      }}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        {modalName?.toLowerCase() === "payment" || "terms" ? (
          <>
            <div className="flex modal-header">
              <h2 className="w-11/12 text-xl font-semibold text-green-700">
                {title}
              </h2>
            </div>
            <button className="close-button w-1/12 " onClick={onClose}>
              &times;
            </button>
          </>
        ) : (
          <div className="modal-header relative">
            {title && <h2>{title}</h2>}
            <button className="close-button absolute right-0" onClick={onClose}>
              &times;
            </button>
          </div>
        )}
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default CustomModal;
