import Header from "../../components/Header";
import CrossSell from "../../components/CrossSell";
import RevenueCircle from "../../components/Revenue";
import NewLoans from "../../components/NewLoans";
import YearlyGrowth from "../../components/YearlyGrowth";
import styles from "../../styles/dashboardstyles/Dashboard.module.css";

export default function Dashboard() {
  return (
    <div className={styles.dashboard}>
      <Header name="Dashboard" />
      <div className={styles.grid_container}>
        <NewLoans />
        <CrossSell />
        <RevenueCircle />
        <YearlyGrowth />
      </div>
    </div>
  );
}
