import React from "react";
import Banner from "../../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import Header from "../../../components/Header";

const ViewBorrower = () => {
  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="w-full bg-no-repeat bg-cover py-16 sm:py-24 min-h-screen flex items-center justify-center px-4"
    >
      <Header name="View Borrower" />
      <form className="relative bg-[#e6ecf4] w-full max-w-[1080px] px-8 sm:px-16 py-6 shadow-md">
        <button
          type="button"
          className="absolute top-4 right-4 bg-red-600 text-white rounded-full w-8 h-8 flex text-2xl items-center justify-center shadow-md hover:bg-red-600"
          aria-label="Close"
          onClick={() => window.close()}
        >
          &times;
        </button>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              First Name
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="First Name"
              disabled
            />
          </div>

          {/* <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Middle Name
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none "
              placeholder="Middle Name"
            />
          </div> */}

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Last Name
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Last Name"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Aadhar Card
            </label>
            <div className="flex items-center border-gray-300">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="Aadhar Card"
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Pan Card
            </label>
            <div className="flex items-center border-gray-300">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="Pan Card"
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Mobile Number
            </label>
            <div className="flex items-center border-gray-300">
              <span className="bg-gray-300 text-black p-2 flex items-center">
                +91
              </span>
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="Mobile Number"
                disabled
              />
              {/* <button className="bg-[#17a44f] text-white px-4 py-2">
                Verify
              </button> */}
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Address
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Address"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Village/Town
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Village/Town"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Taluka
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Taluka"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              District
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="District"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              State
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="State"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Domicile
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Domicile"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Pin Code
            </label>
            <input
              type="number"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Pin Code"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Date of Birth
            </label>
            <input
              type="date"
              className="w-full outline-none border border-gray-300 p-2"
              placeholder="YYYY-MM-DD"
              disabled
            />
          </div>
          <div className="grid grid-cols-2  gap-4">
            <div>
              <label className="block text-black font-medium mb-1 text-[.875rem]">
                Gender
              </label>
              <select
                name="gender"
                className="w-full p-2 border border-gray-300 rounded-none appearance-none"
                disabled
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-black font-medium mb-1 text-[.875rem]">
                Marital Status
              </label>
              <select
                name="maritalStatus"
                className="w-full p-2 border border-gray-300 rounded-none appearance-none"
                disabled
              >
                <option value="">Select Marital Status</option>
                <option value="single">Single</option>
                <option value="married">Married</option>
                <option value="divorced">Divorced</option>
                <option value="widowed">Widowed</option>
              </select>
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              UPI Id
            </label>
            <div className="flex items-center border-gray-300">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="UPI Id"
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              ISFC Code
            </label>
            <div className="flex items-center border-gray-300">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="ISFC Code"
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Bank Account Number
            </label>
            <div className="flex items-center border-gray-300">
              <input
                type="text"
                className="w-full p-2 border border-gray-300 rounded-none"
                placeholder="Bank Account Number"
                disabled
              />
            </div>
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Email ID
            </label>
            <input
              type="email"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Email ID"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Education
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Education"
              disabled
            />
          </div>
          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Occupation
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Occupation"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Loan Amount
            </label>
            <input
              type="number"
              className="w-full p-2 border border-gray-300 rounded-none"
              placeholder="Loan Amount"
              disabled
            />
          </div>

          <div>
            <label className="block text-black font-medium mb-1 text-[.875rem]">
              Tenure
            </label>
            <select
              name="tenure"
              className="w-full p-2 border border-gray-300 rounded-none appearance-none bg-white"
              disabled
            >
              <option value="">Select an Option</option>
              <option value="100">100 Days</option>
            </select>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          {/* <button
            type="submit"
            className="bg-[#17a44f] text-white px-6 py-2 font-semibold"
          >
            View Borrower
          </button> */}
        </div>
      </form>
    </section>
  );
};

export default ViewBorrower;
