export const getIdFromUrl = (url: string): string | null => {
  try {
    const urlParts = url.split("/");
    return urlParts[urlParts.length - 1];
  } catch (error) {
    console.error("Error extracting ID from URL:", error);
    return null;
  }
};

 export const handleBeforeUnload = (event:any) => {
      event.preventDefault();
      event.returnValue = ""; // Show a confirmation dialog in most browsers
    };