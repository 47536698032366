import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IdState } from "../utils/types";

const initialState: IdState = {
  xTanentId: null, // Initial value for ID
};

const xTanentIdSlice = createSlice({
  name: "xTanentId",
  initialState,
  reducers: {
    setXTanentId: (state, action: PayloadAction<string | null>) => {
      state.xTanentId = action.payload;
    },
    clearXTanentId: (state) => {
      state.xTanentId = null;
    },
  },
});

export const { setXTanentId, clearXTanentId } = xTanentIdSlice.actions;

export default xTanentIdSlice.reducer;
