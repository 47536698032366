import React from "react";
import Container from "./Container";

const InnerBanner = ({ img, alt, children }: any) => {
  const hasContent = React.Children.count(children) > 0;
  return (
    <section className="relative ">
      <img src={img} alt={alt} className="w-full" height={400} />
      <div className="static lg:absolute lg:top-0 lg:left-0 w-full h-full lg:h-full text-center flex items-center justify-center">
        <Container
          className={`flex items-center justify-center flex-col ${
            hasContent ? "innerGlassEffect" : ""
          }`}
        >
          {children}
        </Container>
      </div>
    </section>
  );
};
export default InnerBanner;
