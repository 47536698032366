import { useState } from "react";
import In_FAQBG from "../../assets/inner-banners/faq_banner.jpg";
import InnerBanner from "../../components/ui/InnerBanner";
import Container from "../../components/ui/Container";

const Faq = () => {
  const [selectedId, setSelectedId] = useState(0);
  const tabTypes = [
    "Gold",
    "Housing",
    "Personal",
    "Wedding",
    "Vehicle",
    "SME",
    "Share & Security",
    "Property",
    "Holiday",
    "Doctor",
    "CA",
    "Business",
    "Arts & Antique",
  ];
  return (
    <>
      <InnerBanner img={In_FAQBG} alt={"FAQ's"}>
        <h4 className="text-xl md:text-2xl lg:text-4xl text-center text-white">
          <span className="text-white font-bold">FAQ&apos;s</span>
        </h4>
      </InnerBanner>
      <section className="py-6">
        <Container>
          <div className="flex">
            <div className="text-xs font-semibold cursor-pointer w-[12%] shadow-2xl">
              {tabTypes.map((item, index) => {
                return (
                  <div
                    key={item}
                    className={`${
                      selectedId === index
                        ? "bg-slate-900 bg-gradient-to-t from-yellow-600 via-yellow-200 to-yellow-600 shadow-lg arrow_right rounded-md text-slate-900 hover:text-slate-900"
                        : ""
                    } px-2 py-2 transition-all duration-300 font-montserrat font-normal hover:text-white hover:bg-slate-900 text-sm text-center text-green`}
                    onClick={() => setSelectedId(index)}
                  >
                    {item}
                  </div>
                );
              })}
            </div>

            <div className="w-[88%] pl-5 md:pl-8 md:pr-6 py-4">
              {/* {selectedId === 0 && <GoldLoanFaq />}
              {selectedId === 1 && <HousingLoanFaq />}
              {selectedId === 2 && <PersonalLoanFaq />}
              {selectedId === 3 && <WeddingLoanFaq />}
              {selectedId === 4 && <VehicleLoanFaq />}
              {selectedId === 5 && <SMELoanFaq />}
              {selectedId === 6 && <ShareSecurityLoanFaq />}
              {selectedId === 7 && <PropertyLoanFaq />}
              {selectedId === 8 && <HolidayLoanFaq />}
              {selectedId === 9 && <DoctorLoanFaq />}
              {selectedId === 10 && <CALoanFaq />}
              {selectedId === 11 && <BusinessLoanFaq />}
              {selectedId === 12 && <ArtsAntiqueLoanFaq />} */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Faq;
