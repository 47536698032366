import { Bar } from "react-chartjs-2";
import "../components/chartConfig";
import styles from '../styles/dashboardstyles/NewLoans.module.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const data = {
  labels: [
    'Personal','Gold', 'Vehicle', 'Home', 'Property', 'Business', 'Art & Antiques', 'Shares & Security', 'SME', 'Holiday', 'Wedding', 'CA',  'Doctor'
  ],
  datasets: [
    {
      label: "New Loans",
      data: [30, 45, 40, 50, 10, 70, 20, 10, 20, 30, 40,100,300], // Example data values
      backgroundColor: [
       '#3c8f47', // Red for January
          '#95d161', // Blue for February
          '#216d29', // Yellow for March
          '#6ed678', // Teal for April
          '#0e4c11', // Purple for May
          '#93ad2b',   // Orange for June
          '#8b962a', // Red for January
          '#ced6c8',
          '#bfd159', // Red for January
          '#d8e0a9',
          '#1d8921', // Red for January
          '#77af79',
          '#9da09c', // Red for January
      ],
     
      borderWidth: 1,
    },
  ],
};

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: "top" as const,
//     },
//     tooltip: {
//       callbacks: {
//         label: (context: any) => {
//           return `${context.label}: ${context.raw}`;
//         },
//       },
//     },
//   },
//   scales: {
//     x: {
//       beginAtZero: true,
//       title: {
//         display: true,
//         text: "Amount",
//       },
//     },
//     y: {
//       title: {
//         display: true,
//         text: "Loans",
//       },
//     },
//   },
//   indexAxis: "y", // Makes the bar chart horizontal
// };
  // Define the type for the options
  const options: ChartOptions<'bar'> = {
    indexAxis: 'x', // Horizontal bar chart (correctly typed)
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Hide grid on the x-axis
        },
        
      },
      y: {
        grid: {
          display: false, // Hide grid lines on the y-axis
        },
      }
    },
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hides the legend
       
      },
      title: {
        display: true,
        text: 'New-Loans',
        color: '#333', // Title text color (dark gray)
        font: {
          size: 20, // Font size for the title
          weight: 'bold', // Font weight for the title
          family: "'Arial', sans-serif" // Font family
        },
        padding: {
          top: 10,
          bottom: 10
        },
        align: 'start' // Align the title at the center
      },
      
    },
    layout: {
      padding: {
        top: 10, // Adds extra space around the entire chart, including above the title
        left: 10
      }
    },
  };
export default function NewLoans() {
  return (
    <div className={styles.new_loans_container}>
      <div className={styles.new_loans}>
        <Bar data={data} options={options} /> {/* Changed from chart-options to options */}
      </div>
    </div>
  );
}
