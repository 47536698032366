import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../utils/store";
import { forgotPassword } from "../../redux/authSlice";
import Banner from "../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import LOGO from "../../assets/Logo_circle.png";
import Loader from "../../utils/Loader";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { loading, data } = useSelector((state: RootState) => state.auth);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!email) {
      setMessage("Email is required");
      setIsError(true);
      return;
    }
    setMessage("");
    try {
      await dispatch(forgotPassword({ email }));
    } catch (err) {
      setMessage("Failed to send reset link. Please try again.");
      setIsError(true);
    }
  };

  useEffect(() => {
    console.log("forgetPassword", data);
    if (data?.status === "success") {
      setMessage("Reset link sent to your email ID.");
      setIsError(false);
    } else if (data?.status === "Fail") {
      setMessage("Failed to send reset link. Please try again.");
      setIsError(true);
    }
  }, [data]);

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center forgotScreen"
      style={{
        backgroundImage: `url(${Banner})`,
      }}
    >
      <div className="w-full max-w-md p-8 bg-[#f1f5f9] rounded-lg shadow-shadow_2">
        <div className="flex items-center gap-1 h-8">
          <Link
            to="/"
            className="hover:border-b-[1px] transition-all duration-300 ease-in-out text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col"
          >
            <FaHome className="fill-green-700  hover:fill-green-900  " />
            <p className="text-xs text-slate-400">Home Page</p>
          </Link>
        </div>

        <div className="text-center mb-8 w-auto flex items-center justify-center">
          <img src={LOGO} alt="Logo" className="w-32" />
        </div>

        <h1 className="text-[#3c8f47] font-semibold mb-5 text-center text-xl sm:text-2xl">
          Forgot Password
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              placeholder="Enter Your Email ID"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
            />
          </div>
          <div className="flex items-end justify-center">
            {!loading ? (
              <button
                type="submit"
                className="w-60 py-2 bg-[#3c8f47] text-white rounded-lg font-medium hover:bg-green-700 transition shadow-shadow_1 custButton"
              >
                Send OTP
              </button>
            ) : (
              <button
                type="submit"
                className="w-full py-2 bg-[#3c8f47] text-white rounded-lg font-medium hover:bg-green-700 transition"
              >
                <Loader />
              </button>
            )}
          </div>
          <Link
            to="/login"
            className="text-[#3c8f47] font-semibold text-center block mt-4 hover:text-green-900 transition-all duration-300 ease-in-out text-sm "
          >
            Back to Login
          </Link>
          {message && (
            <p
              className={`mt-4  ${
                isError ? "text-red-500" : "text-green-500"
              } text-center`}
            >
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}
