import { useEffect, useState } from "react";
import Banner from "../../../assets/Ashapurti-Gold-Loans_Form_Updated_BG.jpg";
import { getUserFromLocalStorage } from "../../../utils/localStorageUtil";
import { Link } from "react-router-dom";
import Logo from "../../../assets/Logo_circle.png";
import { FaHome } from "react-icons/fa";
import { agentProfileById, createAgentIntent } from "../../../utils/apis";

const AgentProfile = () => {
  const [agentData, setAgentData] = useState<any>({
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: "",
    maritalStatus: "",
    aadharCard: "",
    panCard: "",
    mobileNumber: "",
    email: "",
    whatsappNumber: "",
    address: "",
    villageOrTown: "",
    taluka: "",
    district: "",
    state: "",
    domicile: "",
    pincode: "",
  });
  const [paymentLink, setPaymentLink] = useState<any>("");
  const roleType = getUserFromLocalStorage()?.role;
  const agentId = getUserFromLocalStorage()?.borrowerId;

  useEffect(() => {
    if (roleType === "AGENT" && agentId) {
      agentProfileById(agentId).then((response: any) => {
        if (response) {
          setAgentData((prevData: any) => ({
            ...prevData,
            ...response.agent,
          }));
        }
      });
    }
  }, [roleType, agentId]);

  const handleGenerateLink = () => {
    createAgentIntent(agentId).then((response) => {
      setPaymentLink(response.data);
    });
  };

  return (
    <section
      style={{
        backgroundImage: `url(${Banner})`,
      }}
      className="h-full w-full bg-no-repeat bg-cover min-h-screen agentProfile_wrapper"
    >
      <div className="py-20 md:py-24 h-full md:h-dvh flex md:items-center justify-center">
        <div className="max-w-[1024px] w-full">
          <div className="tab-contentWrapper">
            <ul className="tab-header flex !justify-start mb-6 gap-8">
              <li className="tab-itemHome">
                <img src={Logo} alt="logo" className="pr-6 w-40" />
              </li>
              <div className="flex items-center gap-1 w-24 h-8">
                <Link
                  to="/"
                  className="hover:border-b-[1px] text-xl hover:text-2xl hover:border-b-green-900 hover:border-solid hover:pb-1 flex items-center flex-col transition-all duration-300 ease-in-out"
                >
                  <FaHome className="fill-green-700  hover:fill-green-900  " />
                  <p className="text-xs text-slate-400">Home Page</p>
                </Link>
              </div>
              <li className="w-full">
                <h4 className="text-lg text-[#00a068] font-semibold capitalize text-center underline underline-offset-2 mb-2">
                  Agent Profile
                </h4>
              </li>
            </ul>
            <div className="tab-content">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 border border-gray-300">
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Agent Id:
                  <span className="block lg:inline pl-0 lg:pl-3 font-medium capitalize">
                    {agentData?.agentId}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Agent Code:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.agentCode}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Full Name:
                  <span className="block lg:inline pl-0 lg:pl-3 font-medium capitalize">
                    {agentData?.firstName + " " + agentData?.lastName}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Mobile:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.mobileNumber}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Date of Birth:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.birthDate}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Email:
                  <span className="pl-3 font-medium">{agentData?.email}</span>
                </p>
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  PAN Card:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.panCard}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Aadhar Card:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.aadharCard}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Address:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.address}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  City:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.villageOrTown}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  State:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.state}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Pin Code:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.pincode}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b lg:border-r border-gray-300 p-1 pl-2">
                  Gender:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.gender || "Female"}
                  </span>
                </p>
                <p className="font-semibold text-sm border-b border-gray-300 p-1 pl-2">
                  Marital Status:
                  <span className="pl-3 font-medium capitalize">
                    {agentData?.maritalStatus || "Married"}
                  </span>
                </p>
              </div>

              <div className="flex items-center justify-between mt-6 relative">
                <div className="absolute top-1/2 -translate-y-1/2 left-0"></div>
                <div className="button-container">
                  <div className="flex items-center justify-center w-full">
                    <button onClick={handleGenerateLink} className="submitBtn">
                      Create Collect Link
                    </button>
                  </div>
                </div>
              </div>
              <div className="">
                {paymentLink && (
                  <div className="w-full flex items-center mt-4 px-8">
                    <input
                      type="text"
                      value={paymentLink}
                      readOnly
                      className="flex-grow px-4 py-2 text-gray-700 border border-gray-300 bg-gray-100 rounded-none"
                    />
                    <button
                      onClick={() => navigator.clipboard.writeText(paymentLink)}
                      className="bg-slate-900 text-white px-4 py-2  hover:bg-zinc-600 transition border-[1px]"
                    >
                      Copy
                    </button>
                  </div>
                )}
              </div>
              <Link
                to="/agent-dashboard"
                className="text-[#3c8f47] font-semibold text-center block hover:text-green-900 transition-all duration-300 ease-in-out text-sm mt-4"
              >
                Back to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgentProfile;
